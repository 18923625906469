import { get, map } from 'lodash';
import { HappyPopSound, NextLevelSound, PunchSound, SinisterLaugh, VideoGameDeath, WowAnimation, gameSplash, multiPopSound } from '../assets/images';


export const COTATION_CORRECT = [
    {
        message: "iMira nada mas quien te viera!",
        imageUrl: require('../../utils/assets/images/img21.gif'),
        correctType: "heart"
    },
    {
        message: "¡Buen trabajo!",
        imageUrl: require('../../utils/assets/images/img22.gif'),
        correctType: "clap"
    },
    {
        message: "¡Qué chévere!",
        imageUrl: require('../../utils/assets/images/img23.gif'),
        correctType: "heart-smile"
    },
    {
        message: "iEso, parce!",
        imageUrl: require('../../utils/assets/images/img25.gif'),
        correctType: "thumps-up"
    },
    {
        message: "¡Mira nada más quién te viera!",
        imageUrl: require('../../utils/assets/images/img24.gif'),
        correctType: "heart-happy"
    },
    {
        message: "iPura vida!",
        imageUrl: require('../../utils/assets/images/img42.gif'),
        correctType: "heart-dance"
    },
    {
        message: "¡SOS, un crack!",
        imageUrl: require('../../utils/assets/images/img26.gif'),
        correctType: "style-emoji"
    },
    // {
    //     message: "iQue chido!",
    //     imageUrl: require('../../utils/assets/images/football.gif'),
    //     correctType: "emoji-football"
    // },
];

export const COTATION_INCORRECT = [
    {
        message: "¡No pasa nada, sigue intentando!",
        imageUrl: require('../../utils/assets/images/incorrect1.gif'),
        correctType: "emoji-incorrect1"
    },
    {
        message: "iSigue intentando, si puedes!",
        imageUrl: require('../../utils/assets/images/incorrect4.gif'),
        correctType: "emoji-incorrect2"
    },
    // {
    //     message: "iSiguele, siguele, vamos!",
    //     imageUrl: require('../../utils/assets/images/incorrect6.gif'),
    //     correctType: "emoji-incorrect3"
    // },
    // {
    //     message: "iSiguele, siguele, vamos!",
    //     imageUrl: require('../../utils/assets/images/incorrect5.gif'),
    //     correctType: "emoji-incorrect4"
    // },
];

export const getRAndamContionRight = () => {
    const rendNum = Math.floor(Math.random() * COTATION_CORRECT.length);
    return COTATION_CORRECT[rendNum];

}

export const getRandomCotation = (isCorrect) => {
    if (isCorrect) {
        const rendNum = Math.floor(Math.random() * COTATION_CORRECT.length);
        return COTATION_CORRECT[rendNum];
    } else {
        const rendNum = Math.floor(Math.random() * COTATION_INCORRECT.length);
        return COTATION_INCORRECT[rendNum];
    }
}


export const isImageUrl = (url) => {

    if (typeof url !== 'string') {
        return false;
    }


    return url.startsWith('http') || url.startsWith('https');
}
export const getType = (type) => {
    if (type === 'quiz') {
        return 'Quiz';
    } else if (type === 'true_or_false') {
        return 'True or False';
    } else if (type === 'type_answer') {
        return 'Type Answer';
    } else if (type === 'puzzle') {
        return 'Puzzle';
    } else if (type === 'poll') {
        return 'Poll';
    } else if (type === 'drop_pin') {
        return 'Drop Pin';
    } else if (type === 'word_cloud') {
        return 'Word Cloud';
    } else if (type === 'open_ended') {
        return 'Open Ended';
    } else if (type === 'brainstorm') {
        return 'Brainstorm';
    } else if (type === 'informational_page') {
        return 'Informational Page';
    } else {
        return '';
    }
}

export const getOptionClassName = (index) => {
    const colorClasses = ['Fst', 'Sec', 'Thrd', 'Frth', 'Fst', 'Sec']; // Add more colors if needed
    const colorIndex = index % colorClasses?.length;
    return `col teaQuesAns${colorClasses[colorIndex]} custmBrdrRound  mb-12 item ${index % 2 === 0 ? 'evens' : 'odds'}`;
};

export const matchUserWithSubmissions = (users, currentSessionSumission) => {
    const result = map(users, user => {
        const userId = user.user_id;
        const currentSubmission = get(currentSessionSumission, 'submissions', '') ?? {};
        const { answers, score } = currentSubmission[userId] || [];
        const submissionsWithUserInfo = map(answers, submission => ({
            ...submission,
        }));
        return {
            userInfo: {
                user_id: user.user_id,
                user_name: user.user_name
            },
            submission: submissionsWithUserInfo,
            score: score
        };
    });

    return result;
};

const soccerAnimations = [
    { imageUrl: require('../../utils/assets/images/PlayerRonaldo.png'), inAnimation: 'animate__fadeInBottomRight animate__slow', outAnimation: 'animate__fadeOutLeft animate__slow', inSound: WowAnimation },
    { imageUrl: require('../../utils/assets/images/Soccer_bal_with_fire.png'), inAnimation: 'animate__fadeInTopRight animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: VideoGameDeath },
    { imageUrl: require('../../utils/assets/images/PlayerMessi.png'), inAnimation: 'animate__zoomIn animate__slow', outAnimation: 'animate__zoomOut animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/soccreHurt.png'), inAnimation: 'animate__zoomInDown animate__slow', outAnimation: 'animate__zoomOutDown animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/player_messi_running.png'), inAnimation: 'animate__bounceInRight animate__slow', outAnimation: 'animate__bounceOutLeft animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/soccer_kicking_ball.png'), inAnimation: 'animate__fadeInBottomLeft animate__slow', outAnimation: 'animate__fadeOutRightBig animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/finger_glove_game.png'), inAnimation: 'animate__tada animate__slow', outAnimation: 'animate__fadeOutLeft animate__slow', inSound: HappyPopSound },
    { imageUrl: require('../../utils/assets/images/player_celebrating.png'), inAnimation: 'animate__zoomInDown animate__slow', outAnimation: 'animate__zoomOutRight animate__slow', inSound: gameSplash },
    { imageUrl: require('../../utils/assets/images/multicolor_soccer_ball.png'), inAnimation: 'animate__rollIn animate__slow', outAnimation: 'animate__rollOut animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/player_running_with_ball.png'), inAnimation: 'animate__fadeInTopRight animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: WowAnimation },
    { imageUrl: require('../../utils/assets/images/bobble_head_figure_2.png'), inAnimation: 'animate__bounceIn animate__slow', outAnimation: 'animate__backOutUp animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/Bobble_head_figure.png'), inAnimation: 'animate__backInUp animate__slow', outAnimation: 'animate__backOutRight animate__slow', inSound: gameSplash },
    { imageUrl: require('../../utils/assets/images/player_kickcing_ball_backwards.png'), inAnimation: 'animate__jackInTheBox animate__slow', outAnimation: 'animate__rotateOutUpLeft animate__slow', inSound: VideoGameDeath },
    { imageUrl: require('../../utils/assets/images/player_standing_with_ball.png'), inAnimation: 'animate__lightSpeedInRight animate__slow', outAnimation: 'animate__lightSpeedOutLeft animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/player_walking_away.png'), inAnimation: 'animate__slideInUp animate__slow', outAnimation: 'animate__slideOutUp animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/player_with_ball.png'), inAnimation: 'animate__flipInY animate__slow', outAnimation: 'animate__flip animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/sassy_player_walking_away.png'), inAnimation: 'animate__flash animate__slow', outAnimation: 'animate__fadeOut animate__slow', inSound: HappyPopSound },
    { imageUrl: require('../../utils/assets/images/golie_stopping_ball.png'), inAnimation: 'animate__fadeInBottomRight animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: gameSplash },
];

const footballAnimations = [
    { imageUrl: require('../../utils/assets/images/football_with_fire.png'), inAnimation: 'animate__fadeInTopRight animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: WowAnimation },
    { imageUrl: require('../../utils/assets/images/girl_with_football.png'), inAnimation: 'animate__fadeInBottomRight animate__slow', outAnimation: 'animate__fadeOutLeft animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/player_jump_with_ball.png'), inAnimation: 'animate__zoomIn animate__slow', outAnimation: 'animate__zoomOut animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/player_shoes.png'), inAnimation: 'animate__zoomInDown animate__slow', outAnimation: 'animate__zoomOutDown animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/player_run_with_football.png'), inAnimation: 'animate__bounceInRight animate__slow', outAnimation: 'animate__bounceOutLeft animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/player_goal_football.png'), inAnimation: 'animate__fadeInTopRight animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: HappyPopSound },
    { imageUrl: require('../../utils/assets/images/football_timeout.png'), inAnimation: 'animate__tada animate__slow', outAnimation: 'animate__fadeOut animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/player_with_football_bounce.png'), inAnimation: 'animate__zoomInDown animate__slow', outAnimation: 'animate__zoomOutRight animate__slow', inSound: WowAnimation },
    { imageUrl: require('../../utils/assets/images/player_agressive_football.png'), inAnimation: 'animate__rollIn animate__slow', outAnimation: 'animate__rollOut animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/player_passionate_football.png'), inAnimation: 'animate__fadeInTopRight animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/run_with_football.png'), inAnimation: 'animate__bounceIn animate__slow', outAnimation: 'animate__backOutUp animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/two_player_football.png'), inAnimation: 'animate__backInUp animate__slow', outAnimation: 'animate__backOutRight animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/happy_football.png'), inAnimation: 'animate__jackInTheBox animate__slow', outAnimation: 'animate__rotateOutUpLeft animate__slow', inSound: HappyPopSound },
    { imageUrl: require('../../utils/assets/images/girl_jump_football.png'), inAnimation: 'animate__lightSpeedInRight animate__slow', outAnimation: 'animate__lightSpeedOutLeft animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/player_throw_football.png'), inAnimation: 'animate__slideInUp animate__slow', outAnimation: 'animate__slideOutUp animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/football_with_force.png'), inAnimation: 'animate__flipInY animate__slow', outAnimation: 'animate__flip animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/goal_with_football.png'), inAnimation: 'animate__flash animate__slow', outAnimation: 'animate__fadeOut animate__slow', inSound: WowAnimation },
];

const runAnimations = [
    { imageUrl: require('../../utils/assets/images/run_flag.png'), inAnimation: 'animate__slideInDown animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: WowAnimation },
    { imageUrl: require('../../utils/assets/images/run_thunder.png'), inAnimation: 'animate__jackInTheBox animate__slow', outAnimation: 'animate__fadeOut animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/run_duck.png'), inAnimation: 'animate__zoomInDown animate__slow', outAnimation: 'animate__zoomOutUp animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/run_nike_shoe.png'), inAnimation: 'animate__bounceInUp animate__slow', outAnimation: 'animate__bounceOut animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/run_happy_lion.png'), inAnimation: 'animate__bounceInRight animate__slow', outAnimation: 'animate__bounceOutLeft animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/run_fast_player.png'), inAnimation: 'animate__fadeInLeftBig animate__slow', outAnimation: 'animate__fadeOutRightBig animate__slow', inSound: HappyPopSound },
    { imageUrl: require('../../utils/assets/images/run_slipper.png'), inAnimation: 'animate__tada animate__slow', outAnimation: 'animate__fadeOut animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/run_milky_duck.png'), inAnimation: 'animate__zoomInDown animate__slow', outAnimation: 'animate__zoomOutRight animate__slow', inSound: WowAnimation },
    { imageUrl: require('../../utils/assets/images/run_girl_fast.png'), inAnimation: 'animate__rollIn animate__slow', outAnimation: 'animate__rollOut animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/run_girl_lion.png'), inAnimation: 'animate__fadeInTopRight animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/run_bike.png'), inAnimation: 'animate__bounceIn animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/run_tiger.png'), inAnimation: 'animate__backInUp animate__slow', outAnimation: 'animate__bounceOut animate__slow', inSound: NextLevelSound },
];

const baseballAnimations = [
    { imageUrl: require('../../utils/assets/images/home_run_baseball.png'), inAnimation: 'animate__slideInDown animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: WowAnimation },
    { imageUrl: require('../../utils/assets/images/player_reach_the_field.png'), inAnimation: 'animate__jackInTheBox animate__slow', outAnimation: 'animate__fadeOut animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/player_enjoy.png'), inAnimation: 'animate__zoomInDown animate__slow', outAnimation: 'animate__zoomOutUp animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/playing_with_baseball.png'), inAnimation: 'animate__bounceInUp animate__slow', outAnimation: 'animate__bounceOut animate__slow', inSound: PunchSound },
    { imageUrl: require('../../utils/assets/images/angry_baseball.png'), inAnimation: 'animate__bounceInRight animate__slow', outAnimation: 'animate__bounceOutLeft animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/keeper_with_baseball.png'), inAnimation: 'animate__fadeInLeftBig animate__slow', outAnimation: 'animate__fadeOutRightBig animate__slow', inSound: HappyPopSound },
    { imageUrl: require('../../utils/assets/images/baseball_with_bat.png'), inAnimation: 'animate__tada animate__slow', outAnimation: 'animate__fadeOut animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/player_blue_bat.png'), inAnimation: 'animate__zoomInDown animate__slow', outAnimation: 'animate__zoomOutRight animate__slow', inSound: WowAnimation },
    { imageUrl: require('../../utils/assets/images/player_with_baseball.png'), inAnimation: 'animate__rollIn animate__slow', outAnimation: 'animate__rollOut animate__slow', inSound: multiPopSound },
    { imageUrl: require('../../utils/assets/images/player_with_baseballbat.png'), inAnimation: 'animate__fadeInTopRight animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/three_players_baseball.png'), inAnimation: 'animate__bounceIn animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: SinisterLaugh },
    { imageUrl: require('../../utils/assets/images/tiger_baseball.png'), inAnimation: 'animate__backInUp animate__slow', outAnimation: 'animate__bounceOut animate__slow', inSound: NextLevelSound },
    { imageUrl: require('../../utils/assets/images/lion_baseball.png'), inAnimation: 'animate__slideInDown animate__slow', outAnimation: 'animate__fadeOutTopLeft animate__slow', inSound: WowAnimation },
];

export const getModernImage = (modern_type) => {
    if (modern_type === 'magic_goal') {
        return soccerAnimations;
    } else if (modern_type === 'king_jump') {
        return footballAnimations;
    } else if (modern_type === 'park') {
        return baseballAnimations;
    } else if (modern_type === 'run') {
        return runAnimations;
    } else {
        return [];
    }
}