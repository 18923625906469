import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Outlet } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit } from './modules/auth';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';  // Don't forget to import Integrations
import InternetLostMessage from './modules/apps/IPlayGame/components/InternetLostMessage';

Sentry.init({
  dsn: "https://471ed96febb4e442114fbd93aa16276b@o4506743508631552.ingest.sentry.io/4506743509811200",
  integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: window.location.href.indexOf('localhost') === -1
});

// const TracedApp = Sentry.withProfiler(App);

// ReactDOM.render(<TracedApp />, document.getElementById('root'));

function App() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <InternetLostMessage />
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
}

export { App };
