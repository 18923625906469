//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const gamesInitial = { type: true };

export const openDrawar = (state = gamesInitial, action) => {

    switch (action.type) {
        case 'open':
            return action.payload;
        default:
            return state;
    }
}