import axios from 'axios'
import { IAuthkeyResponse, UserModel } from './_models'

export const GET_USER_BY_ACCESSTOKEN_URL = `/user/verify_token`
export const LOGIN_URL = `/user/login`
export const REGISTER_URL = `/user/managerUser`
export const REQUEST_PASSWORD_URL = `/user/forgotPassword`
export const REQUEST_PASSWORDSAVE_URL = `/user/setNewPassWord`
export const CONFIRM_OTP_URL = `/user/verify`

export const GET_USER_BY_AUTHKEY_URL = `/sync/verify_key`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

export function verify_otp(otp: any) {
  return axios.post(`${CONFIRM_OTP_URL}`, otp)
}

// Server should return AuthModel
export function register(value: any) {
  return axios.post(REGISTER_URL, {
    email: value?.email,
    phone_number: value?.phone,
    user_name: value?.name,
    password: value?.password,
    is_admin: value?.is_admin
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<any>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestPasswordsave(data: any) {
  return axios.post<any>(REQUEST_PASSWORDSAVE_URL, {
    data,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  }
  )
}

export function getKeyVefificationForAutologin(key: string) {
  return axios.post<IAuthkeyResponse>(GET_USER_BY_AUTHKEY_URL, {
    key
  }
  ).then(response => response.data).catch(error => error.message)
}