import { useAuth } from '@app/modules/auth';
import { Modal } from 'antd';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineCheck } from "react-icons/ai";
import { BiBorderAll } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import '../../../../../src/app/modules/apps/IPlayGame/utils/assets/css/index.css';
import { ModalAI } from '../../../../_metronic/layout/components/ModalAI';
import { useActionValues, useActionValues1, useActionValues2 } from './store/TabChange/selectors';
import { setActivity } from './store/TabChange/thunks';
const Index = ({ selectedTime, selectedOption, onFeatureSelect, selectedCard, onCardSelect, showSection }) => {
  // const [type, setType] = useState('');
  // const location = useLocation();
  const { currentGameContext } = useAuth()

  // const allGamesList = useAllGames();

  const dispatch = useDispatch();
  // const currentURL = location.pathname;

  // const currentGameId = currentURL.split('/').pop();
  // const getCurrentGame = find(allGamesList, (item) => item.game_id === currentGameId);
  const getCurrentGame = currentGameContext;



  const gametype = get(getCurrentGame, 'game_type', {}) ?? {}
  const gameMode = get(getCurrentGame, 'game_mode', {}) ?? {}
  const studymetadata = get(getCurrentGame, 'study_metadata', {}) ?? {}


  useEffect(() => {
    handleCardSelect(selectedCard);
  }, [])

  const main = useActionValues();
  const main1 = useActionValues1();
  const main2 = useActionValues2();
  // const [show, setShow] = useState(true);
  const [openAIModal, setOpenAIModal] = useState(false);


  useEffect(() => {
    if (main) {
      handlePointSelect(main1?.selectedOption);
      handleOptionSelect(main1?.selectedTime);
      handleCardSelect(main?.selectedCard);
    }
  }, [main, main1])

  // Left section Dropdown 
  const [isOpen, setIsOpen] = useState(false);
  //const [selectedCard, setSelectedCard] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCardSelect = (card, type) => {
    if (type === "new") {
      if (main2 && main2?.activity === true) {
        Modal.confirm({
          title: 'Discard Changes?',
          content: 'Your changes will be discarded. Do you want to continue?',
          onOk: () => {
            // User clicked OK (Continue)


            // Execute further actions (e.g., selecting card, point, and option)
            onCardSelect(card);
            handlePointSelect({ id: 1, label: 'Standard', subLabel: 'Award correct answers with the normal of points' });
            handleOptionSelect('20 seconds');
            dispatch(setActivity({ activity: false }));
            setIsOpen(false);

          },
          onCancel: () => {
            // User clicked Cancel

          },
        });
      } else {
        // No need to show modal, directly execute further actions
        onCardSelect(card);
        handlePointSelect({ id: 1, label: 'Standard', subLabel: 'Award correct answers with the normal of points' });
        handleOptionSelect('20 seconds');
        dispatch(setActivity({ activity: false }));
        setIsOpen(false);

      }
    } else {
      onCardSelect(card);
      setIsOpen(false);

    }
  };

  const cardData = [];


  // If gametype is "1game", show full cardData
  if (gametype === "1game" && gameMode === 'classic') {
    cardData.push(
      {
        category: 'Test Knowledge',
        cards: [
          { id: 1, label: 'Quiz', icon: <BiBorderAll /> },
          { id: 2, label: 'True or false', icon: <BiBorderAll /> },
          { id: 3, label: 'Type answer', icon: <BiBorderAll /> },
          { id: 4, label: 'Puzzle', icon: <BiBorderAll /> }
        ]
      },
      {
        category: 'Collect opinions',
        cards: [
          { id: 1, label: 'Poll', icon: <BiBorderAll /> },
          { id: 2, label: 'Drop pin', icon: <BiBorderAll /> },
          { id: 3, label: 'Word cloud', icon: <BiBorderAll />},
          { id: 4, label: 'Open-ended', icon: <BiBorderAll /> },
          { id: 5, label: 'Brainstorm', icon: <BiBorderAll /> }
        ]
      },
      {
        category: 'Present info',
        cards: [
          { id: 1, label: 'Informational Page', icon: <BiBorderAll /> },
        ]
      }
    );
  } else if (gametype === "1game" && gameMode === 'modern') {
    cardData.push(
      {
        category: 'Test Knowledge',
        cards: [
          { id: 1, label: 'Quiz', icon: <BiBorderAll /> },
          { id: 2, label: 'True or false', icon: <BiBorderAll /> },
          { id: 3, label: 'Type answer', icon: <BiBorderAll /> },
          { id: 4, label: 'Puzzle', icon: <BiBorderAll /> }
        ]
      },
    );
  } else if (gametype === "1study") {
    // If gametype is "1study", check study_metadata.type
    if (studymetadata?.type === "quiz") {

      cardData.push(
        {
          category: 'Test Knowledge',
          cards: [
            { id: 1, label: 'Quiz', icon: <BiBorderAll /> },
            { id: 2, label: 'True or false', icon: <BiBorderAll /> },
            { id: 4, label: 'Puzzle', icon: <BiBorderAll /> }
          ]
        }
      );
    } else if (studymetadata?.type === "flash_card") {
      cardData.push(
        {
          category: 'Test Knowledge',
          cards: [
            { id: 1, label: 'Flash-Card', icon: <BiBorderAll /> }
          ]
        },
      );
    }
  }


  // const addQuestion = [
  //   {
  //     category: 'Add slide button',
  //     cards: [
  //       { id: 1, label: 'Add question', icon: <BiBorderAll /> },
  //     ]
  //   },
  // ];
  // const addQuestion = [
  //   {
  //     category: 'Add slide button',
  //     cards: [
  //       { id: 1, label: 'Quiz', icon: <BiBorderAll /> },
  //     ]
  //   },
  // ];

  // const addInformation = [
  //   {
  //     category: 'Add slide button',
  //     cards: [
  //       { id: 1, label: 'Informational Page', icon: <BiBorderAll /> },
  //     ]
  //   },
  // ];

  // const [selectedTmOption, setselectedTmOption] = useState(null);
  const [isTimeOpen, setIsTimeOpen] = useState(false);

  const toggleTimeDropdown = () => {
    setIsTimeOpen(!isTimeOpen);
  };

  const handleOptionSelect = (option) => {
    onFeatureSelect("time", option)
    // selectedTime(option)
    // setselectedTmOption(option);
    setIsTimeOpen(false);
  };

  const optionsTime = ['20 seconds', '30 seconds', '1 minute', '1 minute 30 seconds', '2 minutes', '3 minutes', '4 minutes'];

  // const [pointOption, setPointOption] = useState(null);
  const [isPointOpen, setIsPointOpen] = useState(false);

  const togglePointDropdown = () => {
    setIsPointOpen(!isPointOpen);
  };

  const handlePointSelect = (option) => {
    onFeatureSelect("option", option)
    // setPointOption(option);
    setIsPointOpen(false);
  };

  const optionsPoint = [
    { id: 1, label: 'Standard', subLabel: 'Award correct answers with the normal of ponits ' },
    { id: 2, label: 'Double points', subLabel: 'Give twice as many points for the correct answers.' },
    // { id: 3, label: 'No points', subLabel: 'Lower the stakes of the question and remove points' },
  ]


  // const [categoryOption, setCategoryOption] = useState(null);
  // const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  // const toggleCategoryDropdown = () => {
  //   setIsCategoryOpen(!isCategoryOpen)
  // }
  // const handleCategorySelect = (option) => {
  //   setCategoryOption(option);
  //   setIsCategoryOpen(false);
  // }
  // const optionsCategory = ['Homework', 'Participation', 'Quiz', 'Classwork', 'Exam'];


  // const [gradescaleOption, setGradescaleOption] = useState(null);
  // const [isGradescaleOpen, setIsGradescaleOpen] = useState(false);
  // const [isModalVisible, setModalVisible] = useState(false);

  // const toggleGradescaleDropdown = () => {
  // setIsGradescaleOpen(!isGradescaleOpen)
  // }
  // const handleGradescaleSelect = (option) => {
  // setGradescaleOption(option);
  // setIsGradescaleOpen(false);
  // }
  // const optionsGradescale = ['Points', 'Percentage', 'Letters'];
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsTimeOpen(false);
        setIsPointOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // const toggleModal = () => {
  //   setModalVisible(!isModalVisible);
  // };

  const addnewquestion = (selectedCard) => {
    if (selectedCard && selectedCard.label) {
      const selectedLabel = selectedCard.label;

      // Filter cardData based on the selectedLabel
      const specificCardData = cardData
        .flatMap(category => category.cards)
        .filter(card => card.label === selectedLabel);

      // Assuming `main1` is an object with an `activity` property
      if (main2 && main2?.activity === true) {
        Modal.confirm({
          title: 'Discard Changes?',
          content: 'Your changes will be discarded. Do you want to continue?',
          onOk: () => {
            // User clicked OK (Continue)


            // Execute further actions (e.g., selecting card, point, and option)
            handleCardSelect(specificCardData[0]);
            handlePointSelect({ id: 1, label: 'Standard', subLabel: 'Award correct answers with the normal of points' });
            handleOptionSelect('20 seconds');
            dispatch(setActivity({ activity: false }));

          },
          onCancel: () => {
            // User clicked Cancel

          },
        });
      } else {
        // No need to show modal, directly execute further actions
        handleCardSelect(specificCardData[0]);
        handlePointSelect({ id: 1, label: 'Standard', subLabel: 'Award correct answers with the normal of points' });
        handleOptionSelect('20 seconds');
        dispatch(setActivity({ activity: false }));

      }
    } else {
      console.warn('Please select a card before clicking "Add New Question".');
    }
  };


  const handleAIButtonClick = () => {
    // Your condition for showing the modal
    if (main2 && main2.activity === true) {
      Modal.confirm({
        title: 'Discard Changes?',
        content: 'Continuing with AI will discard your changes. Do you want to proceed?',
        onOk: () => {
          // User clicked OK (Continue)

          setOpenAIModal(!openAIModal);
        },
        onCancel: () => {
          // User clicked Cancel

        },
      });
    } else {
      // Condition is not met, directly open the modal
      setOpenAIModal(!openAIModal);
    }
  };

  return (

    <div ref={dropdownRef} className='selectDropListUpr'>
      <div>
        <div className='selectDropList mt-8'>

          <div className='dropCstmlabel'>
            <label className='text-black-10'><BiBorderAll />Question type</label>
          </div>
          <div className='dropCstmSelect'>
            <div className="selectedCardTp text-black-10" onClick={toggleDropdown}>
              {selectedCard ? (
                <>
                  {selectedCard.icon}
                  <label>{selectedCard.label}</label>
                </>
              ) : (
                'Select a card'
              )}
            </div>
            {isOpen && (
              <div className="dropdownContentTp bg-light">
                {cardData.map(category => (
                  <div className="dropdownTpCatgry" key={category.category}>
                    <div className="dropdownTpLbl">{category.category}</div>

                    <div className="cardQuseTpUpr">
                      {category.cards.map((card) => (
                        <div
                          key={card.id}
                          className={`text-hover-primary bg-body cardQuseTp ${selectedCard && selectedCard.id === card.id ? 'selected' : ''}`}
                          onClick={() => {
                            handleCardSelect(card, "new");
                          }}
                        >
                          {card.icon}
                          <label className='text-black-10'>{card.label}</label>
                          <div className='assignStar'>{card.starRate}</div>
                        </div>
                      ))}


                    </div>

                  </div>
                ))}
              </div>
            )}
            {/* <div className='cstmSwitchPart'>
              <label><BiCalendarX />Ungraded</label>
              <Switch defaultChecked />
            </div> */}
          </div>
        </div>

        {/* Time Limit Dropdown */}
        {(selectedCard.label !== "Informational Page" && gametype !== "1study") && (
          <div>
            <div className='dropCstmlabel timeLmtWrap'>
              <label className='text-black-10'><BiBorderAll />Time limit per question</label>
            </div>

            <div className="dropdownTime">
              <div className="drptimeOption text-black-10" onClick={toggleTimeDropdown}>
                {selectedTime ? (
                  <>
                    {selectedTime}
                  </>
                ) : (
                  'Select an option'
                )}
              </div>
              {isTimeOpen && (
                <div className="drpTimecontent bg-light">
                  {optionsTime.map((option) => (
                    <div
                      key={option}
                      className={`text-hover-primary optionTimeSet ${selectedTime === option ? 'selected' : ''}`}
                      onClick={() => handleOptionSelect(option)}
                    >
                      {option}
                      {selectedTime === option && <AiOutlineCheck />}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* End of Time Limit Dropdown */}

            {/* Point Dropdown */}
            {/* <div className='pointDropdown'>
              <div className='dropCstmlabel'>
                <label><BiBorderAll />Points</label>
              </div>

              <div className="dropdownPoint">
                <div className="drpPointOption" onClick={togglePointDropdown}>
                  {selectedOption ? (
                    <>
                      {selectedOption.label}
                    </>
                  ) : (
                    'Select an option'
                  )}
                </div>
                {isPointOpen && (
                  <div className="drpPointcontent bg-light">
                    {optionsPoint.map((option) => (
                      <div
                        key={option.id}
                        className={`text-hover-primary optionPointSet ${selectedOption && selectedOption.id === option.id ? 'selected' : ''}`}
                        onClick={() => handlePointSelect(option)}
                      >
                        <div>
                          <label>{option.label}</label>
                          <p>{option.subLabel}</p>
                        </div>
                        {selectedOption && selectedOption.id === option.id && <AiOutlineCheck />}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div> */}
          </div>
        )}

        {/* End of Point Dropdown */}
        {/* Category Dropdown */}
        {/* <div className='pointDropdown'>
          <div className='dropCstmlabel'>
            <label><BiSolidGridAlt />Category</label>
          </div>

          <div className="dropdownPoint">
            <div className="drpPointOption" onClick={toggleCategoryDropdown}>
              {categoryOption ? (
                <>
                  {categoryOption}
                </>
              ) : (
                'Select an option'
              )}
            </div>
            {isCategoryOpen && (
              <div className="drpPointcontent bg-light">
                {optionsCategory.map((option) => (
                  <div
                    key={option}
                    className={`text-hover-primary optionTimeSet ${categoryOption === option ? 'selected' : ''}`}
                    onClick={() => handleCategorySelect(option)}
                  >
                    {option}
                    {categoryOption === option && <AiOutlineCheck />}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div> */}
        {/* End of Category Dropdown */}
        {/* Grade Scale Dropdown */}
        {/* <div className='pointDropdown'>
          <div className='dropCstmlabel'>
            <label><SiApollographql />Grade Scale</label>
          </div>

          <div className="dropdownPoint">
            <div className="drpPointOption" onClick={toggleGradescaleDropdown}>
              {gradescaleOption ? (
                <>
                  {gradescaleOption}
                </>
              ) : (
                'Select an option'
              )}
            </div>
            {isGradescaleOpen && (
              <div className="drpPointcontent bg-light">
                {optionsGradescale.map((option) => (
                  <div
                    key={option}
                    className={`text-hover-primary optionTimeSet ${gradescaleOption === option ? 'selected' : ''}`}
                    onClick={() => handleGradescaleSelect(option)}
                  >
                    {option}
                    {gradescaleOption === option && <AiOutlineCheck />}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div> */}
        {/* End of Grade Scale Dropdown */}
      </div>
      <div>
        <div className='listLayoutBtn previewWidth'>

          {/* {addQuestion.map(category => (
            <div className='addSlideMenu'>
              {category.cards.map((card) => (
                <div
                  key={card.id}
                  className={`${selectedCard && selectedCard.id === card.id ? 'selected' : ''}`}
                  onClick={() => handleCardSelect(selectedCard)}
                >
                  <button className='addquesMenu'>{'Add question'}</button>
                </div>
              ))}
            </div>
          ))} */}
          <button className='addquesMenu' onClick={() => addnewquestion(selectedCard)}>{'Add question'}</button>
          
            <button className='neCLasSPace' onClick={handleAIButtonClick}>Add AI Question</button>
       
          <ModalAI gameOpen={openAIModal} setOpenAIModal={setOpenAIModal} defaultPage={5} comesFrom={"ai"} />
        </div>
      </div>
    </div>

  )
}

export default Index;
