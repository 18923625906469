/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'
import { ModalAI } from '../ModalAI'
import { find, get } from 'lodash'
import { useAllGames } from '../../../../app/modules/apps/IPlayGame/store/GameCreate/selectors'
import { useGameQuestions } from '../../../../app/modules/apps/IPlayGame/store/GameQuestions/selectors'
import { CreateGames, deleteGames } from '../../../../app/modules/apps/IPlayGame/store/GameCreate/thunks'
import { useDispatch } from 'react-redux'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { SaveOutlined } from '@ant-design/icons'
import { LeavePageModal } from '../../../../app/modules/apps/IPlayGame/components/LeavePageModal'
import PreviewGame from '@app/modules/apps/IPlayGame/components/PreviewGame'
import Loading from '@app/modules/apps/IPlayGame/components/Loading'
import { notification } from 'antd'
import { useAuth } from '@app/modules/auth'

let redrect=['create_game','edit_game']

export function HeaderWrapper({ changeTheme = () => { } }) {
  const { auth, currentGameContext , saveCurrentGameContext} = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const allGamesList = useAllGames();
  const GameQuestions = useGameQuestions()
  const { config, classes, attributes, openDrawal } = useLayout()
  const { header } = config
  const [offset, setOffset] = useState(`{default: '200px', lg: '300px'}`)
  const [openAIModal, setOpenAIModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [modalLeave, setModalLeave] = useState(false);
  const currentURL = location.pathname;
  const currentGameId = currentURL.split('/').pop();
  // const getCurrentGame = find(allGamesList, (item) => item.game_id === currentGameId);
  const getCurrentGame = currentGameContext;

  const mediaUrl = get(getCurrentGame, 'media_urls', {}) ?? {}
  const gametype = get(getCurrentGame, 'game_type', {}) ?? {}
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [gameId, setGameId] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    let newString = `{default: '200px', lg: '300px'}`
    if (header.fixed.desktop) {
      if (!header.fixed.tabletAndMobile) {
        newString = `{lg: '300px'}`
      }
    } else {
      newString = `{default: '200px', lg: false}`
    }

    setOffset(newString)
  }, [header.fixed])

  const onBackButtonClick = async () => {

    if (GameQuestions?.length > 0 && getCurrentGame?.display_name !== null && getCurrentGame?.category_id !== null && mediaUrl?.image) {
      if (getCurrentGame?.status === 'true' || getCurrentGame?.status === 'false') {
        const data = {
          ...getCurrentGame,
          status: true
        }
        await dispatch(CreateGames(data, saveCurrentGameContext))
      }
      if(redrect.includes(auth?.session_data?.metadata?.action)){
        if (window != window.top) {
          window.parent.postMessage({ action: 'close_one_game' }, '*');
        }
      }else{
      
      navigate(`/apps/home/`)
      }
    } else {
      setModalLeave(true)
    }
  }

  useEffect(() => {
    getDynamicMessage()
  }, [GameQuestions, getCurrentGame])

  const getDynamicMessage = () => {
    let messages = [];
    if (GameQuestions?.length === 0) {
      messages.push('Add at least one Question');
    }
    if (getCurrentGame?.display_name === null) {
      messages.push('Give it a name: Let your game stand out with a unique title.');
    }
    if (getCurrentGame?.category_id === null) {
      messages.push('Select books and pages to build your game.');
    }
    if (!mediaUrl?.image) {
      messages.push('Uh oh, looks like you left your picture behind. Pick one and try again.');
    }
    return messages;
  };

  const onGameDelete = () => {
        setDeleteLoader(true)
    if(getCurrentGame?.game_id){
      dispatch(deleteGames(getCurrentGame?.game_id))
      .then((response) => {
        setDeleteLoader(false)
        if (response && response.status === 200) {
          if(redrect.includes(auth?.session_data?.metadata?.action)){
            if (window != window.top) {
              window.parent.postMessage({ action: 'close_one_game' }, '*');
            }
          }else{
            navigate(`/apps/home/`);
          }
        } else {
          console.error('Failed to delete game:', response);
        }
      })
      .catch((error) => {
        setDeleteLoader(false)
        if(redrect.includes(auth?.session_data?.metadata?.action)){
          if (window != window.top) {
            window.parent.postMessage({ action: 'close_one_game' }, '*');
          }
        }else{
          navigate(`/apps/home/`);
        }
        console.error('Error deleting game:', error);
      });
    }else{
      if(redrect.includes(auth?.session_data?.metadata?.action)){
        if (window != window.top) {
          window.parent.postMessage({ action: 'close_one_game' }, '*');
        }
      }else{
        navigate(`/apps/home/`);
      }
    }
    
  };

  const getGameList = (item) => {
    setLoader(item);
    if (GameQuestions.length > 0) {
      setTimeout(() => {
        setDrawerOpen(true);
        setLoader(null);
      }, 3000);
      setGameId(item);
    }
    else {
      setLoader(null);
      openCheckNotification('error')
    }
  }

  const openCheckNotification = (type) => {
    api[type]({
      message: 'Attention is needed to add at least one game !',
      description:
        'Please make sure to add at least one game to continue',
    });
  };

  return (
    <>
      {loader && <Loading isOpen={loader} isClose={setLoader} />}
      {contextHolder}
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '))}
      {...attributes.headerMenu}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset={offset}
    >
      {/* begin::Container */}
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-center justify-content-between'
        )}
        id='kt_header_container'
      >

        {/* <DefaultTitle /> */}

        {/* begin::Wrapper */}
        <div className={'d-flex d-lg-none align-items-center ms-n2 me-2'}>
          {/* begin::Aside mobile toggle */}
            <div className='btn btn-icon btn-active-icon-primary aside-toggle' onClick={() => openDrawal('aside aside-extended drawer drawer-start drawer-on')} id='kt_aside_toggle'>
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
          </div>

          {/* begin::Logo */}
          {/* <Link to='/apps/home/' className='d-flex align-items-center'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/demo7.svg')} className='h-30px' />
          </Link> */}
          {/* end::Logo */}
        </div>
        {/* end::Wrapper */}


        <div className='topBarBtns'>
          <button className='btn btn-icon btn-primary me-3' onClick={() => {
            onBackButtonClick()
          }}>
            <ExitToAppIcon className='exittoappiconstyle' /><span className="topBarBtnsEctions" style={{ marginLeft: 5 }}> Exit</span>
          </button>
          {gametype === "1study" ? "" :
          <>
          <button className='btn btn-icon btn-primary' onClick={() => changeTheme()}>
            <i className="fa-solid fa-house"></i><span className="topBarBtnsEctions"> Themes</span>
          </button>
          <button className='btn btn-icon btn-primary ms-3' onClick={() => getGameList(getCurrentGame?.game_id)}>
              <i className="fa-solid fa-street-view"></i> <span className="topBarBtnsEctions">Overview</span>
          </button>
          </>}
          <button onClick={() => setOpenAIModal(!openAIModal)
          } className='btn btn-icon btn-primary ms-3 saveCloseBtnSec'>
            {/* <i className="fa-solid fa-square-arrow-up-right"></i>  */}
            <SaveOutlined /><span className="topBarBtnsEctions" style={{ marginLeft: 5 }}>
              Save</span>
          </button>

          <ModalAI gameOpen={openAIModal} setOpenAIModal={setOpenAIModal} defaultPage={6} onSelectedQuestionsChange={undefined} />

        </div>
        <Topbar />
        <LeavePageModal
          isOpens={modalLeave}
          isLoading={deleteLoader}
          isClose={() => {
            setModalLeave(false);
          }}
          title={`Hold on! Leaving your game?`}
          message={getDynamicMessage()}
          confirmDelete={() => {
            onGameDelete();
          }} />
      </div>
      {/* end::Container */}
      {drawerOpen && (
          <PreviewGame isOpen={true} GameId={gameId} isClose={setDrawerOpen} nosotros={false} />
      )}
    </div >
    </>
  )
}
