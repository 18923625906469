import { ActionTypes } from '../constants';

const gamesInitial = {  };

export const currentGameSession = (state = gamesInitial, action) => {

    switch (action.type) {
        case ActionTypes.sessions.set_game_session:
            return action.payload;
        case ActionTypes.sessions.update_game_session:
            return ({ ...state ,...action.payload});
        case ActionTypes.sessions.clear_game_session:
            return {};
        default:
            return state;
    }
}
const sessionUsers={
    session_id:'',
    users:[]
}
export const currentGameSessionUsers = (state = sessionUsers, action) => {

    switch (action.type) {
        case ActionTypes.sessions.set_current_session_users:
            return action.payload;
        case ActionTypes.sessions.update_current_session_users:
            state.users.push(action.payload);
            return  state;
        case ActionTypes.sessions.clear_game_session:
            return sessionUsers;
        default:
            return state;
    }
}