import { useAuth } from '@app/modules/auth';
import { Checkbox } from '@material-ui/core';
import { Divider, Input } from 'antd';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const VideoModal = ({ isOpens, isClose, onSubmit = () => { } }) => {
    // const getAllGames = useAllGames();
    // const location = useLocation()
    const {currentGameContext } = useAuth()

    // const dispatch = useDispatch();
    // const currentURL = location.pathname;
    // const currentGameId = currentURL.split('/').pop();
    // const getCurrentGame = find(getAllGames, (item) => item.game_id === currentGameId);
    const getCurrentGame = currentGameContext;

    // const [selectedImage, setSelectedImage] = useState(null);
    // const [YouTubeVideoId, setYouTubeVideoId] = useState("");
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [error, setError] = useState(null);
    const [videoLink, setVideoLink] = useState('');
    const [embeddedVideo, setEmbeddedVideo] = useState('');

    const handleCloseModal = () => {
        isClose(false);
        setSelectedVideo(null)
        setIsVideoLoadedID("")
    };

    useEffect(() => {

        const fullVideoUrl = getCurrentGame?.media_urls?.video; // Adjust this based on your actual data structure
        // Extract video ID from the full video URL
        const videoId = fullVideoUrl && fullVideoUrl.split('/').pop();

        if (videoId && videoIds.includes(videoId)) {
            setSelectedVideo(videoId)
            setVideoLink("")

        } else {
            setSelectedVideo("")
            setVideoLink(fullVideoUrl)
        }

    }, [isOpens])


    const handleSaveModal = () => {

        if (selectedVideo) {

            const you_url = `https://www.youtube.com/embed/${selectedVideo}`;

            // Assuming onSubmit is a function that takes a URL as an argument
            setSelectedVideo(null)
            setIsVideoLoadedID("")
            setVideoLink("")
            setEmbeddedVideo("")
            onSubmit(you_url);
            isClose(false);
            setError(null)
        } else {
            const you_url = "";
            setIsVideoLoadedID("")

            // Assuming onSubmit is a function that takes a URL as an argument
            setSelectedVideo(null)
            setEmbeddedVideo("")
            setVideoLink("")
            onSubmit(you_url);
            isClose(false);
            setError(null)
        }



    };




    const videoIds = [
        'HwjkTH5xCTw', // Rick Astley - Never Gonna Give You Up
        'vIrH5SOhiEw', // Psy - Gangnam Style
        'C9PYzGyIfF8', // Psy - Gentleman
        'mcyIL_1QmaQ', // Luis Fonsi - Despacito ft. Daddy Yankee
        'uIjwGUsTu-U',
        'BioazdzMBq4',
        'g7dUyKpugeA',
        'iVxo8eIpWNg',
        // Add more video IDs as needed
    ];



    const embedVideo = () => {
        setSelectedVideo("")
        // Extract video ID from the YouTube link
        const videoId = extractVideoId(videoLink);

        if (videoId) {
            // Embed the video in an iframe
            const embedCode = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
            setSelectedVideo(videoId)
            setEmbeddedVideo(embedCode);
            setError(null);
        } else {
            // Handle invalid YouTube link
            // console.error('Invalid YouTube link');
            setError('No video found!');
        }
    };

    const extractVideoId = (url) => {
        // Extract video ID from the YouTube link
        const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|.*[?&]v=)([^"&?\/\s]{11})/);
        return match ? match[1] : null;
    };

    // const handleCheckboxChange = (videoId) => {
    //     setSelectedVideo(videoId);
    //     setEmbeddedVideo("")
    //     setVideoLink("")
    //     setError(null)
    // };

    const handleCheckboxChange = (videoId) => {
        if (selectedVideo === videoId) {
            // If the clicked video ID is the same as the currently selected one,
            // unselect it by setting selectedVideo to null.
            setSelectedVideo(null);
            setEmbeddedVideo("");
            setVideoLink("");
            setError(null);
        } else {
            // If the clicked video ID is different, select it.
            setSelectedVideo(videoId);
            setEmbeddedVideo("");
            setVideoLink("");
            setError(null);
            // Add the rest of your logic for setting embedded video, video link, error, etc.
        }
    };


    // const opts = {
    //     height: '140',
    //     width: '100%'

    // };


    const [isVideoLoadedID, setIsVideoLoadedID] = useState("");


    const handleVideoClick = (id) => {

        setIsVideoLoadedID(id)
    };

    const thumbnailStyle = {
        cursor: 'pointer',
        height: '120px', // Set the desired height
        width: '100%',
    };


    return (
        <Modal show={isOpens} className='galleryPicUpr' scrollable >
            <Modal.Header closeButton onClick={handleCloseModal} > <Modal.Title>Gallery Videos</Modal.Title></Modal.Header>
            {/* <div className='galleryMdlCls' onClick={handleCloseModal}>
                <img src={require('../../utils/assets/images/close.png')} alt="" title="" />
            </div> */}
            <Modal.Body>
                <div className='galleryPic videoContin'>
                    {/* <label>Gallery Videos</label> */}
                    <ul>
                        <ul className="video-list">
                            {videoIds.map((videoId, index) => (
                                <li key={index} className="video-item" style={{ position: 'relative',marginTop:10 }}>
                                    {isVideoLoadedID !== videoId && (
                                        <div className="thumbnail-container">
                                            <img
                                                src={`https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`}
                                                alt="Video Thumbnail"
                                                onClick={() => handleVideoClick(videoId)}
                                                style={thumbnailStyle}
                                            />
                                            <div className="play-button-overlay"
                                                onClick={() => handleVideoClick(videoId)}

                                            >
                                                ▶
                                            </div>
                                        </div>
                                    )}
                                    {isVideoLoadedID === videoId && (
                                        <iframe
                                            title={`YouTube Video for ${videoId}`}
                                            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                                            allow="autoplay"
                                            frameBorder="0"
                                            allowFullScreen
                                            style={thumbnailStyle}
                                        ></iframe>
                                    )}

                                    <div style={{ position: 'absolute', top: -8, right: -8 }}>
                                        <Checkbox
                                            className='checkBoxCont'
                                            checked={selectedVideo === videoId}
                                            onChange={() => handleCheckboxChange(videoId)}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
                {/* {videoIds.map((videoId, index) => (
                    <div key={index} className="video-item">
                        <YouTube videoId={videoId} opts={opts} />
                    </div>
                ))} */}
                <div>
                    <Divider className='text-dark'>Youtube Link</Divider>
                    <div className='youTubeAdd' style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ fontWeight: '700', minWidth: 70, marginRight: '10px' }}>Video link</label>
                        <div style={{ flex: 1, position: 'relative', marginRight: '10px' }}>
                            <Input
                                onChange={(e) => setVideoLink(e.target.value)}
                                placeholder="Please enter youtube link"
                                value={videoLink || ''}
                                style={{ width: '100%', marginRight: 10 }}
                            />
                            {videoLink && (
                                <button
                                    onClick={() => setVideoLink('')}
                                    style={{
                                        position: 'absolute',
                                        right: '8px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                    }}
                                >
                                    X
                                </button>
                            )}
                        </div>
                        <Button type="primary" onClick={embedVideo}>
                            Generate
                        </Button>
                    </div>

                    <div>
                        {error ? (
                            <div className='text-center d-flex align-items-center justify-content-center font-weight-bold'>{error}</div>
                        ) : (
                            <div className='genrateVdo'>
                                {/* Display the embedded video or other content */}
                                {embeddedVideo && <div dangerouslySetInnerHTML={{ __html: embeddedVideo }} />}
                            </div>
                        )}
                    </div>

                </div>


                {/* <div className='galleryUpload'>
                    <div className='nwGaleryUpload'>
                        <label>Upload New Videos</label>
                    
                        <input
                            type="file"
                            accept="video/*"
                            onChange={(event) => {
                                handleImageUpload(event)
                            }}
                        />
                    </div>
                    {selectedImage && (
                        <div className='nwGaleryPrevw'>
                            <p>Preview:</p>
                          
                            <video controls width="155" style={{ borderRadius: 10 }}>
                                <source src={URL.createObjectURL(selectedImage)} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    )}

                </div> */}
            </Modal.Body>
            {(((!selectedVideo || !videoLink) && getCurrentGame?.media_urls?.video) || selectedVideo || videoLink) && <Modal.Footer>
                <Button variant="secondary" onClick={handleSaveModal}>Submit</Button>
            </Modal.Footer>}
        </Modal>
    )
}

export { VideoModal };

