/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownOutlined, EnterOutlined, LoadingOutlined } from '@ant-design/icons'
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { Button, Col, Input, Modal, Row, Select, Spin, notification, Radio, Menu, Dropdown, Checkbox } from 'antd'
import { filter, find, get, map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../../../../app/modules/apps/IPlayGame/Home/game-style.css'
import { AudioModal } from '../../../../app/modules/apps/IPlayGame/components/AudioModal'
import { GamePinGenerator } from '../../../../app/modules/apps/IPlayGame/components/GamePinGenerator'
import { ImageModal } from '../../../../app/modules/apps/IPlayGame/components/ImageModal'
import { VideoModal } from '../../../../app/modules/apps/IPlayGame/components/VIdeoModal'
import { useGameCategory } from '../../../../app/modules/apps/IPlayGame/store/GameCategory/selectors'
import { useAllGames } from '../../../../app/modules/apps/IPlayGame/store/GameCreate/selectors'
import {
  CreateGames,
  generateQuestionsAnswersAI,
} from '../../../../app/modules/apps/IPlayGame/store/GameCreate/thunks'
import { CreateGameQuestion } from '../../../../app/modules/apps/IPlayGame/store/GameQuestions/thunks'
import { useAuth } from '../../../../app/modules/auth'
import {
  UserGameCategory,
  UserGameSubCategory,
} from '../../../../app/modules/apps/IPlayGame/store/GameCategory/thunks'
import { CircularProgress, MenuItem, Select as SelectTimer, makeStyles } from '@material-ui/core'
import { useGameQuestions } from '@IPlayGame/store/GameQuestions/selectors'
import { AITopic, AIBook, audiomodalurl1 } from '@IPlayGame/utils/assets/images/index'
import PreviewGame from '@app/modules/apps/IPlayGame/components/PreviewGame'
import Loading from '@app/modules/apps/IPlayGame/components/Loading'
import * as Sentry from '@sentry/react';
import { useSharedSession } from '@auth/core/SessionShare'


const GradeConfig = ({ options, value, label, onChange }) => {
  const label_txt = useMemo(() => {
    const f_value = value ? find(options, k => k.value == value || k.id == value) : null;
    if (f_value) {
      return f_value.title || f_value.type
    }
    return label;
  }, [value, options, label])
  const handleMenuClick = (e) => {
    const f_value = find(options, k => k.value == e.key || k.id == e.key)
    if (f_value) {
      onChange(f_value)
    }
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      {map(options, pt => <Menu.Item key={pt.id || pt.value}>
        {pt.title || pt.type}
      </Menu.Item>)}
    </Menu>
  );

  return (
    <div className='linksSectionIcon text-dark'>
      <Dropdown overlay={menu}>
        <div className='linksSectionIcon text-dark'>
          {value ? (
            <span>
              <CheckCircleOutlineIcon style={{ color: 'green' }} />
            </span>
          ) : (
            <span>
              <AddCircleSharpIcon />
            </span>
          )}
          {label_txt}
        </div>
      </Dropdown>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-select': {
      backgroundColor: 'transparent', // Set background color to transparent
    },
  },
}));

const AITopics = [
  'Family',
  'Hispanic Food',
  'Hispanic Celebrations',
  'Hispanic Celebrations',
  'Peace',
  'Values',
  'Hispanic Cities',
  'Hispanic History',
  'Friendship',
  'Respect Each',
  'Respect Art',
  'Hispanic History',
];

const ModalAI = ({ gameOpen, setOpenAIModal, defaultPage, onSelectedQuestionsChange, comesFrom, content }) => {
  const classes = useStyles();
  const [gameVisibleEbk, setgameVisibleEbk] = useState(gameOpen)
  const [selected, setSelected] = useState(false)
  const [currentScenarioEbk, setCurrentScenarioEbk] = useState(1)
  const [selectedAdd, setSelectedAdd] = useState(false)
  const [gradingIsOpen, setGradingIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [visible, setVisible] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(false)
  const [gameTitle, setGameTitle] = useState('')
  const [questions, setQuestions] = useState({ questions: [] })
  const [topic, settopic] = useState('')
  const [pointvalue, setpointvalue] = useState('')
  const [error, seterror] = useState('')
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const { currentUser, currentGameContext, saveCurrentGameContext } = useAuth()
  const getAllGames = useAllGames()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentURL = location.pathname
  const currentGameId = currentURL.split('/').pop()
  // const getCurrentGame = find(getAllGames, (item) => item.game_id === currentGameId)
  const getCurrentGame = currentGameContext;
  const [uploadedImage, setUploadedImage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [uploadedImageImg, setUploadedImageImg] = useState('')
  const [selectedBookOption, setselectedBookOption] = useState('')
  const [selectedSubOption, setselectedSubOption] = useState('')
  const [showModalImg, setShowModalImg] = useState(false)
  const [showModalAudio, setShowModalAudio] = useState(false)
  const [showModalPin, setShowModalPin] = useState(false)
  const [bookCategory, setBookCategory] = useState()
  const [btnLoader, setBtnLoader] = useState(false)
  const [btnLoaderAI, setBtnLoaderAI] = useState(false)
  const [subCategoryList, setSubCategoryList] = useState([])
  const [subCategoryList1, setSubCategoryList1] = useState([])
  const [subCategoryList2, setSubCategoryList2] = useState([])
  const [selectedOptionSingle, setSelectedOptionSingle] = useState(null)
  const gamesCategory = useGameCategory()
  const [playForGrade, setPlayForGrade] = useState(false)
  const GameQuestions = useGameQuestions()
  const [waiting, setwaiting] = useState(false)
  const [playing, setplaying] = useState(false)
  const mediaUrl = get(getCurrentGame, 'media_urls', {}) ?? {}
  const gametype = get(getCurrentGame, 'game_type', {}) ?? {}
  const studymetadata = get(getCurrentGame, 'study_metadata', {}) ?? {}
  const [studyType, setStudyType] = useState('');
  const { sharedSessionData } = useSharedSession()
  const game_grade_category = get(sharedSessionData, "metadata.grade_category", [])
  const game_grade_type = get(sharedSessionData, "metadata.grade_type", [])

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [gameId, setGameId] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [ai_content_loader, setAiContentLoader] = useState(false);
  const [contenTopic, setContenTopic] = useState('');
  const [count, setCount] = useState(0);
  const [modeType, setModeType] = useState('');
  const [selectModernType, setSelectModernType] = useState('magic_goal');
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectLimitTime, setSelectLimitTime] = useState('');
  const handleRadioChangeGame = (value) => {
    setSelectedValue(value);
    setSelectLimitTime('');
  };
  useEffect(() => {
    const data = {
      user_id:
        get(currentUser, 'is_admin', '') === true ? 'owe_admin' : get(currentUser, 'user_id', ''),
    }
    dispatch(UserGameCategory(data))
  }, [])

  useEffect(() => {

    if (content) {
      settopic(content?.contentInfo)
      setLoading(true)
      setAiContentLoader(true)
      setContenTopic(content?.content)
      fetchQuestionsAI('first', content?.content)
    }
  }, [content])
  useEffect(() => {
    if (gamesCategory) {
      onCategoryFilter()
    }
  }, [gamesCategory])

  const onCategoryFilter = () => {
    const categoryFilter = filter(gamesCategory, (item) => item?.parent_id === '0')
    setBookCategory(categoryFilter)
  }

  const handleWordLimit = (text) => {
    const maxLength = 80;
    const trimmedValue = text.slice(0, maxLength);
    // if (text.length <= 80) {
    settopic(trimmedValue);
    setCount(trimmedValue.length);
    // }
    // else {
    //   return;
    // }
  }

  const getDynamicMessage = () => {
    let errorMessages = [];
    if (GameQuestions?.length === 0) {
      errorMessages.push('Add at least one Question');
    }
    if (!gameTitle?.trim()) {
      errorMessages.push('add a catchy name');
    }
    if (getCurrentGame?.category_id === null) {
      errorMessages.push('choose books and pages');
    }
    if (!mediaUrl?.image && !uploadedImage) {
      errorMessages.push('upload an image');
    }
    if (!selectModernType) {
      errorMessages.push('choose modern type');
    }
    if (getCurrentGame?.game_mode === 'modern' && !selectedValue) {
      errorMessages.push('choose modern configuration');
    }
    if (selectedValue === 'limited' && !selectLimitTime) {
      errorMessages.push('choose limited time');
    }
    // if (getCurrentGame?.game_mode === 'modern' && !selectLimitTime){
    //   errorMessages.push('Time Should be Selected');
    // }
    if (errorMessages.length > 0) {
      const errorMessage = `Almost There! ${errorMessages.join(', ')} and Save again.`;
      return errorMessage;
    }
  };

  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Kindly attempt once more.',
      description: 'Feel free to give it another shot.',
    })
  }
  const openNotificationWithIcons = (type) => {
    if (type === 'info') {
      api[type]({
        message: 'Hold on!',
        description: getDynamicMessage(),
        // 'Almost There! add a catchy name, choose books and pages, and upload an image. Add those elements and Save again.',
      })
    } else {
      api[type]({
        message: 'successfully saved.',
        description: 'All set! The game name has been successfully saved.',
      })
    }
  }

  const optionss = [
    { id: 1, label: 'Competitive mode' },
    { id: 2, label: 'Study mode (flashcards)' },
    { id: 3, label: 'Exam mode' },
  ]

  const handleCheckboxChange = (option) => {
    setSelectedOptionSingle(option.id === selectedOptionSingle ? null : option.id)
  }

  useEffect(() => {
    if (defaultPage) {
      setCurrentScenarioEbk(defaultPage)
    } else return
  }, [defaultPage])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setGameTitle(getCurrentGame?.display_name ? getCurrentGame?.display_name : gameTitle)
        setgrade_category(getCurrentGame.grade_category ? getCurrentGame.grade_category : {})
        setgrade_type(getCurrentGame.grade_type ? getCurrentGame.grade_type : {})
        setUngraded(getCurrentGame.ungraded)
        setSelectModernType(getCurrentGame?.modern_type ? getCurrentGame?.modern_type : 'magic_goal');
        setSelectedValue(getCurrentGame?.games_metadata?.gameChanceType ? getCurrentGame?.games_metadata?.gameChanceType : selectedValue)
        setSelectLimitTime(getCurrentGame?.games_metadata?.modernTimeLimit ? getCurrentGame?.games_metadata?.modernTimeLimit : selectLimitTime)
        const Filter = find(gamesCategory, (item) => item?.id === getCurrentGame?.category_id)

        if (Filter) {
          const categoryFilter = find(gamesCategory, (item) => item?.id === Filter?.parent_id)

          setselectedBookOption(categoryFilter?.category_name)
          if (subCategoryList1?.length === 0) {
            getSelectValues(categoryFilter?.id, "outside")
          }
          const categoryFilterUnit = find(
            subCategoryList1,
            (item) => item?.id === getCurrentGame?.category_id
          )
          setselectedSubOption(categoryFilterUnit?.category_name)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        Sentry.captureException(`047 ModalAi Game fetching data ${error}.`);

      }
    }

    // Call the fetchData function
    if (getCurrentGame?.category_id) {

      fetchData()
    }

  }, [gamesCategory, subCategoryList1, gameOpen, getCurrentGame])

  useEffect(() => {
    if (subCategoryList1) {
      setSubCategoryList(subCategoryList1)
    }
  }, [subCategoryList1])

  useEffect(() => {
    if (subCategoryList2) {
      setSubCategoryList(subCategoryList2)

    }
  }, [subCategoryList2])

  // Check if the prop contains a specific value

  const showModalEbk = () => {
    setgameVisibleEbk(true)
    setCurrentScenarioEbk(1)
  }

  const handleNextEbk = () => {
    setCurrentScenarioEbk(currentScenarioEbk + 1)
    setContenTopic('')
  }
  // const handleButtonClick = () => {
  //   setSelected(true);
  // };

  const getTrueLabel = (value) => {
    if (value === 'Verdadero') {
      return 'True';
    } else if (value === 'Falso') {
      return 'False';
    } else {
      return value;
    }
  }

  const handleButtonClick = async (index) => {
    try {
      // Prevent event propagation to stop unexpected behavior
      // event.stopPropagation();

      // Find the selected question based on its index
      const selectedQuestion = questions[index]

      // Update the selected state based on the selected question
      setSelected((prevSelected) => {
        const questionIndex = prevSelected.findIndex((question) => question.index === index)

        if (questionIndex === -1) {
          // Add the question to the selected list
          return [...prevSelected, { index, question: selectedQuestion }]
        } else {
          // Remove the question from the selected list
          const newSelected = [...prevSelected]
          newSelected.splice(questionIndex, 1)
          return newSelected
        }
      })

      // Prepare answers based on the selected question type
      let answers
      if (selectedQuestion.type === 'quiz') {
        answers = {
          options: selectedQuestion.options,
          answer: selectedQuestion.correct_answer || selectedQuestion.correctAnswer,
        }
      } else if (selectedQuestion.type === 'true_or_false') {
        answers = {
          options: ['True', 'False'],
          answer: getTrueLabel(selectedQuestion.correct_answer),
        }
      } else if (selectedQuestion.type === 'type_answer') {
        answers = {
          options: [selectedQuestion.correct_answer] || [selectedQuestion.correctAnswer],
          answer: [selectedQuestion.correct_answer] || [selectedQuestion.correctAnswer],
        }
      }
      else if (selectedQuestion.type === 'flash_card') {
        answers = {
          options: [selectedQuestion.correct_answer] || [selectedQuestion.correctAnswer],
          answer: [selectedQuestion.correct_answer] || [selectedQuestion.correctAnswer],
        }
      }

      // Prepare question data
      const question = {
        title: selectedQuestion.question,
        imageUrl: '',
        time: '20 seconds',
        option: {
          id: 1,
          label: 'Standard',
          subLabel: 'Award correct answers with the normal of ponits ',
        },
      }

      // Prepare data for dispatch
      const data = {
        org_id: get(currentUser, 'company_id', ''),
        created_by: get(currentUser, 'user_id', ''),
        type: selectedQuestion.type,
        question,
        answers,
        game_id: currentGameId,
      }

      // Dispatch the action and await its completion
      await dispatch(CreateGameQuestion(data))

      // Additional logic after the dispatch, if needed
    } catch (error) {
      console.error('Error handling button click:', error)
      Sentry.captureException(`046 ModalAi Game Save ${error}.`);

      // Handle error appropriately
    }
  }

  const handleAddButtonClick = () => {
    setSelectedAdd(true)
  }
  const handleSelectChange = (option) => {
    setSelectedOption(option)
    setIsOpen(false)
  }
  const handleSelectGrading = (option) => {
    setpointvalue('')
    setSelectedOptions(option)
    setGradingIsOpen(false)
  }
  const handleCancelEbk = () => {
    setOpenAIModal(false)
    // setgameVisibleEbk(false);
    seterror('')
    settopic('')
    setContenTopic('')
    if (comesFrom === 'ai') {
      setCurrentScenarioEbk(5)
    }
  }
  const handleClick = async (type, StudyType) => {
    if (type === 'custom') {
      setBtnLoader(true)
    } else {
      setBtnLoaderAI(true)
    }
    const data = {
      created_by: get(currentUser, 'user_id', ''),
      org_id: get(currentUser, 'company_id', ''),
      study_metadata: {
        type: StudyType ? StudyType : studyType
      },
      game_type: comesFrom === "1study" ? "1study" : "1game",
      status: false,
      game_mode: comesFrom === "1study" ? null : modeType
    }
    const result = await dispatch(CreateGames(data, saveCurrentGameContext))
    if (result) {
      setBtnLoader(false)
      navigate(`/apps/create/${get(result, 'game_id', '')}`, {
        state: { blank_ai: type === 'ai' ? true : false },
      })
    }
  }

  function parseResponse(responseString) {
    const cleanedString = typeof responseString === "string" ? responseString.replace(/```json\n|\n```|\\n/g, '') : null;
    if (typeof responseString === "object" && Object.keys(responseString).length > 0) return responseString;
    try {
      return JSON.parse(cleanedString);
    } catch (error) {
      console.error("Failed to parse response:", error);
      Sentry.captureException(`045 ModalAi Game Create ${error}.`);

      return null; // or handle the error as you see fit
    }
  }

  const fetchQuestionsAI = async (comesFrom, content) => {
    try {
      if (topic !== '' || content !== '') {
        if (comesFrom == 'first') {
          setCurrentScenarioEbk(3)
        }

        let maxAttempts = 5
        let questionsArray

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
          const data = {
            userTopic: content ? content : contenTopic ? contenTopic : topic,
            gameType: gametype,
            StudyType: studymetadata?.type
          }


          const response = await dispatch(generateQuestionsAnswersAI(data))

          // Check if the response status is 200
          if (response.status === 200) {
            try {
              // Attempt to parse the response data
              questionsArray = parseResponse(response.data.generatedQuestions[0])

              // Check if the parsed data is an array
              if (Array.isArray(questionsArray)) {
                // Found a valid array, update state and exit the loop
                setQuestions(questionsArray)
                setCurrentScenarioEbk(4)
                seterror('')
                setLoading(false)
                setSelected([])
                setAiContentLoader(false)
                return
              } else {
                console.log('Invalid array found. Retrying...')
              }
            } catch (parseError) {
              console.log('Error parsing JSON data:', parseError)
              Sentry.captureException(`044 ModalAi fetchgame Ai ${parseError}.`);

              // Retry the loop immediately if a parsing error occurs
              continue
            }
          } else {
            console.log(`Received ${response.status} status. Retrying...`)

          }
        }

        // If the loop completes without finding a valid array or status 200
        openNotificationWithIcon('error')
        // seterror("Please try again..");
        if (!ai_content_loader) {
          settopic('')
        }
        setCurrentScenarioEbk(5)
      } else {
        // seterror("Please type a topic..");
        openNotificationWithIcon('error')
      }
    } catch (error) {
      Sentry.captureException(`048 ModalAi fetchgame Ai ${error}.`);

      // This block will only execute if there's an error during the loop
      // seterror("Please try again..");
      openNotificationWithIcon('error')
      if (!ai_content_loader) {
        settopic('')
      }
      setCurrentScenarioEbk(5)
    }
    setAiContentLoader(false)
  }

  const onUpdateGameTitle = async () => {
    if (gameTitle) {
      const data = {
        ...getCurrentGame,
        display_name: gameTitle,
      }
      const createdGameData = await dispatch(CreateGames(data, saveCurrentGameContext))
      openNotificationWithIcons('success')
      onFinalSave(createdGameData)
    }
  }

  const onUploadGameMedia = (media, type) => {
    try {
      const isVideoPresent = getCurrentGame?.media_urls?.video
      const isAudioPresent = getCurrentGame?.media_urls?.audio
      const isWaiting = getCurrentGame?.media_urls?.waiting

      if (media && type) {
        if (type === 'image') {
          // Replace existing image with the new one
          // const data = {
          //   ...getCurrentGame,
          //   media_urls: {
          //     ...getCurrentGame?.media_urls,
          //     image: media,
          //   },
          // }
          // dispatch(CreateGames(data, saveCurrentGameContext))
          setUploadedImage(media)
        } else if (type === 'audio' || type === 'video') {
          if (type === 'video' && isWaiting && isAudioPresent) {
            // Show modal confirmation when uploading video, waiting is true, and audio is present
            Modal.confirm({
              title: 'Replace Video',
              content:
                'If you save this video, the audio from waiting will be removed. Do you want to proceed?',
              onOk: () => {
                // User clicked OK, remove audio from waiting and update waiting to false
                const data = {
                  ...getCurrentGame,
                  media_urls: {
                    ...getCurrentGame?.media_urls,
                    video: media,
                    waiting: false,
                  },
                }
                dispatch(CreateGames(data, saveCurrentGameContext))
              },
              onCancel: () => {
                // User clicked Cancel
                // You can handle any additional logic or simply do nothing
              },
            })
          } else if (type === 'video') {
            // Replace existing image with the new one
            const data = {
              ...getCurrentGame,
              media_urls: {
                ...getCurrentGame?.media_urls,
                video: media,
              },
            }
            dispatch(CreateGames(data, saveCurrentGameContext))
          } else if (type === 'audio' && isVideoPresent && waiting) {
            // Show modal confirmation when uploading audio, waiting is true, and video is present
            const data = {
              ...getCurrentGame,
              media_urls: {
                ...getCurrentGame?.media_urls,
                audio: media,
                video: '',
                waiting: waiting,
                playing: playing,
              },
            }
            dispatch(CreateGames(data, saveCurrentGameContext))
          } else if (type === 'audio') {
            // If waiting is false, update audio directly without confirmation
            const data = {
              ...getCurrentGame,
              media_urls: {
                ...getCurrentGame?.media_urls,
                audio: media,
                waiting: waiting,
                playing: playing,
              },
            }
            dispatch(CreateGames(data, saveCurrentGameContext))
          } else {
            // // If waiting is false or uploading video, update media URLs directly without confirmation
            // const data = {
            //   ...getCurrentGame,
            //   media_urls: {
            //     ...getCurrentGame?.media_urls,
            //     [type]: media,
            //   },
            // };
            // dispatch(CreateGames(data, saveCurrentGameContext));
          }
        }
      } else {
        if (type === 'video') {
          // Replace existing image with the new one
          const data = {
            ...getCurrentGame,
            media_urls: {
              ...getCurrentGame?.media_urls,
              video: '',
            },
          }
          dispatch(CreateGames(data, saveCurrentGameContext))
        }

        if (type === 'audio') {
          // Replace existing image with the new one
          const data = {
            ...getCurrentGame,
            media_urls: {
              ...getCurrentGame?.media_urls,
              audio: '',
              playing: false,
              waiting: false,
            },
          }
          dispatch(CreateGames(data, saveCurrentGameContext))
        }
      }
    } catch (e) {

      Sentry.captureException(`049 ModalAi Search ${e}.`);

    }
  }

  const selectOptions = bookCategory?.map((cat) => ({
    label: cat.category_name,
    value: cat.id,
  }))
  const selectOptionsSubCategory = subCategoryList.map((cat) => ({
    label: cat.category_name ?? '',
    value: cat.id,
  }))

  const getSelectValues = async (id, comes) => {
    if (comes === "outside") {
      const data = {
        id,
      }
      const filterSubCategory = await UserGameSubCategory(data)
      setSubCategoryList1(filterSubCategory)
    }
    else {
      const data = {
        id,
      }
      const filterSubCategory = await UserGameSubCategory(data)
      setSubCategoryList2(filterSubCategory)
    }
  }

  const onChangeCategory = async (value, key) => {
    if (key === 'category') {
      getSelectValues(value)
      const categoryFilter = find(bookCategory, (item) => item?.id === value)
      setselectedBookOption(categoryFilter?.category_name)
      setselectedSubOption("")
    } else {
      const categorySub = find(subCategoryList, (item) => item?.id === value)
      setselectedSubOption(categorySub?.category_name)
    }
  }
  const onSearch = (value) => {
    console.log('search:', value)
  }

  const handleBlur = (e) => {
    try {
      const enteredValue = e.target.value;
      // console.log()
      // Check if the entered value exists in the list of options
      const optionExists = selectOptionsSubCategory.some(
        (option) => option.label.toLowerCase() === enteredValue.toLowerCase()
      );

      // If the entered value exists, set it as the selected value
      if (optionExists && enteredValue) {
        setselectedSubOption(enteredValue)
      }
    } catch (e) {
      Sentry.captureException(`043 ModalAi Search ${e}.`);

    }
  };


  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  const handleSave = async () => {
    try {
      const categorySub = find(subCategoryList, (item) => item?.category_name === selectedSubOption)
      if (gameTitle?.trim() && categorySub?.id && (mediaUrl?.image || uploadedImage) && GameQuestions?.length != 0 && selectModernType) {
        if (getCurrentGame?.game_mode === 'modern' && !selectedValue) {
          openNotificationWithIcons('info');
        } else if (getCurrentGame?.game_mode === 'modern' && selectedValue === 'limited' && !selectLimitTime) {
          openNotificationWithIcons('info');
        }
        else {
          const data = {
            ...getCurrentGame,
            media_urls: {
              ...getCurrentGame?.media_urls,
              image: uploadedImage ? uploadedImage : mediaUrl?.image,
              audio: mediaUrl?.audio ? mediaUrl?.audio : audiomodalurl1,
              playing: mediaUrl?.audio ? mediaUrl?.playing : true
            },
            category_id: categorySub?.id,
            display_name: gameTitle,
            games_metadata: {
              ...getCurrentGame?.games_metadata,
              type: selectedOptions,
              marking: pointvalue,
              gameChanceType: getCurrentGame?.game_mode === 'modern' && selectedValue,
              modernTimeLimit: (getCurrentGame?.game_mode === 'modern' && selectedValue === 'limited' && selectLimitTime) ? selectLimitTime : null
            },
            study_metadata: {
              ...getCurrentGame?.study_metadata,
            },
            game_type: gametype,
            status: 'true',
            pin_code: randomNumber,
            grade_category,
            grade_type,
            ungraded,
            modern_type: getCurrentGame?.game_mode === 'modern' ? selectModernType : null
          }
          const createGameResult = await dispatch(CreateGames(data, saveCurrentGameContext))
          if (createGameResult) {
            openNotificationWithIcons('success')
            const queryParams = new URLSearchParams(location.search)
            const paramValue = queryParams.get('from')
            handleCancelEbk()
            if (paramValue === 'outer') {
              setTimeout(backToNosotros, 2000)
            } else {
              setTimeout(() => navigate(`/apps/home/`), 2000)
            }
          }
        }
      } else {
        openNotificationWithIcons('info');
      }
    } catch (e) {
      Sentry.captureException(`041 ModalAi Game Save ${e}.`);
    }
  }
  const backToNosotros = () => {
    if (window != window.top) {
      window.parent.postMessage({ action: 'close_one_game' }, '*')
    } else {
      navigate(`/apps/home/`)
    }
  }
  const onFinalSave = (createdGameData) => {
    try {
      if (
        createdGameData?.status === 'false' &&
        GameQuestions?.length > 0 &&
        createdGameData?.display_name !== null &&
        createdGameData?.category_id !== null
      ) {
        const data = {
          ...createdGameData,
          status: true,
        }
        dispatch(CreateGames(data, saveCurrentGameContext))
      }
    } catch (e) {
      Sentry.captureException(`042 ModalAi Game Save ${e}.`);


    }
  }

  const [showInput, setShowInput] = useState(false)
  const [randomNumber, setRandomNumber] = useState('')
  const handleRadioChange = () => {
    setShowInput(!showInput)
    if (!showInput) {
      const newRandomNumber = Math.floor(100000 + Math.random() * 900000).toString()
      setRandomNumber(newRandomNumber)
    }
  }
  const [grade_category, setgrade_category] = useState({})
  const [grade_type, setgrade_type] = useState({})
  const [ungraded, setUngraded] = useState(false)

  const getGameList = (item) => {
    setLoader(item);
    if (GameQuestions.length > 0) {
      setTimeout(() => {
        setDrawerOpen(true);
        setLoader(null);
      }, 3000);
      setGameId(item);
    }
    else {
      setLoader(null);
      openCheckNotification('error')
    }
  }

  const openCheckNotification = (type) => {
    api[type]({
      message: 'Attention is needed to add at least one game !',
      description:
        'Please make sure to add at least one game to continue',
    });
  };

  const handleFlashcard = (game_type) => {
    setStudyType(game_type)
  }

  const handleMode = (mode_type) => {
    setModeType(mode_type);
  }

  const selectOptionsModernType = [
    { value: "magic_goal", label: "The magic goal" },
    { value: "king_jump", label: "The king of jump" },
    { value: "run", label: "Run, run, and run" },
    { value: "park", label: "Out of the park" }
  ];

  const handleChange = (value) => {
    setSelectModernType(value);
  };

  const onTopicClick = async (topic) => {
    settopic(topic);
    try {
      if (topic !== '') {
        setCurrentScenarioEbk(3)
        let maxAttempts = 5
        let questionsArray
        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
          const data = {
            userTopic: topic,
            gameType: gametype,
            StudyType: studymetadata?.type
          }
          const response = await dispatch(generateQuestionsAnswersAI(data))
          if (response.status === 200) {
            try {
              questionsArray = parseResponse(response.data.generatedQuestions[0])
              if (Array.isArray(questionsArray)) {
                setQuestions(questionsArray)
                setCurrentScenarioEbk(4)
                seterror('')
                setLoading(false)
                setSelected([])
                setAiContentLoader(false)
                return
              } else {
                console.log('Invalid array found. Retrying...')
              }
            } catch (parseError) {
              console.log('Error parsing JSON data:', parseError)
              Sentry.captureException(`044 ModalAi fetchgame Ai ${parseError}.`);
              continue
            }
          } else {
            console.log(`Received ${response.status} status. Retrying...`)

          }
        }
        openNotificationWithIcon('error')
        if (!ai_content_loader) {
          settopic('')
        }
        setCurrentScenarioEbk(5)
      } else {
        openNotificationWithIcon('error')
      }
    } catch (error) {
      Sentry.captureException(`048 ModalAi fetchgame Ai ${error}.`);
      openNotificationWithIcon('error')
      if (!ai_content_loader) {
        settopic('')
      }
      setCurrentScenarioEbk(5)
    }
    setAiContentLoader(false)
  }

  return (
    <>
      {loader && <Loading isOpen={loader} isClose={setLoader} />}
      {contextHolder}
      <Modal
        open={gameOpen}
        onCancel={handleCancelEbk}
        className={`bg-body scenMdlWrapCstm scenarioMdlWrapNew ${currentScenarioEbk >= 2 ? 'gmScenarioSecond' : ''
          }`}
      >
        <div>
          {(currentScenarioEbk === 1 && comesFrom !== "1study") && (
            <>

              {modeType ? (
                <div className='aiGmMdl'>
                  <div className='aiMdlLbl text-dark'>
                    <label>Create your 1Game!</label>
                  </div>
                  <div className='aiGmMdlInr'>
                    <div className='aiMdlOvr'>
                      <a
                        onClick={() => {
                          handleClick('custom')
                        }}
                      >
                        <span>
                          {btnLoader ? (
                            <CircularProgress size={24} style={{ color: 'teal' }} />
                          ) : (
                            'Blank template'
                          )}
                        </span>
                        <img
                          src={require('../../../../app/modules/apps/IPlayGame/Home/images/tabImg.png')}
                          alt=''
                        />
                      </a>
                    </div>
                    <div className='aiMdlOvr'>
                      <a
                        onClick={() => {
                          handleClick('ai')
                        }}
                      >
                        <span>
                          {btnLoaderAI ? (
                            <CircularProgress size={24} style={{ color: 'teal' }} />
                          ) : (
                            <span>
                              <label>AI Generator using</label><label> my own topics</label>
                            </span>
                          )}
                        </span>
                        <img src={AITopic} alt='' />
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='aiGmMdl'>
                  <div className='aiMdlLbl text-dark'>
                    <label>Choose your mode!</label>
                  </div>
                  <div className='aiGmMdlInr'>
                    <div className='aiMdlOvr'>
                      <a
                        onClick={() => {
                          handleMode('classic')
                        }}
                      >
                        <span>
                          Classic
                        </span>
                        <img
                          src={AIBook}
                          alt=''
                        />
                      </a>
                    </div>
                    <div className='aiMdlOvr'>
                      <a
                        onClick={() => {
                          handleMode('modern')
                        }}
                      >
                        <span>
                          Modern
                        </span>
                        <img src={AITopic} alt='' />
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {(currentScenarioEbk === 1 && comesFrom === "1study") && (
            <>
              {!studyType &&
                <div className='aiGmMdl'>
                  <div className='aiMdlLbl text-dark'>
                    <label>Create your 1Study!</label>
                  </div>
                  <div className='aiGmMdlInr'>
                    <div className='aiMdlOvr'>
                      {/* <Link to="/apps/create/"><span>Blank template</span> <img src={require('../../../../app/modules/apps/IPlayGame/Home/images/tabImg.png')} alt="" /></Link> */}
                      <a
                        onClick={() => { handleFlashcard('quiz') }}
                      >
                        <span>
                          Quiz
                        </span>
                        <img
                          src={require('../../../../app/modules/apps/IPlayGame/Home/images/tabImg.png')}
                          alt=''
                        />
                      </a>
                    </div>
                    {/* <div className='aiMdlOvr'><a onClick={handleNextEbk}><span><label>AI Generator</label> using this section content</span> <img src={require('./images/ai.png')} alt="" /></a></div> */}
                    <div className='aiMdlOvr'>
                      <a
                        onClick={() => { handleFlashcard('flash_card') }}
                      >
                        <span> Flashcards</span>
                        <img src={AITopic} alt='' />
                      </a>
                    </div>
                  </div>
                </div>
              }
              {studyType &&
                <div className='aiGmMdl'>
                  <div className='aiMdlLbl text-dark'>
                    <label>Create your 1Study!</label>
                  </div>
                  <div className='aiGmMdlInr'>
                    <div className='aiMdlOvr'>
                      {/* <Link to="/apps/create/"><span>Blank template</span> <img src={require('../../../../app/modules/apps/IPlayGame/Home/images/tabImg.png')} alt="" /></Link> */}
                      <a
                        onClick={() => {
                          handleClick('custom')
                        }}
                      >
                        <span>
                          {btnLoader ? (
                            <CircularProgress size={24} style={{ color: 'teal' }} />
                          ) : (
                            'Blank template'
                          )}
                        </span>
                        <img
                          src={require('../../../../app/modules/apps/IPlayGame/Home/images/tabImg.png')}
                          alt=''
                        />
                      </a>
                    </div>
                    {/* <div className='aiMdlOvr'><a onClick={handleNextEbk}><span><label>AI Generator</label> using this section content</span> <img src={require('./images/ai.png')} alt="" /></a></div> */}
                    <div className='aiMdlOvr'>
                      <a
                        onClick={() => {
                          handleClick('ai')
                        }}
                      >
                        <>
                          {btnLoaderAI ? (
                            <CircularProgress size={24} style={{ color: 'teal' }} />
                          ) : (
                            <span>
                              {' '}
                              <label>AI Generator using</label> my own topics
                            </span>
                          )}
                        </>{' '}
                        <img src={AITopic} alt='' />
                      </a>
                    </div>
                  </div>
                </div>
              }
            </>
          )}
          {currentScenarioEbk === 2 && (
            <div className='writeTpcUpr'>
              <div className='writeTpcEbk'>
                <p>Choose from what section of this page you want us to create!</p>
                <img
                  src={require('../../../../app/modules/apps/IPlayGame/Home/images/ai.png')}
                  alt=''
                />
              </div>
              <div className='choseQuesUpr'>
                <div onClick={handleNextEbk}>
                  <p>Create questions from the entire article</p>
                </div>
                <div onClick={handleNextEbk}>
                  <p>Create questions using the vocabulary words</p>
                </div>
                <div onClick={handleNextEbk}>
                  <p>Create reflexive questions using the main topic</p>
                </div>
                <div onClick={handleNextEbk}>
                  <p>Create reflexive questions using the main topic</p>
                </div>
              </div>
            </div>
          )}
          {(currentScenarioEbk === 3 || ai_content_loader) && (
            <div className='yourMagic'>
              <div className='yourMagicImg'>
                {/* <img
                  src={require('../../../../app/modules/apps/IPlayGame/Home/images/ai.png')}
                  alt=''
                /> */}
                <img
                  src={'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/AI_Topic.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222'}
                  alt=''
                />
              </div>
              <div className='yourMagicTxt'>
                <p>Working on your magic!</p>
              </div>
              <div className='maginSpinner'>
                <Spin
                  size='large'
                  indicator={
                    <div className='magicIndicator'>
                      <div className='dot' />
                      <div className='dot' />
                      <div className='dot' />
                      <div className='dot' />
                    </div>
                  }
                />
              </div>
            </div>
          )}
          {currentScenarioEbk === 4 && (
            <div className='writeTpcUpr'>
              <div className='createBckBtn text-dark'>
                <span onClick={handleNextEbk} className='arrowBckBtn'>
                  <ArrowBackIosIcon />
                </span>
                AI Creator
              </div>

              <div className='writeTpcEbk writeTpcEbkSection'>
                <p>Your questions are ready for your selection! </p>
                {/* <img
                  src={require('../../../../app/modules/apps/IPlayGame/Home/images/ai.png')}
                  alt=''
                /> */}
                <img
                  src={'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/AI_Topic.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222'}
                  alt=''
                />
              </div>
              <div className='generateMore'>
                <div className='subGenerateMore'>
                  {/* { */}
                  {/* // contenTopic ? <label className='AIFieldMore'>{topic}</label> : */}

                  <textarea
                    className='AIFieldMore'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey && topic.trim() !== '') {
                        e.preventDefault()
                        setLoading(true)
                        fetchQuestionsAI()
                      }
                    }}
                    onChange={(e) => handleWordLimit(e.target.value)}
                    value={topic}
                    // disabled={contenTopic}
                    type='text'
                    placeholder='Write your topic or choose one from below'
                  />
                  {/* // } */}
                  <Button
                    className='gmAI'
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      if (topic.trim() !== '') {
                        setLoading(true)
                        fetchQuestionsAI()
                      }
                    }}
                  >
                    Generate more{' '}
                    {loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: 'white', marginLeft: '10px' }}
                            spin
                          />
                        }
                      />
                    ) : (
                      ''
                    )}
                  </Button>
                </div>
              </div>
              <div className='quesSelect'>
                {/* <div className='quesSelectTxt'><p>Title: EI 14 de febrero, amor o mercadeo?</p></div>

            <div className='quesSelectTxt'>
              <Button onClick={handleButtonClick}
                className={selected ? 'greenButton' : 'defaultButton'}>{selected ? 'Selected' : 'Select'}
                {selected ? <RemoveCircleIcon /> : <AddCircleSharpIcon />}</Button>
              <p>1. Origen del Dia de san valentin:</p>
              <p>A. Roma Antigua</p>
              <p>B. Grecia Moderna <img src={require('../../../../app/modules/apps/IPlayGame/Home/images/right.jpg')} alt="" /></p>
              <p>C. Egipto Antiguo</p>
              <p>D. Edad Media Europea</p>
            </div>
            <div className='quesSelectTxt'>
              <Button onClick={handleAddButtonClick}
                className={selectedAdd ? 'greenButton' : 'defaultButton'}>{selectedAdd ? 'Selected' : 'Select'}
                {selectedAdd ? <RemoveCircleIcon /> : <AddCircleSharpIcon />}</Button>
              <p>1. Origen del Dia de san valentin:</p>
              <p>A. Roma Antigua</p>
              <p>B. Grecia Moderna <img src={require('../../../../app/modules/apps/IPlayGame/Home/images/right.jpg')} alt="" /></p>
              <p>C. Egipto Antiguo</p>
              <p>D. Edad Media Europea</p>
            </div>

            <div className='allCreateBtn'>
              <Button>Cancel</Button>
              <Button onClick={() => { setCurrentScenarioEbk(6) }}>Create and play now!</Button>
              <Button>Create to play later!</Button>
            </div> */}

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  {Array.isArray(questions) &&
                    questions.map((question, index) => (

                      <div key={index} className='quesSelectTxt'>
                        <div className='questionContainer'>
                          <Button
                            onClick={(e) => handleButtonClick(index)}
                            className={
                              selected?.some((selected) => selected.index === index)
                                ? 'greenButton'
                                : 'defaultButton'
                            }
                            disabled={selected?.some((selected) => selected.index === index)}
                          >
                            {selected.some((selected) => selected.index === index)
                              ? 'Selected'
                              : 'Select'}{' '}
                            {selected?.some((selected) => selected.index === index) ? (
                              <RemoveCircleIcon />
                            ) : (
                              <AddCircleSharpIcon />
                            )}
                          </Button>

                          <p
                            style={{
                              position: 'absolute',
                              top: -14,
                              padding: '0px 10px',
                              background: 'white',
                              borderRadius: 25,
                              color: 'black',
                              fontSize: 15,
                            }}
                          >
                            {question?.type === 'quiz'
                              ? 'Quiz'
                              : question?.type === 'true_or_false'
                                ? 'True or False'
                                : question?.type === 'type_answer'
                                  ? 'Type Answer'
                                  : question?.type === 'flash_card'
                                    ? 'Flash-Card'
                                    : ''}
                          </p>
                          <p>
                            {index + 1}. {question.question}
                          </p>

                          {question.type === 'quiz' && (
                            <div className='optionsContainer'>
                              {question.options.map((option, optionIndex) => (
                                <p key={optionIndex}>
                                  {String.fromCharCode(65 + optionIndex)}. {option}
                                  {(question.correct_answer === option ||
                                    question.correctAnswer === option) && (
                                      <img
                                        src={require('../../../../app/modules/apps/IPlayGame/Home/images/right.jpg')}
                                        alt='Correct'
                                      />
                                    )}
                                </p>
                              ))}
                            </div>
                          )}

                          {question.type === 'true_or_false' && (
                            <div className='optionsContainer'>
                              {/* <p>
                                A. True{' '}
                                {(question.correct_answer === 'verdadero' ||
                                  question.correct_answer === 'Verdadero' ||
                                  question.correctAnswer === 'verdadero' ||
                                  question.correctAnswer === 'Verdadero') && (
                                    <img
                                      src={require('../../../../app/modules/apps/IPlayGame/Home/images/right.jpg')}
                                      alt='Correct'
                                    />
                                  )}
                              </p>
                              <p>
                                B. False{' '}
                                {(question.correct_answer === 'false' ||
                                  question.correct_answer === 'False' ||
                                  question.correctAnswer === 'false' ||
                                  question.correctAnswer === 'False') && (
                                    <img
                                      src={require('../../../../app/modules/apps/IPlayGame/Home/images/right.jpg')}
                                      alt='Correct'
                                    />
                                  )}
                              </p> */}
                              {question.options.map((option, optionIndex) => (
                                <p key={optionIndex}>
                                  {String.fromCharCode(65 + optionIndex)}. {option}
                                  {(question.correct_answer === option ||
                                    question.correctAnswer === option) && (
                                      <img
                                        src={require('../../../../app/modules/apps/IPlayGame/Home/images/right.jpg')}
                                        alt='Correct'
                                      />
                                    )}
                                </p>
                              ))}
                            </div>
                          )}

                          {['type_answer'].includes(question.type) && (
                            <div className='optionsContainer'>
                              <p>Answer: {question.correct_answer || question.correctAnswer}</p>
                            </div>
                          )}

                          {['flash_card'].includes(question.type) && (
                            <div className='optionsContainer'>
                              <p>Answer: {question.correct_answer || question.correctAnswer}</p>
                            </div>
                          )}
                        </div>
                      </div>


                    ))}
                </div>
                <div className='allCreateBtn'></div>
              </div>
            </div>
          )}

          {(currentScenarioEbk === 5 && !ai_content_loader) && (
            <div className='writeTpcUpr'>
              <div className='text-center mb-5' style={{ color: 'red' }}>
                {error}
              </div>

              <div className='writeTpc'>
                <textarea
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey && topic.trim() !== '') {
                      e.preventDefault()
                      fetchQuestionsAI('first', '')
                    }
                  }}
                  className='AIField'
                  value={topic}
                  // onChange={(e) => settopic(e.target.value)}
                  onChange={(e) => handleWordLimit(e.target.value)}
                  placeholder='Write your topic or choose one from below'
                ></textarea>
                {topic && (
                  <div style={{ position: 'relative' }}>
                    <Button
                      onClick={() => {
                        if (topic.trim() !== '') {
                          fetchQuestionsAI('first', '')
                        }
                      }}
                      style={{ height: 45, marginLeft: 12 }}
                      type='primary'
                    >
                      Generate
                    </Button>
                    <span style={{ color: 'white', position: 'absolute', bottom: '-30px', left: '5px' }}>{`${count}/80`}</span>
                  </div>
                )}
                <img
                  src={'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/AI_Topic.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222'}
                  alt=''
                />
              </div>
              <div className='writeTpcBtns'>
                {map(AITopics, (label, index) => (
                  <Button onClick={() => onTopicClick(label)}>{label}</Button>
                ))}
              </div>
            </div>
          )}
          {currentScenarioEbk === 6 && (
            <div className='writeTpcUpr'>
              <div className='newTxtModal'>
                <span className='text-dark'>
                  {gametype === "1study" ? 'Name your 1study:' : 'Name your 1Game:'}
                </span>

                <Input
                  placeholder={gametype === "1study" ? 'Enter study Title' : 'Enter Game Title'}
                  value={gameTitle}
                  onChange={(e) => setGameTitle(e.target.value)}
                  // onPressEnter={() => {
                  //   onUpdateGameTitle();
                  // }}
                  className='inputWith-outlined'
                />
                <EnterOutlined
                  // onClick={() => onUpdateGameTitle()}
                  className='enter-outlined'
                />
              </div>
              <div className=''>
                <Row justify='start' gutter={20}>
                  <Col xs={24} lg={12} className='mt-3'>
                    <div className='oneGameTxtBook'>
                      <label className='text-dark'>Textbook:</label>
                      <Select
                        defaultValue='Books'
                        style={{ width: '100%', height: 35 }}
                        value={selectedBookOption ? selectedBookOption : ''}
                        showSearch
                        placeholder='Select a books'
                        optionFilterProp='children'
                        onChange={(value) => onChangeCategory(value, 'category')}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={selectOptions}
                      />
                    </div>
                  </Col>
                  <Col xs={24} lg={7} className='mt-3'>
                    {subCategoryList && subCategoryList?.length > 0 && (
                      <div className='oneGameTxtBook'>
                        <label className='text-dark'>Page:</label>
                        <Select
                          defaultValue='Pages'
                          style={{ width: '100%', height: 35 }}
                          value={selectedSubOption ? selectedSubOption : ''}
                          showSearch
                          placeholder='Select a pages'
                          optionFilterProp='children'
                          onChange={(value) => onChangeCategory(value, 'subCategory')}
                          onSearch={onSearch}
                          filterOption={filterOption}
                          options={selectOptionsSubCategory}
                          onBlur={(e) => handleBlur(e)}
                        />
                        {/* <p>Preview game</p> */}
                      </div>
                    )}
                  </Col>
                  <Col xs={24} lg={5} className='mt-3'>
                    {gametype === "1study" ? "" :

                      <div className='oneGameModalPreview text-dark'>
                        <p onClick={() => getGameList(getCurrentGame?.game_id)}>Preview game</p>
                      </div>
                    }
                  </Col>
                </Row>
              </div>

              {/* <div className='mainMidSection'> */}
              {/* <div className='allMyUnitssection'>
                <div className="image-container">
                  <img style={{ width: 150, borderRadius: 8 }} src={getCurrentGame?.media_urls ? getCurrentGame?.media_urls?.image : 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fimg21.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'} alt="Image" className="image" />
                  <div className="edit-icon">
                  
                    <span onClick={() => setShowModalImg(true)} >Edit</span>
                  </div>
                </div>
                <ImageModal isOpens={showModalImg} isClose={setShowModalImg} onSubmit={(e) => {
                  onUploadGameMedia(e)
                }} />
              </div> */}
              {/* <div className='allQuesSelectTxt'> */}
              {/* {optionss.map((option) => (
                  <div className="form-check form-check-sm form-check-custom form-check-solid custmCheckbox">
                    <input className="form-check-input" type="checkbox" value="1" id={option.id} checked={option.id === selectedOptionSingle} onChange={() => handleCheckboxChange(option)} />
                    <label for={option.id} className='text-dark'>{option.label}</label>
                  </div>
                ))} */}
              {/* <div className="form-group">
                <input type="checkbox" id="html" />
                <label for="html">Competitive mode</label>
              </div>
              <div className="form-group">
                <input type="checkbox"
                  id="flashcards"
                />
                <label
                  for="flashcards"
                >Study mode (flashcards)</label>
              </div>
              <div className="form-group">
                <input type="checkbox" id="javascript" />
                <label for="javascript">Exam mode</label>
              </div> */}
              {/* </div> */}
              {/* <div className='youtubAllPart'>
                <div className='allAddCircleIcon text-dark' onClick={() => {
                  setShowModal(true)
                }}><span><AddCircleSharpIcon /></span>YouTube video for the waiting room</div>
                <div className='allAddCircleIcon text-dark' onClick={() => {
                  setShowModalAudio(true)
                }}><span><AddCircleSharpIcon /></span>Choose a song for the waiting room</div>
                <div className='allAddCircleIcon text-dark'
                  onClick={() => {
                    setShowModalPin(true)
                  }}
                ><span><AddCircleSharpIcon /></span>Add code for entry</div>
              </div> */}
              {/* <div className='allCreateBtnPart' style={{ flexDirection: 'column' }}>
                {selectedOptionSingle !== 3 && (
                  <>

                    {get(currentUser, 'is_admin', '') !== true && <Button style={{ backgroundColor: '#7C29E7' }} onClick={() => { setCurrentScenarioEbk(7) }}>Play for fun</Button>}
                  </>
                )}
                {get(currentUser, 'is_admin', '') !== true && (
                  <Button style={{ backgroundColor: '#00B936' }} onClick={() => { setCurrentScenarioEbk(8) }}>Play for grade</Button>
                )}
               
              </div> */}
              {/* </div> */}
              <div className='mt-10'>
                <Row>
                  <Col xs={24} lg={5}>
                    <div className='allMyUnitssectionNew newImgTabs'>
                      <div className='image-container topPadRemove'>
                        <img
                          onClick={() => setShowModalImg(true)}
                          style={{ width: '100%', borderRadius: 15, objectFit: 'contain', cursor: "pointer" }}
                          src={
                            uploadedImage
                              ? uploadedImage
                              : getCurrentGame?.media_urls?.image
                                ? getCurrentGame.media_urls.image
                                : 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/select_image_cove.jpg?alt=media&token=40964ae8-79a1-42df-ae90-f83c35c8ffd0'
                          }
                          alt='Image'
                          className='image'
                        />
                        <div className='edit-icon'>
                          <div className='uprBnrIcon'>
                            <label style={{ textShadow: 'rgb(0, 0, 0) 2px 0px 5px', color: 'white' }}>Choose cover image</label>
                            <span className='editBnrIcon' onClick={() => setShowModalImg(true)}>
                              <img
                                src='https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/addimg.png?alt=media&token=40964ae8-79a1-42df-ae90-f83c35c8ffd0'
                                alt=''
                                title=''
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <ImageModal
                        isOpens={showModalImg}
                        isClose={setShowModalImg}
                        onSubmit={(e) => {
                          onUploadGameMedia(e, 'image')
                        }}
                      />
                    </div>
                  </Col>
                  {getCurrentGame?.game_mode === 'modern' && (
                    <Col xs={24} lg={getCurrentGame?.game_mode === 'modern' ? 7 : 9}>
                      <div>
                        <div>
                          <div>
                            <div className='entryCodeLft' style={{ marginBottom: 15 }}>
                              <div className='form-check form-check-sm form-check-custom form-check-solid createCustmCheckboxOneGame oneGameCodeEntry'>
                                <input
                                  className='form-check-input checkIconsAns forborder'
                                  type='radio'
                                  name='entryCode'
                                  id='entryCode1'
                                  onChange={() => handleRadioChangeGame('unlimited')}
                                  value='unlimited'
                                  checked={selectedValue === 'unlimited'}
                                />
                                <label htmlFor='entryCode1' className='text-dark radoText'>Unlimited</label>
                              </div>
                            </div>
                            <div className='entryCodeLft' style={{ marginBottom: 15 }}>
                              <div className='selectTm form-check form-check-sm form-check-custom form-check-solid createCustmCheckboxOneGame oneGameCodeEntry'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                    className='form-check-input checkIconsAns forborder'
                                    type='radio'
                                    name='entryCode'
                                    id='entryCode2'
                                    onChange={() => handleRadioChangeGame('limited')}
                                    value='limited'
                                    checked={selectedValue === 'limited'}
                                  />
                                  <label htmlFor='entryCode2' className='text-dark radoText'>Limited</label>

                                </div>

                                {selectedValue === 'limited' && (
                                  <div style={{ border: '1px solid #d9d9d9', backgroundColor: 'white', minWidth: 100, borderRadius: 20 }}>
                                    <SelectTimer
                                      className={classes.root}
                                      disableUnderline={true}
                                      style={{ width: '100%' }}
                                      required={true}
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      value={selectLimitTime}
                                      onChange={(v) => {
                                        const times = v.target.value;
                                        setSelectLimitTime(times)
                                      }}
                                      label="Type"
                                    >
                                      {[...Array(59)].map((_, index) => (
                                        <MenuItem key={index + 1} value={index + 1}>{index + 1 + ' ' + 'min'}</MenuItem>
                                      ))}
                                    </SelectTimer>
                                  </div>
                                )}

                              </div>
                            </div>
                            <div className='entryCodeLft' style={{ marginBottom: 15 }}>
                              <div className='form-check form-check-sm form-check-custom form-check-solid createCustmCheckboxOneGame oneGameCodeEntry'>
                                <input
                                  className='form-check-input checkIconsAns forborder'
                                  type='radio'
                                  name='entryCode'
                                  id='entryCode3'
                                  onChange={() => handleRadioChangeGame('EndGame')}
                                  value='EndGame'
                                  checked={selectedValue === 'EndGame'}
                                />
                                <label htmlFor='entryCode3' className='text-dark radoText'>End game post-answers</label>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </Col>
                  )}
                  <Col xs={24} lg={getCurrentGame?.game_mode === 'modern' ? 11 : 18}>
                    {gametype === "1study" ? "" :
                      <>
                        <div className='linksMain'>
                          {/* <div className='linksSection'>
                            <GradeConfig label={"Choose grading system"} value={grade_type?.value} options={game_grade_type} onChange={setgrade_type} />
                            <GradeConfig label={" Choose category"} value={grade_category?.id} options={game_grade_category} onChange={setgrade_category} />
                          </div> */}
                          <div className='linksSection'>
                            <div
                              className='linksSectionIcon text-dark'
                              onClick={() => {
                                setShowModal(true)
                              }}
                            >
                              {getCurrentGame?.media_urls?.video ? (
                                <span>
                                  <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </span>
                              ) : (
                                <span>
                                  <AddCircleSharpIcon />
                                </span>
                              )}
                              YouTube video for the waiting room
                            </div>
                            <div
                              className='linksSectionIcon text-dark'
                              onClick={() => {
                                setShowModalAudio(true)
                              }}
                            >
                              {getCurrentGame?.media_urls?.audio ? (
                                <span>
                                  <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                </span>
                              ) : (
                                <span>
                                  <AddCircleSharpIcon />
                                </span>
                              )}
                              Choose a song for the waiting room
                            </div>
                          </div>
                        </div>

                        <div className='entryCodeUpr'>
                          <div className='entryCode entryCodeLft'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid createCustmCheckboxOneGame oneGameCodeEntry'>
                              <input
                                onChange={handleRadioChange}
                                className='form-check-input checkIconsAns forborder'
                                type='checkbox'


                              />
                              <span className='text-dark'>Need code for entry</span>
                            </div>
                            {showInput && <Input value={randomNumber} readOnly className='entryCodeInput' />}
                          </div>
                          <div className='entryCode entryCodeRght'>
                            {/* <Radio style={{color: 'black'}} onChange={handleRadioChange}>
                          Ungraded
                        </Radio> */}
                            {/* <div className='form-check form-check-sm form-check-custom form-check-solid createCustmCheckboxOneGame oneGameCodeEntry'>
                              <input
                                onChange={e => {
                                  setUngraded(!ungraded)
                                }}
                                className='form-check-input checkIconsAns forborder'
                                type='checkbox'
                                checked={ungraded}
                              />
                              <span className='text-dark' >Ungraded</span>
                            </div>
                            <a className='entryCodeHvr'>
                              <img
                                src={require('../../../../app/modules/apps/IPlayGame/Home/images/infoIcon.png')}
                                alt=''
                              />
                              <label className='text-dark'>
                                Please, keep in mind that playing for fun will not save the scores in
                                the gradebook.
                              </label>
                            </a> */}
                          </div>
                        </div>
                        {getCurrentGame?.game_mode === 'modern' && (
                          // <div className='entryCodeUpr entryCodeUprPad'>
                          //   <label className='text-dark'>
                          //     Choose modern type :
                          //     <select value={selectModernType} defaultValue="magic_goal" onChange={(e) => {
                          //       setSelectModernType(e.target.value);
                          //     }} style={{ marginLeft: '10px' }}>
                          //       <option value="magic_goal">The magic goal</option>
                          //       <option value="king_jump">The king of jump</option>
                          //       <option value="run">Run, run, and run</option>
                          //       <option value="park">Out of the park</option>
                          //     </select>
                          //   </label>
                          // </div>
                          <div className='oneGameTxtBooks'>
                            <label className='text-dark'>Choose modern type :</label>
                            <Select
                              defaultValue="magic_goal"
                              value={selectModernType}
                              onChange={handleChange}
                              options={selectOptionsModernType}
                            />
                            {/* <p>Preview game</p> */}
                          </div>

                        )}
                      </>
                    }
                    <div>
                      {playForGrade ? (
                        <div className='catogryAndSystem'>
                          <div className='subCatogryAndSystem'>
                            <div className='allCategorySection'>
                              <div>
                                <div
                                  className={`selectCategoryBox ${isOpen ? 'open' : ''}`}
                                  onClick={() => setIsOpen(!isOpen)}
                                >
                                  {selectedOption || 'Choose category'}
                                  <div>
                                    <DownOutlined />
                                  </div>
                                </div>
                                {isOpen && (
                                  <div className='allOptions'>
                                    <div
                                      className='option'
                                      onClick={() => handleSelectChange('Homework')}
                                    >
                                      <p>Homework</p>
                                      <p>20%</p>
                                    </div>
                                    <div
                                      className='option'
                                      onClick={() => handleSelectChange('Participation')}
                                    >
                                      <p>Participation</p>
                                      <p>20%</p>
                                    </div>
                                    <div
                                      className='option'
                                      onClick={() => handleSelectChange('Quiz')}
                                    >
                                      <p>Quiz</p>
                                      <p>20%</p>
                                    </div>
                                    <div
                                      className='option'
                                      onClick={() => handleSelectChange('Classwork')}
                                    >
                                      <p>Classwork</p>
                                      <p>20%</p>
                                    </div>
                                    <div
                                      className='option'
                                      onClick={() => handleSelectChange('Exam')}
                                    >
                                      <p>Exam</p>
                                      <p>20%</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='allCategorySection'>
                              <div>
                                <div
                                  className={`gradingCategoryBox ${gradingIsOpen ? 'open' : ''}`}
                                  onClick={() => setGradingIsOpen(!gradingIsOpen)}
                                >
                                  {selectedOptions || 'Grading System'}
                                  <div>
                                    <DownOutlined />
                                  </div>
                                </div>
                                {gradingIsOpen && (
                                  <div className='allOptionsSection'>
                                    <div className='gardingCheckbox'>
                                      <div
                                        className='option'
                                        onClick={() => handleSelectGrading('Points')}
                                      >
                                        <p>Points</p>
                                      </div>
                                      <Input
                                        onChange={(e) => setpointvalue(e.target.value)}
                                        placeholder='Enter a value'
                                      />
                                    </div>
                                    <div
                                      className='option'
                                      onClick={() => handleSelectGrading('Percentage')}
                                    >
                                      <p>Percentage</p>
                                    </div>
                                    <div
                                      className='option'
                                      onClick={() => handleSelectGrading('Letters')}
                                    >
                                      <p>Letters</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className='allCreateBtnPart allStartPrviewBtn'>
                            <div className='startAndPreview startPrviewBtn'>
                              {/* <Link to="/apps/game-quiz"> */}
                              <Link>
                                <Button onClick={() => handleSave()}>Start</Button>
                              </Link>
                              <Button onClick={() => handleSave()}>Preview</Button>
                              <Button
                                // onClick={() => onFinalSave()}
                                onClick={() => {
                                  handleSave()
                                }}
                              >
                                Save for later
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='allCreateBtnPart createBtnNew createBtnNewCstm makeCenterBtn'>
                          {/* {selectedOptionSingle !== 3 && (
                      <> */}

                          {/* {get(currentUser, 'is_admin', '') !== true &&
                      <Button style={{ backgroundColor: '#7C29E7' }}
                       onClick={() => { setCurrentScenarioEbk(7) }}
                      >Play for fun</Button>
                    } */}

                          {/* </>
                    )} */}
                          {/* {(get(currentUser, 'is_admin', '') !== true && gametype === '1game') && (
                            <Button
                              style={{ backgroundColor: '#00B936' }}
                              onClick={() => {
                                setPlayForGrade(!playForGrade)
                              }}
                            >
                              Start 1Game
                            </Button>
                          )} */}
                          <Button
                            style={{ backgroundColor: '#7C29E7' }}
                            onClick={() => {
                              handleSave()
                            }}
                          >
                            Save for Later
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <VideoModal
                isOpens={showModal}
                isClose={setShowModal}
                onSubmit={(e) => {
                  onUploadGameMedia(e, 'video')
                }}
              />
              <AudioModal
                isOpens={showModalAudio}
                currentGameId={currentGameId}
                isClose={setShowModalAudio}
                waiting={waiting}
                setwaiting={setwaiting}
                playing={playing}
                setplaying={setplaying}
                onSubmit={(e) => {
                  onUploadGameMedia(e, 'audio')
                }}
              />
              <GamePinGenerator
                isOpens={showModalPin}
                isClose={setShowModalPin}
                onSubmit={(e) => {
                  // setUploadedImage(e)
                }}
              />
            </div>
          )}
          {currentScenarioEbk === 7 && (
            <div className='writeTpcUpr'>
              <div className='newTxtModal'>
                <span className='text-dark'>Name your 1Game:</span>
                <Input placeholder='' />
              </div>
              <div className='mainMidSection'>
                <div className='allMyUnitssection'>
                  <div className='myUnitsBnr'>
                    <img
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fimg21.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'
                      }
                      alt=''
                    />
                  </div>
                </div>
                <div className='allQuesSelectTxt'>
                  {/* {optionss.map((option) => (
                  <div className="form-group">
                    <input type="checkbox" id={option.id} checked={option.id === selectedOptionSingle} onChange={() => handleCheckboxChange(option)} />
                    <label for={option.id} className='text-dark'>{option.label}</label>
                  </div>
                ))} */}
                  {/* <p><img src={IGameRightTick} alt="" />Competitive mode</p>
                                        <p><img src={IGameRightTick} alt="" />Study mode (flashcards)</p>
                                        <p><img src={IGameRightTick} alt="" />Exam mode</p> */}
                </div>
                <div className='youtubAllPart'>
                  <div className='allAddCircleIcon text-dark'>
                    <span>
                      <AddCircleSharpIcon />
                    </span>
                    YouTube video for the waiting room
                  </div>
                  <div className='allAddCircleIcon text-dark'>
                    <span>
                      <AddCircleSharpIcon />
                    </span>
                    Choose a song for the waiting room
                  </div>
                  <div className='allAddCircleIcon text-dark'>
                    <span>
                      <AddCircleSharpIcon />
                    </span>
                    Add code for entry
                  </div>
                </div>
              </div>
              <div className='allCategorySection'>
                <div className='allCreateBtnPart'>
                  <Button>Play for fun</Button>
                </div>
                <div className='allCreateBtnPart allStartPrviewBtn'>
                  <div className='startAndPreview startPrviewBtn'>
                    {/* <Link to="/apps/game-quiz"> */}
                    <Link>
                      <Button>Start</Button>
                    </Link>
                    <Button>Preview</Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentScenarioEbk === 8 && (
            <div className='writeTpcUpr'>
              <div className='newTxtModal'>
                <span className='text-dark'>Name your 1Game:</span>
                <Input placeholder='' />
              </div>
              <div className='mainMidSection'>
                <div className='allMyUnitssection'>
                  <div className='myUnitsBnr'>
                    <img
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fimg21.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'
                      }
                      alt=''
                    />
                  </div>
                </div>
                <div className='youtubAllPart'>
                  <div className='allAddCircleIcon text-dark'>
                    <span>
                      <AddCircleSharpIcon />
                    </span>
                    YouTube video for the waiting room
                  </div>
                  <div className='allAddCircleIcon text-dark'>
                    <span>
                      <AddCircleSharpIcon />
                    </span>
                    Choose a song for the waiting room
                  </div>
                  <div className='allAddCircleIcon text-dark'>
                    <span>
                      <AddCircleSharpIcon />
                    </span>
                    Add code for entry
                  </div>
                </div>
              </div>
              <div className='catogryAndSystem'>
                <div className='subCatogryAndSystem'>
                  <div className='allCategorySection'>
                    <div>
                      <div
                        className={`selectCategoryBox ${isOpen ? 'open' : ''}`}
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {selectedOption || 'Choose category'}
                        <div>
                          <DownOutlined />
                        </div>
                      </div>
                      {isOpen && (
                        <div className='allOptions'>
                          <div className='option' onClick={() => handleSelectChange('Homework')}>
                            <p>Homework</p>
                            <p>20%</p>
                          </div>
                          <div
                            className='option'
                            onClick={() => handleSelectChange('Participation')}
                          >
                            <p>Participation</p>
                            <p>20%</p>
                          </div>
                          <div className='option' onClick={() => handleSelectChange('Quiz')}>
                            <p>Quiz</p>
                            <p>20%</p>
                          </div>
                          <div className='option' onClick={() => handleSelectChange('Classwork')}>
                            <p>Classwork</p>
                            <p>20%</p>
                          </div>
                          <div className='option' onClick={() => handleSelectChange('Exam')}>
                            <p>Exam</p>
                            <p>20%</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='allCategorySection'>
                    <div>
                      <div
                        className={`gradingCategoryBox ${gradingIsOpen ? 'open' : ''}`}
                        onClick={() => setGradingIsOpen(!gradingIsOpen)}
                      >
                        {selectedOptions || 'Grading System'}
                        <div>
                          <DownOutlined />
                        </div>
                      </div>
                      {gradingIsOpen && (
                        <div className='allOptionsSection'>
                          <div className='gardingCheckbox'>
                            <div className='option' onClick={() => handleSelectGrading('Points')}>
                              <p>Points</p>
                            </div>
                            <Input placeholder='' />
                          </div>
                          <div className='option' onClick={() => handleSelectGrading('Percentage')}>
                            <p>Percentage</p>
                          </div>
                          <div className='option' onClick={() => handleSelectGrading('Letters')}>
                            <p>Letters</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='allCreateBtnPart allStartPrviewBtn'>
                  <div className='startAndPreview startPrviewBtn'>
                    {/* <Link to="/apps/game-quiz"> */}
                    <Link>
                      <Button>Start</Button>
                    </Link>
                    <Button>Preview</Button>
                    <Button>Save for later</Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
      {drawerOpen && (
        <PreviewGame isOpen={true} GameId={gameId} isClose={setDrawerOpen} nosotros={false} />
      )}
    </>
  )
}

export { ModalAI }
