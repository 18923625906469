// //  ----------------------------------------------------------------------------
// //  Dependencies
// //  ----------------------------------------------------------------------------

// //  -- Constants ----------------------
import { ActionTypes } from '../constants';


// //  ----------------------------------------------------------------------------
// //  Actions
// //  ----------------------------------------------------------------------------

// //  -- Create Game ----------



export const setCurrentSessionSubmissions = (returnedPayload) => ({ type: ActionTypes.session_submissions.set_session_submissions, payload: returnedPayload });
export const ClearCurrentSessionSubmissions = () => ({ type: ActionTypes.session_submissions.clear_session_submissions });
