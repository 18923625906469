import { useRef, useState } from 'react';

const Index = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef();

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    }

    const handleCirclePlusClick = () => {
        fileInputRef.current.click();
    }

    return (
        <div className='addOnsQues'>
            <div className='addOnsTpTitle'><input type='text' placeholder='Type a title' /></div>
            <div className='addOnsUpload'>
                <label>
                    <input
                        ref={fileInputRef}
                        type='file'
                        accept='image/*, video/*'
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                    />
                    {!selectedFile && <img src={require('./../utils/assets/images/upload2.png')} alt="" />}
                </label>
                {selectedFile && (
                    <div className='preview'>
                        {selectedFile.type.startsWith('image/') && ( 
                            <img src={URL.createObjectURL(selectedFile)} alt='Preview' />
                        )}
                        {selectedFile.type.startsWith('video/') && (
                            <video controls>
                                <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                )}
                <i className="fa-solid fa-circle-plus" onClick={handleCirclePlusClick}></i>
            </div>
            <div><input type='text' placeholder='Type text' /></div>
        </div>
    )
}

export default Index;
