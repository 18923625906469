// import { Button, Col, Row } from 'antd';
// import { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { ChartsPieWidget9 } from '../../../../_metronic/partials/widgets/charts/ChartsPieWidget9';

// const ProjectorScreen = () => {

//     const [currentSection, setCurrentSection] = useState(1);
//     const nextSection = () => {
//         setCurrentSection(prev => prev + 1);
//     };
//     const data = [
//         ["Pizza", "Popularity"],
//         ["Pepperoni", 30],
//         ["Hawaiian", 10],
//         ["Mushroom", 60],
//     ];
//     const options = {
//         title: "Popularity of Types of Pizza",
//         sliceVisibilityThreshold: 0.2, // 20%
//     };

//     return (
//         <div className="classicLayBgProj">
//             {/* <div className="classicLayBgColor"> */}
//             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                 <Row className='containerProj'>
//                     <Col xs={24} lg={24} className='titlePro'>PROJECTOR/TEACHER VIEW</Col>
//                     <Col xs={24} lg={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50, position: 'relative' }} className="piechartProj">
//                             <ChartsPieWidget9
//                                 chartType="PieChart"
//                                 data={data}
//                                 options={options}
//                                 width={"100%"}
//                                 height={"400px"}
//                             />
//                             <span className="testLablPie" style={{ right: '60px' }}>
//                                 Correct answers
//                             </span>
//                             <span className="testLablPie" style={{ left: '-15px' }}>
//                                 Incorrect answers
//                             </span>
//                             <span className="testLablPie" style={{ top: '-100px' }}>
//                                 No response
//                             </span>
//                         </div>
//                     </Col>
//                     <Col lg={24} xs={24}>
//                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
//                             <Link to="/apps/best-player">
//                                 <Button style={{ backgroundColor: '#002d75', color: 'white', borderRadius: 40, minWidth: 90, height: 55, fontWeight: '800', fontSize: 18 }}>
//                                     NEXT!
//                                 </Button>
//                             </Link>
//                         </div>
//                     </Col>
//                 </Row>
//             </div>
//             {/* </div> */}
//         </div>
//     );
// };

// export default ProjectorScreen;






import React, { useState } from 'react'
import { useSpring, animated } from "react-spring";
// import { useState } from "react";

function IPlayGameProjector() {
    const [isHidden, setIsHidden] = useState(true);
    const [isChecked, setIsChecked] = useState(false);

    // Checkbox code

    const springProps = useSpring({
        backgroundColor: isChecked ? "#1e7709" : "#f0f0f0",
        transform: isChecked ? "scale(1.1)" : "scale(1)",
    });

    const toggleCheckbox = () => {
        // setChecked((prevChecked) => !prevChecked);
        setIsChecked(!isChecked);
    };

    const handleLstButtonClick = () => {
        setIsChecked(!isChecked);
    };
    // End Checkbox code
    return (
        <div>
            {/* <div className="allBtnSection"> */}
            {/* <animated.button
                    // style={animation}
                    // onMouseDown={handleMouseDown}
                    className="allForthBtn"
                    // onMouseUp={handleMouseUp}
                    // onTouchStart={handleMouseDown}
                    // onTouchEnd={handleMouseUp}
                    onClick={handleLstButtonClick}
                > */}
            {/* State of atmosphere */}
            {/* <div className="square homeBtnImgLst"></div> */}
            {/* {isHidden && ( */}
            <div
                className="checkbox-container fourCheck"
                onClick={toggleCheckbox}
            >
                <animated.div
                    className="checkbox"
                    style={springProps}
                >
                    {isChecked && (
                        <animated.div className="checkmark">
                            ✔
                        </animated.div>
                    )}
                </animated.div>
            </div>
            {/* )} */}
            {/* </animated.button> */}
        </div>
        // </div>
    )
}

export default IPlayGameProjector;



