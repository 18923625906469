import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FullLoader } from '../components/FullLoader';
import PreviewGame from '../components/PreviewGame';

function Privew() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const game_id = queryParams.get('game_id');
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [loader,setLoader] = useState(false);
    const handleLoader = () => {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        },3000)
    }
    useEffect(() => {
        handleLoader();
    }, [game_id])
  return (
      <>
          {loader ? <FullLoader /> :
              <PreviewGame isOpen={drawerOpen} GameId={game_id} isClose={setDrawerOpen} nosotros={true} />
          }
      </>
  )
}

export default Privew;