const gameThemeBrainstorm = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1Game%2F2023-12-20-63db84b3-353f-4f0c-9d79-6af4671dd59d%2FBrainstorm.jpeg?alt=media&token=4e4082a2-bc52-4040-a20d-b675f644ae30`;
const gameThemeRed = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1Game%2F2023-12-20-02decd7d-7814-433f-a263-9cdb36a72f61%2Fquiz2.jpeg?alt=media&token=23c5c6a7-2062-4a8c-843a-1b25c6192c5b`
const gameThemeQuestion = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1Game%2F21d937bb-f739-402c-84f3-c1606cea84b0%2F2023-12-20-0c8b1fd3-da7f-41ee-9f8b-486d5059c7c0%2FquizStudent.jpeg?alt=media&token=1d36bf05-e024-45c0-810a-cc416fc6bb5d`
const gameThemeCloud = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1Game%2F21d937bb-f739-402c-84f3-c1606cea84b0%2F2023-12-20-5790313d-09cf-4765-a404-2c56f26c564e%2Fwordcloud.png?alt=media&token=cfc8464d-2ffb-46cf-ba20-af263ceb18a5`
const IGameLogo = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2FGameLogo.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'
const IGameModalImgDeck = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fbg1.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'
const IGameHome8 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fimg22.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'
const AIBook = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/AI_Book.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const AITopic = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/AI_Topic.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const audiomodalurl1 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/waitingAudio1.mpeg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const audiomodalurl2 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/waitingAudio2.mpeg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const audiomodalurl3 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/waitingAudio3.mpeg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const audiomodalurl4 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/waitingAudio4.mpeg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const audiomodalurl5 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/waitingAudio5.mpeg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const audiomodalurl6 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/waitingAudio6.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const audiomodalurl7 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/waitingAudio7.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const audiomodalurl8 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/waitingAudio8.mpeg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const leaderboardaudio = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/leaderboard.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const endgameaudio = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/endgame.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';

// <-------------Modern Game Animation Sound ----------------->
const WowAnimation = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/wow_animation.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const VideoGameDeath = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/videogame_death_sound.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const SinisterLaugh = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/sinister_laugh.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const PunchSound = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/punch_6.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const NextLevelSound = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/next_level.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const multiPopSound = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/multi_pop_1.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const HappyPopSound = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/happy_pop_2.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const gameSplash = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/game_splash.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';

// <-------------Modern Game End Sound ----------------->
const WinMutedGuitarEnd = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/win_muted_guitar.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const WinGrandpianoEnd = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/win_grand_piano.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const LevelWinEnd = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/level_win.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const LevelCompleteEnd = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/level_completed.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const BrassFailEnd = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/brass_fail_1.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';

// <-------------Modern Game Student Waiting Sound ----------------->
const AvatarMusicLoop = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/avatar_music_loop.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const AvatarMusicLoop2 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/avatar_music_loop_2.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const AvatarSampleMusic = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/8bit_sample.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const AvatarTuneTank = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/tunetank.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';

// <-------------Modern Game--------------------->
const SoccerFootball = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/soccer_football.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const SoccerBckground = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/soccer_img.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222';
const SoccerVolleyball = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/soccer_volleyball.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222'

const IGameLogos = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2FGameLogo.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'
const getFireStoreUrl = (path) => (`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2F${path}?alt=media&token=6a9a7f5a-4959-431c-a305-8eabe60a4d46`);
const videoLink = getFireStoreUrl("videoLink.png");
const closeModal = getFireStoreUrl("closeModel.png");
export const studyGameLogo = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/studyLogos.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`

export {
    gameThemeRed,
    gameThemeCloud,
    gameThemeQuestion,
    gameThemeBrainstorm,
    IGameLogo,
    IGameModalImgDeck,
    IGameHome8,
    AIBook,
    AITopic,
    videoLink,
    closeModal,
    audiomodalurl1,
    audiomodalurl2,
    audiomodalurl3,
    audiomodalurl4,
    audiomodalurl5,
    audiomodalurl6,
    audiomodalurl7,
    audiomodalurl8,
    leaderboardaudio,
    endgameaudio,
    IGameLogos,
    SoccerFootball,
    SoccerBckground,
    SoccerVolleyball,
    WowAnimation,
    VideoGameDeath,
    SinisterLaugh,
    PunchSound,
    NextLevelSound,
    multiPopSound,
    HappyPopSound,
    gameSplash,
    WinMutedGuitarEnd,
    WinGrandpianoEnd,
    LevelWinEnd,
    LevelCompleteEnd,
    BrassFailEnd,
    AvatarMusicLoop,
    AvatarMusicLoop2,
    AvatarSampleMusic,
    AvatarTuneTank
}