//  ----------------------------------------------------------------------------
//  Store config
//  ----------------------------------------------------------------------------
//
//  Store constant directory
//
//  -- Description
//  Source of truth for store config values
//

//  -- Action types -------------------
export const ActionTypes = {
    gameQuestion: {
        listGameQuestion: "LIST_GAME_QUESTION",
        createGameQuestionSuccess: "CREATE_GAME_QUESTION_SUCCESS",
        updateGameQuestionSuccess: "UPDATE_GAME_QUESTION_SUCCESS",
        deleteGameQuestionSuccess: "DELETE_GAME_QUESTION_SUCCESS",
    },
    games: {
        listCreatedGame: "LIST_CREATED_GAME",
        createGameSuccess: "CREATE_GAME_SUCCESS",
        updateGameSuccess: "UPDATE_GAME_SUCCESS",
        deleteGameSuccess: "DELETE_GAME_SUCCESS",
    },
    gamesCategory: {
        listGameCategory: "LIST_GAME_CATEGORY",
        createGameCategorySuccess: "CREATE_GAME_CATEGORY_SUCCESS",
        updateGameCategorySuccess: "UPDATE_GAME_CATEGORY_SUCCESS",
        deleteGameCategorySuccess: "DELETE_GAME_CATEGORY_SUCCESS",
        listCategoryUnit: "LIST_CATEGORY_UNIT"
    },
    sessions:{
        set_game_session: "SET_GAME_SESSION",
        update_game_session: "UPDATE_GAME_SESSION",
        clear_game_session: "CLEAR_GAME_SESSION",

        set_current_session_users: "SET_CURRENT_SESSION_USERS",
        update_current_session_users: "UPDATE_CURRENT_SESSION_USERS",
        clear_current_session_users: "CLEAR_CURRENT_SESSION_USERS"
    },
    session_submissions: {
        set_session_submissions: "SET_SESSION_SUBMISSIONS",
        update_session_submissions: "UPDATE_SESSION_SUBMISSIONS",
        clear_session_submissions: "CLEAR_SESSION_SUBMISSIONS"

    },
    my_submission: {
        set_my_submission: "SET_MY_SUBMISSIONS",
        update_my_submission: "UPDATE_MY_SUBMISSIONS",
        clear_my_submission: "CLEAR_MY_SUBMISSIONS"
    },
    tutorial_video: {
        listtutorialvideo: "LIST_TUTORIAL_VIDEO",
        listImageCategory: "LIST_IMAGE_CATEGORY",
        listGameGallery: "LIST_GAME_GALLERY"
    }
};
