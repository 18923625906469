import { TagFilled } from '@ant-design/icons';
import { Box, Modal, Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { Button, Col, Row } from 'antd';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';
import { useTutorialVideoList } from './store/TutorialVideo/selectors';
import { getTutorialVideoList } from './store/TutorialVideo/thunks';
import { closeModal, videoLink } from './utils/assets/images';

const PAGE_CATEGORY = [
  "ANÉCDOTAS SERIES",
  "AP SPANISH LANGUAGE",
  "PROFICIENCY LEVELS",
  "LITERACY"
]
const VideoModal = styled.video`
    height:100%;
    width:100%;
`;

const VideoContainer = styled.div`
        display: flex;
        justify-content: center;
        height: 50vh;
        background: #fff;
        align-items: center;
`

const TutorialVideo = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [videoModalInfo, setVideoModalInfo] = useState({
    open: false,
    video: '',
    poster: ''
  });
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const allVideo = useTutorialVideoList();  
  const videos = allVideo?.data?.filter(category => category?.video_type === "tutorial");
  const dispatch = useDispatch();

  const fetchItem = async () => {
    setLoading(!videos || videos.length === 0)
    await dispatch(getTutorialVideoList())
    setLoading(false)
  }
  useEffect(() => {
    fetchItem()
  }, [])

  useEffect(() => {
    if (Array.isArray(videos)) {
      setFilteredData(videos)
    } else {
      setFilteredData([])
    }
  }, [allVideo])

  const handleOpen = (item) => setVideoModalInfo({
    open: true,
    video: item.video,
    poster: item.poster
  });
  const handleClose = () => setVideoModalInfo({
    open: false,
    video: '',
    poster: ''
  });

  const filterItem = (item) => {
    setSelectedFilter(item)
    if (item === "all") {
      setFilteredData(videos)
    } else {
      setFilteredData(filter(videos, ["category", item]))
    }
  }

  const filterItemTxt = (txt) => {

    if (txt && txt.length > 0) {
      setFilteredData(filter(videos, k => {
        if (k.tags && k.tags.toLowerCase().includes(txt.toLowerCase())) {
          return true;
        }
        if (k.category && k.category.toLowerCase().includes(txt.toLowerCase())) {
          return true;
        }
        if (k.title && k.title.toLowerCase().includes(txt.toLowerCase())) {
          return true;
        }
        return false;
      }))
    } else {
      setFilteredData(videos)

    }
  }

  const loader = () => {
    return (<div class="skeleton-wrapper">
      <div class="skeleton-card" style={{ textAlign: "center" }}>{loading ? '' : "No content available!"}</div></div>)
  }

  return (
    <div className="teacherStrategies">
      <Modal open={videoModalInfo.open} onClose={handleClose} className="modelCntnt">
        <Box sx={{}} className="modelCntntIner">
          <div className="lernModalBg">
            <VideoContainer className="modelDtl">
              <VideoModal controls controlsList="nodownload" autoPlay src={videoModalInfo.video} poster={videoModalInfo.poster} />
              <img
                src={closeModal}
                onClick={handleClose} className="closeModelInfo" />
            </VideoContainer>
          </div>
        </Box>
      </Modal>
      <Row className='catGryFilterRow'>
        <Col xs={{ span: 24 }} lg={{ span: 16 }}>
          <div className='filterTxts'>
            <p>Let’s learn some new strategies!</p>
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <div className='catGryfilterSearch'>
            <p>Search:</p>
            <input type="text" placeholder="" onChange={e => filterItemTxt(e.target.value)} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={{ span: 24 }}>
          <div className='categorySection'>
            <span>Categories</span>
          </div>
          <div className='categoryBtns'>
            <Button
              style={{ borderColor: selectedFilter === "all" && '#1890ff' }}
              onClick={() => filterItem("all")}>ALL</Button>
            {PAGE_CATEGORY.map(item => <Button
              style={{ borderColor: selectedFilter === item && '#1890ff', boxShadow: selectedFilter === item && 'rgb(94 42 231) 0px 0px 5px' }}
              onClick={() => filterItem(item)} key={item}>{item}</Button>)}
          </div>
        </Col>
      </Row>
      <div className='myCategorys'>
        {filteredData.length > 0 ? filteredData.map((category, index) => (
          <div className='myCategoryList'>
            <div className="ShowDetailBnr">
              <Link className="ShowDetail" style={{ cursor: "pointer" }} ><img
                src={videoLink}
                style={{ height: 50, width: 50, border: "0px" }} onClick={() => handleOpen(category)} /></Link>
              <img
                src={category.poster}
                alt="" /></div>
            <div className='categoryBtnTxt'>
              <Tooltip placement="top" title={category.title}>
                <div className='onway-teacher-txt'>{category.title}</div>
              </Tooltip>
              <div className='btm1Category'>
                <div className='persionIconImg'>
                  <PersonIcon className='iconClrChange' />
                  <span>{"ONE WAY EDUCATION"}</span>
                </div>
                <div className='persionIconImg mt-2'>
                  <TagFilled className='iconFldChange' style={{ marginRight: 13 }} />
                  <span> {category.tags || 'Uno,Dos,Tres'}</span>
                </div>
              </div>
            </div>
          </div>
        )) : loader()}
      </div>
    </div>
  );
};

export default TutorialVideo;
