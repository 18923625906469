/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Dispatch, FC, SetStateAction } from 'react';

const tabs: ReadonlyArray<{ link: string; icon: string; tooltip: string, label: string }> = [
  {
    link: 'IPlayGame',
    icon: '/media/icons/duotune/general/gen008.svg',
    tooltip: 'IPlayGame',
    label: 'IPlayGame'
  },
]




type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
}


const AsideTabs: FC<Props> = ({ link, setLink }) => {
  return (
    <div
      className='hover-scroll-y mb-10'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >

      <ul className='nav flex-column' id='kt_aside_nav_tabs'>

        {tabs?.map((t) => (
          <>

           
          </>
        ))}
      </ul>
    </div>
  )
}

export { AsideTabs };

