import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { find, findIndex, get } from 'lodash';
import { BiBorderAll } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useAuth } from '../../../auth';
import CustomSelect from '../CustomSelect.js';
import { LeavePageModal } from '@IPlayGame/components/LeavePageModal';
import { useAllGames } from '@store/GameCreate/selectors';
import { CreateGames, deleteGames } from '../store/GameCreate/thunks';
import { useGameQuestions } from '../store/GameQuestions/selectors';
import { setActionTab, setFeatures } from '../store/TabChange/thunks';
import { useLayout } from '@metronic/layout/core';
import { CloseCircleOutlined } from '@ant-design/icons';
import { studyGameLogo } from '../utils/assets/images';
const gameTypes = [
    { label: "Quiz", questionType: "quiz" },
    { label: "True or false", questionType: "true_or_false" },
    { label: "Type answer", questionType: "type_answer" },
    { label: "Drop pin", questionType: "drop_pin" },
    { label: "Word cloud", questionType: "word_cloud" },
    { label: "Open-ended", questionType: "open_ended" },
    { label: "Brainstorm", questionType: "brainstorm" },
    { label: "Poll", questionType: "poll" },
    { label: "Puzzle", questionType: "puzzle" },
    { label: "Informational Page", questionType: "informational_page" },
    { label: "Flash-Card", questionType: "flash_card" },
];
const projects = [
    // {
    //   image: '/media/svg/brand-logos/bebo.svg',
    //   title: 'Team Members',
    //   link: 'apps/settings/employees',
    // },

    {
        image: '/media/svg/brand-logos/bebo.svg',
        title: 'IPlayGame',
        link: 'apps/IPlayGame/',
        label: 'IPlayGame'
    },

    {
        image: '/media/svg/brand-logos/bebo.svg',
        title: 'IPlayGameSecond',
        link: 'apps/IPlayGameSecond/',
        label: 'IPlayGameSecond'
    },

    // {
    //   image: '/media/svg/brand-logos/bebo.svg',
    //   title: 'Lead Table',
    //   link: 'apps/leadtable/',
    // }
]

const SuperAdminprojects = [

    {
        image: '/media/svg/brand-logos/bebo.svg',
        title: 'Sector',
        link: 'apps/sector/',
    },
    {
        image: '/media/svg/brand-logos/bebo.svg',
        title: 'Role',
        link: 'apps/userrole/role',
    },
    {
        image: '/media/svg/brand-logos/bebo.svg',
        title: 'Custom Form',
        link: 'apps/forms'
    },

]

const IPlayGameTab1 = () => {
    const dispatch = useDispatch()
    const { closeDrawal } = useLayout()

    const location = useLocation();
    const navigate = useNavigate();
    const { currentUser, currentGameContext, saveCurrentGameContext } = useAuth()
    const allGamesList = useAllGames();
    const GameQuestions = useGameQuestions();
    const [modalLeave, setModalLeave] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const currentURL = location.pathname;
    const currentGameId = currentURL.split('/').pop();
    // const getCurrentGame = find(allGamesList, (item) => item.game_id === currentGameId);
    const getCurrentGame = currentGameContext;

    const mediaUrl = get(getCurrentGame, 'media_urls', {}) ?? {}
    const gametype = get(getCurrentGame, 'game_type', {}) ?? {}
    const studyType = get(getCurrentGame, 'study_metadata', {}) ?? {}

    let project = []
    if (currentUser?.is_admin === true) {

        let values = {
            image: '/media/svg/brand-logos/bebo.svg',
            title: 'Role',
            link: 'apps/userrole/role',
            label: 'is_admin'
        }
        project = [values, ...projects]


    } else {
        project = [...projects]


    }

    const getDynamicMessage = () => {
        let messages = [];
        if (GameQuestions?.length === 0) {
            messages.push('Add at least one Question');
        }
        if (getCurrentGame?.display_name === null) {
            messages.push('Give it a name: Let your game stand out with a unique title.');
        }
        if (getCurrentGame?.category_id === null) {
            messages.push('Select books and pages to build your game.');
        }
        if (!mediaUrl?.image) {
            messages.push('Uh oh, looks like you left your picture behind. Pick one and try again.');
        }
        return messages;
    };
    const [selectedTime, setSelectedTime] = useState('20 seconds');
    const [selectedOption, setSelectedOption] = useState({ id: 1, label: 'Standard', subLabel: 'Award correct answers with the normal of ponits ' });


    const [showSection, setShowSection] = useState(false);
    const [selectedCard, setSelectedCard] = useState({
        id: 1,
        label: studyType?.type === "flash_card" ? "Flash-Card" : 'Quiz',
        icon: <BiBorderAll />,
        // isEditable: true,
        // questionData: GameQuestions[0],
    });

    useEffect(() => {
        if (GameQuestions.length > 0) {
            const lastGame = GameQuestions.at(-1)
            const typeInfo = gameTypes.find(k => k.questionType === lastGame.type)
            if (selectedCard && selectedCard.questionData && selectedCard.questionData.game_id) {
                const hasAlreadySelectedIndex = findIndex(GameQuestions, ["game_id", selectedCard.questionData.game_id])
                if (hasAlreadySelectedIndex >= 0) {
                    return;
                }
            }
            setSelectedCard(prevState => ({
                ...prevState,
                isEditable: true,
                label: typeInfo?.label || "Quiz",
                questionData: lastGame
            }));
            setSelectedTime(lastGame?.question?.time)
            setSelectedOption(lastGame?.question?.option)
        } else {
            setSelectedCard({
                id: 1,
                label: studyType?.type === "flash_card" ? "Flash-Card" : 'Quiz',
                icon: <BiBorderAll />,
            });
        }
    }, [GameQuestions]);

    const handleCardSelect = (card) => {
        setSelectedCard(card);
        setShowSection(true);
    };
    const handleFeatureSelect = (type, time) => {
        if (type == "time") {
            setSelectedTime(time)
        }
        if (type == "option") {
            setSelectedOption(time)
        }
    };
    const getData = () => {
        dispatch(setActionTab({ selectedCard, handleCardSelect, showSection }))
        // dispatch(setFeatures({ selectedTime }))
    }

    const getData1 = () => {
        dispatch(setFeatures({ selectedTime, selectedOption }))

    }

    useEffect(() => {
        getData()
    }, [selectedCard, showSection])

    useEffect(() => {
        getData1()
    }, [handleFeatureSelect])

    const onGameDelete = () => {
        setDeleteLoader(true)
        dispatch(deleteGames(getCurrentGame?.game_id))
            .then((response) => {
                setDeleteLoader(false)
                if (response && response.status === 200) {
                    navigate(`/apps/home/`);
                } else {
                    console.error('Failed to delete game:', response);
                }
            })
            .catch((error) => {
                setDeleteLoader(false)
                console.error('Error deleting game:', error);
            });
    };

    const onBackButtonClick = async () => {
        if (GameQuestions?.length > 0 && getCurrentGame?.display_name !== null && getCurrentGame?.category_id !== null && mediaUrl?.image) {
            if (getCurrentGame?.status === 'true' || getCurrentGame?.status === 'false') {
                const data = {
                    ...getCurrentGame,
                    status: true
                }
                await dispatch(CreateGames(data, saveCurrentGameContext))
            }
            navigate(`/apps/home/`)
        } else {
            setModalLeave(true)
        }
    }

    return (
        <div className='m-0 manageScrollBar' style={{ position: 'relative' }}>
            <CloseCircleOutlined style={{ fontSize: 30, position: 'absolute', right: 0, top: 0 }} onClick={() => closeDrawal()} className='d-lg-none' />
            <div className='gameWebLogo logoSpce'>
                {gametype === "1study" ? (
                    <img
                        src={studyGameLogo}
                        alt=""
                        style={{ cursor: 'default' }}

                    />
                ) : (
                    <Link
                        onClick={onBackButtonClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <img
                            src={require('../../../../../../src/app/modules/apps/IPlayGame/utils/assets/images/GameLogo.jpg')}
                            alt=""
                            style={{ cursor: 'inherit' }}
                        />
                    </Link>
                )}

            </div>
            {/* <div className='d-flex mb-10'>
                <Search />
                <div className='flex-shrink-0 ms-2'>
                    <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />

                    </button>
                    <Dropdown1 />
                </div>
            </div> */}
            <div className='m-0'>
                <div className='mb-10'>
                    {currentUser?.is_superadmin == 1
                        ? SuperAdminprojects.map((p) => (
                            <Link
                                key={p.link}
                                to={p.link}
                                className='custom-list d-flex align-items-center px-5 py-4'
                            >
                                <div className='symbol symbol-40px me-5'>
                                    <span className='symbol-label'>
                                        <img
                                            src={toAbsoluteUrl(p.image)}
                                            alt={p.title}
                                            className='h-50 align-self-center'
                                        />
                                    </span>
                                </div>

                                <div className='d-flex flex-column flex-grow-1'>
                                    {/* <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{p.title}</h5> */}
                                </div>
                            </Link>
                        ))
                        :

                        // (projects?.map((key: any) => (
                        //     <Link
                        //         key={key.link}
                        //         to={key.link}
                        //         className='custom-list d-flex align-items-center px-5 py-4'
                        //     >
                        //         <div className='symbol symbol-40px me-5'>
                        //             <span className='symbol-label'>
                        //                 <img
                        //                     src={toAbsoluteUrl(key.image)}
                        //                     alt={key.title}
                        //                     className='h-50 align-self-center'
                        //                 />
                        //             </span>
                        //         </div>

                        //         <div className='d-flex flex-column flex-grow-1'>
                        //             <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{key.title}</h5>
                        //         </div>
                        //     </Link>
                        // )))

                        <>
                            <CustomSelect
                                selectedTime={selectedTime}
                                selectedOption={selectedOption}
                                onFeatureSelect={handleFeatureSelect}
                                selectedCard={selectedCard}
                                onCardSelect={handleCardSelect}
                                showSection={showSection}
                            />
                        </>
                    }
                </div>
            </div>
            <LeavePageModal
                isOpens={modalLeave}
                isLoading={deleteLoader}
                isClose={() => {
                    setModalLeave(false);
                }}
                title={`Hold on! Leaving your game?`}
                message={getDynamicMessage()}
                confirmDelete={() => {
                    onGameDelete();
                }} />
        </div>
    )
}

export { IPlayGameTab1 };
