//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


const selectore = (state) => {
    return state.createGamesCategory
};

const selectoreUnit = (state) => {
    return state.categoryUnit
};

export const useGameCategory = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}

export const useCategoryUnit = () => {
    const selectedData = useSelector(selectoreUnit, shallowEqual)
    return selectedData;
}