import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Button, Col, Row, Tooltip, notification } from 'antd';
import { map, get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { IGameLogos, studyGameLogo } from '../utils/assets/images/index';
import StudyQuizResult from './StudyQuizResult';
import './study-style.css';
import { isImageUrl } from '../utils/methods/QuizeMethodData';
import { LeftOutlined } from '@ant-design/icons';
import { RightOutlined } from '@ant-design/icons';

const StudyQuiz = ({ GameQuestions, nosotros }) => {
  const navigate = useNavigate();
  const [isFlipped, setIsFlipped] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [correctOption, setCorrectOption] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState(Array(GameQuestions.length).fill(null));
  const currentQuestion = GameQuestions[currentQuestionIndex];
  const [startAgain, setStartAgain] = useState(false)
  const [questions, setQuestions] = useState(GameQuestions); // Separate state for questions
  const [isPuzzleLocked, setIsPuzzleLocked] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  // const [isCorrectOrder, setIsCorrectOrder] = useState(false);

  const handleOptionClick = (option, currentQuestion) => {
    const isCorrect = Array?.isArray(currentQuestion.answers.answer)
      ? currentQuestion.answers.answer.includes(option)
      : currentQuestion.answers.answer === option;
    setUserResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses[currentQuestionIndex] = { response: option, isCorrect };
      return updatedResponses;
    });

    if (Array?.isArray(currentQuestion.answers.answer)) {
      // If it's an array, set the first element as the correct option
      setCorrectOption(currentQuestion.answers.answer[0]);
    } else {
      // If it's not an array, set the value directly as the correct option
      setCorrectOption(currentQuestion.answers.answer);
    }
  };

  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiperInstance = swiperRef.current.swiper;
      if (swiperInstance.isEnd) { // Check if it's the last slide
        const allQuestionsAnswered = userResponses.every(response => response?.response !== null && response?.isCorrect !== null) && userResponses.every(response => response !== null);
        if (allQuestionsAnswered) {
          onHandleResult(); // Trigger result if all questions are answered
          return; // Exit function early to prevent moving to the next slide
        } else {
          openCheckNotification('error')

          // Optionally, you can provide feedback to the user that all questions need to be answered
          // console.log("Please answer all questions before proceeding.");
          return; // Exit function early to prevent moving to the next slide
        }
      }
      swiperInstance.slideNext(); // Move to the next slide
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();

    }
  };
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.allowTouchMove = !isDragging;
    }
  }, [isDragging]);
  const onHandleResult = () => {
    setShowResult(true);
  };

  const onBackClick = () => {
    if (window !== window.top) {
      window.parent.postMessage({ action: 'close_one_game' }, '*');
    }
  }
  const handleDragStart = (event) => {
    setIsDragging(true);
    document.body.style.overflow = 'hidden'
    if (window !== window.top) {
      window.parent.postMessage({ action: 'stop_iiframe_scroller' }, '*')
    }
  }
  useEffect(() => {
    const currentQuestion = GameQuestions[currentQuestionIndex];
    if (currentQuestion?.type === 'puzzle') {
      setUserResponses(prevResponses => {
        const updatedResponses = [...prevResponses];
        const currentQuestionOptions = currentQuestion?.answers?.description.map(option => option);
        // Check if isCorrect is already true or false
        if (!prevResponses[currentQuestionIndex] || prevResponses[currentQuestionIndex]?.isCorrect === null) {
          updatedResponses[currentQuestionIndex] = { response: currentQuestionOptions, isCorrect: null };
        }
        return updatedResponses;
      });
    }
  }, [currentQuestionIndex, GameQuestions, startAgain]);

  const handleDragEnd = (result) => {
    setIsDragging(false);
    document.body.style.overflow = 'auto';
    if (window !== window.top) {
      window.parent.postMessage({ action: 'release_iiframe_scroller' }, '*');
    }
    if (!result.destination) return;

    // const currentQuestion = GameQuestions[currentQuestionIndex];
    const newOrder = Array?.from(userResponses[currentQuestionIndex].response);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);

    // Update userResponses with the new order
    setUserResponses(prevResponses => {
      const updatedResponses = [...prevResponses];
      updatedResponses[currentQuestionIndex] = { response: newOrder, isCorrect: null };
      return updatedResponses;
    });
  };


  const handlePuzzleSubmit = () => {
    // Update isCorrect property for the current question
    const currentQuestion = GameQuestions[currentQuestionIndex];
    updateIsCorrect(currentQuestion);

  };

  const updateIsCorrect = (currentQuestion) => {
    const userResponse = userResponses[currentQuestionIndex]?.response;
    const correctAnswer = currentQuestion.answers.answer;
    const isCorrect = userResponse?.map((response, index) => response === correctAnswer[index]);

    setUserResponses(prevResponses => {
      const updatedResponses = [...prevResponses];
      updatedResponses[currentQuestionIndex] = { response: userResponse, isCorrect };
      return updatedResponses;
    });
  };
  const openCheckNotification = (type) => {
    api[type]({
      message: 'Hold On!',
      description:
        'Please answer all questions before proceeding.',
      duration: 2,
    });
  };
  return (
    <div>
      {!showResult ? (
        <div className='studyTestDesignTpc'>
          <div className='arrowLogoBtn'>
            <div className='createBckBtn' onClick={() => {
              if (nosotros) {
                onBackClick();
              } else {
                navigate(`/apps/home/`);
              }
            }}>
              <span className='arrowBckBtn'>
                <ArrowBackIosIcon />
              </span>
              Study TEST
            </div>
            <div className='oneGmPopup disabledCursor'>
              <Button className='gmPopupBtn'>
                <img src={studyGameLogo} alt='' />
              </Button>
            </div>
          </div>

          <div className='writeTpcEbk writeTpcEbkSection vamosSection quizTopVms'>
            <p>!Vamos a practicar! </p>
          </div>

          <Swiper
            effect={'flip'}
            grabCursor={true}
            pagination={false}
            navigation={false}
            className='mySwiper'
            // speed={2000}
            ref={swiperRef}
            simulateTouch={false}
            onSlideChange={(swiper) => {
              setCurrentQuestionIndex(swiper.activeIndex);
            }}
          >
            {map(questions, (currentQuestion, index) => {
              const questionInfo = currentQuestion?.question || {}
              return (
                <SwiperSlide key={index}>
                  {contextHolder}
                  <div className={`flshCardQuizSection flshCardQuizSectionCstm ${isFlipped ? 'flipped' : ''}`}>
                    <div className='flashCardInner'>
                      <div className='stdyCardWrap quizFlshCrdFrnt'>
                        <div className='stdyCardHad stdyCardTopSec'>
                          <div className='studySlideTxt'>
                            <span>{currentQuestion.order_num}.</span>
                            <p>{currentQuestion.question.title}</p>
                          </div>
                          <label>PREGUNTA</label>
                        </div>
                        <div className='studyTxtForMoble'>
                          <p>{currentQuestion.question.title}</p>
                        </div>

                      <div className='allSliderQuestion'>
                        {questionInfo?.imageUrl && (
                        <div className='flashcard-Image-container'>
                          <img
                            className='AQimage studyAQImg'
                            src={get(questionInfo, 'imageUrl', '')}
                            alt=""
                            />
                        </div>
                        )}


                        {currentQuestion.type !== 'puzzle' && (
                          <div className='quesSelectTxtAll'>
                            <div className='quesSelectTrueFalseAll'>
                              <Row gutter={20}>
                                {currentQuestion.type === 'true_or_false' && currentQuestionIndex === index && (
                                  <>
                                    <Col className='gutter-row' lg={12} xs={24} sm={12} md={12}>
                                      <div className={`quizFlshCrdBtn ${userResponses[currentQuestionIndex]?.response === 'True' && currentQuestionIndex === index ? (userResponses[currentQuestionIndex]?.isCorrect === true ? 'greenBackground' : 'redBackground') : ((currentQuestion?.answers?.answer[0] === 'True' || currentQuestion?.answers?.answer === 'True') && userResponses[currentQuestionIndex] !== null) ? 'greenBackground' : ''}`}>

                                        <p>{userResponses[currentQuestionIndex]?.response === 'True' ? (userResponses[currentQuestionIndex].isCorrect === true ?
                                          <CheckCircleIcon className='trueSuffleIcon' /> :
                                          <CancelIcon className='falseSuffleIcon' />) : ((currentQuestion?.answers?.answer[0] === 'True' || currentQuestion?.answers?.answer === 'True') && userResponses[currentQuestionIndex] !== null) ? <CheckCircleIcon className='trueSuffleIcon' /> : ''
                                        }</p>
                                        <Button onClick={() => handleOptionClick('True', currentQuestion)} disabled={userResponses[currentQuestionIndex]}>
                                          True
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col className='gutter-row' lg={12} xs={24} sm={12} md={12}>
                                      <div className={`quizFlshCrdBtn ${userResponses[currentQuestionIndex]?.response === 'False' && currentQuestionIndex === index ? (userResponses[currentQuestionIndex]?.isCorrect === true ? 'greenBackground' : 'redBackground') : ((currentQuestion?.answers?.answer[0] === 'False' || currentQuestion?.answers?.answer === 'False') && userResponses[currentQuestionIndex] !== null) ? 'greenBackground' : ''}`}>

                                        {/* Checking if the user response matches the option */}
                                        <p>{userResponses[currentQuestionIndex]?.response === 'False' ? (userResponses[currentQuestionIndex]?.isCorrect === true ?
                                          <CheckCircleIcon className='trueSuffleIcon' /> :
                                          <CancelIcon className='falseSuffleIcon' />) : ((currentQuestion?.answers?.answer[0] === 'False' || currentQuestion?.answers?.answer === 'False') && userResponses[currentQuestionIndex] !== null) ? <CheckCircleIcon className='trueSuffleIcon' /> : ''
                                        }</p>
                                        <Button onClick={() => handleOptionClick('False', currentQuestion)} disabled={userResponses[currentQuestionIndex]}>
                                          False
                                        </Button>
                                      </div>
                                    </Col>
                                  </>

                                )}
                                {currentQuestion.type === 'quiz' && currentQuestionIndex === index && (
                                  currentQuestion?.answers?.options?.map((option, optionIndex) => (
                                    <Col className="gutter-row" lg={12} xs={24} sm={12} md={12} key={optionIndex}>
                                      
                                      <div className={`quizFlshCrdBtn ${userResponses[currentQuestionIndex]?.response === option && currentQuestionIndex === index ? (userResponses[currentQuestionIndex]?.isCorrect === true ? 'greenBackground' : 'redBackground') : (((currentQuestion?.answers?.answer[0] === option || currentQuestion?.answers?.answer === option) && userResponses[currentQuestionIndex] !== null) ? 'greenBackground' : '')}`}>

                                        
                                        <p>{userResponses[currentQuestionIndex]?.response === option ? (userResponses[currentQuestionIndex]?.isCorrect === true ?
                                          <CheckCircleIcon className='trueSuffleIcon' /> :
                                          <CancelIcon className='falseSuffleIcon' />) : (((currentQuestion?.answers?.answer[0] === option || currentQuestion?.answers?.answer === option) && userResponses[currentQuestionIndex] !== null) ? <CheckCircleIcon className='trueSuffleIcon' /> : '')
                                        }</p>
                                        {isImageUrl(option) ? (

                                        <Button onClick={() => handleOptionClick(option, currentQuestion)} disabled={userResponses[currentQuestionIndex]}>
                                            <img src={option} alt={`Option ${String.fromCharCode(65 + index)}`} className='imgContainerAQ studyAnswerImg' />
                                        </Button>
                                        ): (
                                        <button onClick={() => handleOptionClick(option, currentQuestion)} disabled={userResponses[currentQuestionIndex]}>
                                          {option}
                                        </button>
                                        )}
                                      </div>
                                    </Col>
                                  ))
                                )}
                              </Row>
                            </div>
                          </div>
                        )}
                        {currentQuestion.type === 'puzzle' && currentQuestionIndex === index && (
                          <>
                            <div className="quesSelectdragAndDrop ms-8">
                              <div className='dragDropTxt dragDropTxtCstm'>
                                {/* {currentQuestion?.answers?.description?.map((item, index) => { */}
                                {currentQuestion?.answers?.options?.map((item, index) => {
                                  return (
                                    <div className='matchingDesc addingHeightMobile'>
                                      <p>{`${item}`}</p>
                                    </div>
                                  )
                                })}
                              </div>
                              <DragDropContext
                                onDragStart={(userResponses[currentQuestionIndex]?.isCorrect === false || userResponses[currentQuestionIndex]?.isCorrect === true) ? null : handleDragStart}
                                onDragEnd={(userResponses[currentQuestionIndex]?.isCorrect === false || userResponses[currentQuestionIndex]?.isCorrect === true) ? null : handleDragEnd}
                              >
                                <Droppable droppableId="droppable">
                                  {(provided) => (
                                    <div
                                      className="pzlWrapMainBg puzzleDrpDwn addDrpDwnCstm"
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {/* {Array?.isArray(userResponses[currentQuestionIndex]?.response) && userResponses[currentQuestionIndex]?.response?.map((item, index) => ( */}
                                      {Array?.isArray(userResponses[currentQuestionIndex]?.response) && userResponses[currentQuestionIndex]?.response?.map((item, index) => (
                                        userResponses[currentQuestionIndex]?.isCorrect !== null ? (
                                          <div key={index} className="textareadragPart">
                                            <div className="responseDragDrop">
                                              {item && item.length > 8 ? (
                                                <Tooltip title={item}>
                                                  <p>{item.slice(0, 8) + "..."}</p>
                                                </Tooltip>
                                              ) : (
                                                <p>{item}</p>
                                              )}
                                              <div className='studyPuzlDrp'>
                                                {userResponses[currentQuestionIndex]?.isCorrect == null ? "" :
                                                  (userResponses[currentQuestionIndex]?.isCorrect[index] === true ?
                                                    <CheckCircleIcon className='trueSuffleIcon' /> :
                                                    <CancelIcon className='falseSuffleIcon' />
                                                  )
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <Draggable
                                            key={index}
                                            draggableId={`option-${index}`}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div className="textareadragPart">
                                                  <div className={`responseDragDrop ${snapshot.isDragging ? 'dragging' : ''}`}>
                                                    {item && item?.length > 8 ? (
                                                      <Tooltip title={item}>
                                                        <p>{item.slice(0, 8) + '...'}</p>
                                                      </Tooltip>
                                                    ) : (
                                                      <p>{item}</p>
                                                    )}
                                                    {userResponses[currentQuestionIndex]?.isCorrect == null ? "" :
                                                      (userResponses[currentQuestionIndex]?.isCorrect === true ?
                                                        <CheckCircleIcon className='trueSuffleIcon' /> :
                                                        <CancelIcon className='falseSuffleIcon' />
                                                      )
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                            

                            {userResponses[currentQuestionIndex]?.isCorrect && (
                              <div className="correctOrder">
                                <span>Correct order:</span>
                                {currentQuestion?.answers?.answer.map((item, index) => (
                                  <div key={index} className="correctOrderItem">
                                    {item && item.length > 8 ? (
                                      <Tooltip title={item}>
                                        <p>{` ${item.slice(0, 8) + '...'}`}</p>
                                      </Tooltip>
                                    ) : (
                                      <p>{`${item}`}</p>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                            {/* Rest of your JSX */}
                          </>
                        )}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}

          </Swiper>

          <div className='slideCardTrueFalse'>
            <div className='slideCardNav'>
              {currentQuestion.type === 'puzzle' && (
                <div className={`stdyPzleSubmitBtn ${userResponses[currentQuestionIndex]?.isCorrect && 'btnDisabled'}`}>
                  <Button
                    onClick={() => handlePuzzleSubmit()}
                    disabled={userResponses[currentQuestionIndex]?.isCorrect}
                  >
                    Submit
                  </Button>
                </div>
              )}
              {/* <button className='slideNavbtn slideNavTypAnsPre' onClick={() => onHandleResult()}>
                Result
              </button> */}
              <button className={`slideNavbtn slideNavTypAnsPre ${currentQuestionIndex === 0 ? 'btnDisabled' : ''}`}
                disabled={currentQuestionIndex === 0}
                onClick={() => {
                  handlePrev();
                }}>
                {/* <img src={require('../utils/assets/images/arow.png')} alt='study1' /> */}
                <LeftOutlined className='flashCardArrowIcon' />
              </button>
              <button className='slideNavbtn slideNavTypAnsNxt' onClick={() => handleNext()}>
                {/* <img src={require('../utils/assets/images/arw2.png')} alt='study' /> */}
                <RightOutlined className='flashCardArrowIcon'/>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <StudyQuizResult
          GameQuestions={GameQuestions}
          userResponses={userResponses}
          onBackBtnClick={() => {
            window.speechSynthesis.cancel();
            if (nosotros) {
              onBackClick();
            }
            else {
              navigate(`/apps/home/`);
            }
          }}
          onStartAgain={() => {
            window.speechSynthesis.cancel();
            setCurrentQuestionIndex(0);
            setUserResponses(Array(GameQuestions.length).fill(null));
            setStartAgain(!startAgain)
            setShowResult(false);
          }} />
      )}
    </div>
  );
};

export default StudyQuiz;
