/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { get, map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { usePageData } from '../../../layout/core/PageData';
import { useGameQuestions } from '../../../../app/modules/apps/IPlayGame/store/GameQuestions/selectors';
import { useAuth } from '../../../../app/modules/auth';
import { DeleteModal } from '../../../../app/modules/apps/IPlayGame/components/DeleteModal';
import {
  GetSocketUrl,
  MessageModel
} from '../../../helpers';
import { CreateGameQuestion, deleteGameQuestion } from '../../../../app/modules/apps/IPlayGame/store/GameQuestions/thunks';
import { useDispatch } from 'react-redux';
import { Droppable, DragDropContext, Draggable } from "react-beautiful-dnd";
import { PictureOutlined } from '@ant-design/icons';
import { setActionTab } from '../../../../app/modules/apps/IPlayGame/store/TabChange/thunks';
import { BiBorderAll } from 'react-icons/bi';
import { getAppBackendUrl } from '@app/modules/apps/IPlayGame/utils/methods'
const API_URL = getAppBackendUrl()
const SOCKET_URL = GetSocketUrl();

// type Props = {
//   isDrawer?: boolean
// }
// interface FormData {
//   user_id: string
//   avatar: string
//   user_name: string
//   phone: string
//   whatsapp: string
//   languages: string
//   email: string
//   address: {
//     address: string
//     city: string
//     country: string
//     state: string
//     pin: string
//   }
//   leadinquiry: string
//   generatedby: string
//   generatedatd: string
//   status: string
//   metadata: { avatar: string }
// }

// const QuestionTypeFormat = {
//   type: {
//     'quiz',
//     'true_or_false',
//     'type_answer',
//     'puzzle',
//     'poll',
//     'drop_pin',
//     'word_cloud',
//     'open_ended',
//     'brainstorm'
//   }
// }

var bufferMessages = []
const DrawerMessenger = ({ isDrawer = false }) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState(false)
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({
    user_id: '',
    avatar: '',
    user_name: '',
    phone: '',
    whatsapp: '',
    languages: '',
    email: '',
    address: {
      address: '',
      city: '',
      country: '',
      state: '',
      pin: '',
    },
    leadinquiry: '',
    generatedby: '',
    generatedatd: '',
    status: 'inactive',
    metadata: {
      avatar: '',
    }
  })
  const dispatch = useDispatch();
  const [online, setOnline] = useState([])
  const [messages, setMessages] = useState(bufferMessages)
  // const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const { currentUser, superauth, auth } = useAuth()
  const token = superauth ? superauth?.api_token : auth?.api_token
  const location = useLocation()

  const GameQuestions = useGameQuestions()
  const { setChatUser, chatUser } = usePageData()
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;

  }

  const [checkDelete, setCheckDelete] = useState(false);
  const [getName, setGetName] = useState();
  const [deleteData, setDeleteData] = useState();
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems([...GameQuestions]);
  }, [GameQuestions])
  useEffect(() => {
    if (chatUser && chatUser !== '') {
      fetch(`${API_URL}/user/userById/${chatUser}`, {
        headers: {
          'Content-Type': 'application/json',
          token: token
        },
      })
        .then((response) => response.json())
        .then((data) => { setFormData(data) })
        .catch((error) => console.error(error))

      fetch(`${API_URL}/lead_msg/msgById/${chatUser}`, {
        headers: {
          'Content-Type': 'application/json',
          token: token
        },
      })
        .then((response) => response.json())
        .then((data) => { bufferMessages = data?.data; setMessages(data?.data) })
        .catch((error) => console.error(error))


    }
  }, [chatUser])

  useEffect(() => {

    toggleChatUpdateFlat(!chatUpdateFlag)
  }, [])



  const getLeadUser = () => {
    fetch(`${API_URL}/Lead/leadById/${location?.state}`, {
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((response) => response.json())
      .then((data) => setFormData(data?.data))
      .catch((error) => console.error(error))
  }

  const sendMessage = async () => {
    const newMessage = {
      // lead_id : location.state,
      reciever_id: formData?.user_id,
      sender_id: currentUser?.user_id.toString(),
      messagetype: 'out',
      text: message,
      time: ''
    }
    await axios.post(`/lead_msg/AddUpdateMsg`, newMessage, {
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    })
    bufferMessages.push(newMessage)
    setMessages(bufferMessages)
    toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')
    // setTimeout(() => {
    //   bufferMessages.push(messageFromClient)
    //   setMessages(() => bufferMessages)
    //   toggleChatUpdateFlat((flag) => !flag)
    // }, 1000)

    // there must be api call
  }

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const onDelete = (item) => {
    setCheckDelete(true);
    setGetName(getType(item?.type))
    setDeleteData(item?.id)
    // dispatch(deleteGameQuestion(item?.game_id))
    // dispatch(deleteGameQuestion(item?.id))
  }

  const onDuplicate = (item) => {
    if (item) {
      const data = {
        org_id: item?.org_id,
        created_by: item?.created_by,
        type: item?.type,
        question: item?.question,
        answers: item?.answers,
        game_id: item?.game_id
      }
      dispatch(CreateGameQuestion(data))
    }
  }

  const getType = (type) => {
    if (type === 'quiz') {
      return 'Quiz';
    } else if (type === 'true_or_false') {
      return 'True or False';
    } else if (type === 'type_answer') {
      return 'Type Answer';
    } else if (type === 'puzzle') {
      return 'Puzzle';
    } else if (type === 'poll') {
      return 'Poll';
    } else if (type === 'drop_pin') {
      return 'Drop Pin';
    } else if (type === 'word_cloud') {
      return 'Word Cloud';
    } else if (type === 'open_ended') {
      return 'Open Ended';
    } else if (type === 'brainstorm') {
      return 'Brainstorm';
    } else {
      return '';
    }
  }

  const handleDeleteConfirmation = async () => {
    setLoaderBtn(true);
    await dispatch(deleteGameQuestion(deleteData));
    setLoaderBtn(false);
    setCheckDelete(false);
  }
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = [...items];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    setItems(newItems);
  };

  const getSelectedCard = (item, type) => {
    const cardTypes = {
      quiz: {
        id: 1,
        label: 'Quiz',
        icon: <BiBorderAll />,
        isEditable: true,
        questionData: item
      },
      true_or_false: {
        id: 2,
        label: 'True or false',
        icon: <BiBorderAll />,
        isEditable: true,
        questionData: item
      },
      type_answer: {
        id: 3,
        label: 'Type answer',
        icon: <BiBorderAll />,
        isEditable: true,
        questionData: item
      },
      puzzle: {
        id: 4,
        label: 'Puzzle',
        icon: <BiBorderAll />,
        isEditable: true,
        questionData: item
      },
      poll: {
        id: 1,
        label: 'Poll',
        icon: <BiBorderAll />,
        isEditable: true,
        questionData: item
      },
      drop_pin: {
        id: 2,
        label: 'Drop pin',
        icon: <BiBorderAll />,
        isEditable: true,
        questionData: item
      },
      word_cloud: {
        id: 3,
        label: 'Word cloud',
        icon: <BiBorderAll />,
        starRate: <BiBorderAll />,
        isEditable: true,
        questionData: item
      },
      open_ended: {
        id: 4,
        label: 'Open-ended',
        icon: <BiBorderAll />,
        starRate: <BiBorderAll />,
        isEditable: true,
        questionData: item
      },
      brainstorm: {
        id: 5,
        label: 'Brainstorm',
        icon: <BiBorderAll />,
        starRate: <BiBorderAll />,
        isEditable: true,
        questionData: item
      }
    };

    return cardTypes[type] || '';
  };

  const handleCardSelect = (item) => {
    const type = get(item, 'type', '') ?? '';
    const selectedCard = getSelectedCard(item, type)
    dispatch(setActionTab({ selectedCard, handleCardSelect: null, showSection: true }))
  }

  return (
    <>
      <div
        id='kt_drawer_chat'
        className='bg-body sideMenuDesign drawerWidth'
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_chat_toggle'
        data-kt-drawer-close='#kt_drawer_chat_close'
        onClick={() => setChatUser('')}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', height: 100, width: '100%', padding: 8 }}>
          {/* {map(GameQuestions, (item) => {
          const Questions = get(item, 'question', '');
          return ( */}
          <>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div style={{ width: '100%' }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}>
                    {items?.length > 0 && map(items, (item, index) => {
                      const Questions = get(item, 'question', '');
                      return (
                        <Draggable
                          key={item?.id}
                          draggableId={item?.id}
                          index={index}
                        >
                          {(provided) => (
                            <div className='listLayoutCntnt' ref={provided.innerRef}  {...provided.draggableProps}  {...provided.dragHandleProps}>

                              <div className='listLayoutWrap'>

                                <div className='previewWidth'>
                                  <div className='previewCard'>
                                    <div className='previewIcon'>
                                      <i onClick={() => {
                                        onDuplicate(item)
                                      }} className="fa-solid fa-copy"></i>
                                      <i onClick={() => {
                                        onDelete(item)

                                      }} className="fa-solid fa-trash-can"></i>
                                    </div>
                                    <label className='previewName'>{getType(item?.type)}</label>
                                    <div className='previewUpr' onClick={() => {
                                      handleCardSelect(item);
                                    }}>
                                      {item?.type === 'poll' && (
                                        <div className='previewList'>
                                          <p className='previewPara'>{get(Questions, 'title', '')}</p>
                                          <div className='previewUpload'> </div>

                                          <div className='previewAns'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                      )}

                                      {item?.type === 'quiz' && (
                                        <div className='previewList'>
                                          <p className='previewPara'>{get(Questions, 'title', '')}</p>
                                          <div className='previewUpload'> </div>
                                          <div className='previewAns'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                      )}
                                      {item?.type === 'true_or_false' && (
                                        <div className='previewList'>
                                          <p className='previewPara'>{get(Questions, 'title', '')}</p>
                                          <div className='previewUpload'> </div>
                                          <div className='previewAns'>
                                            <div>{item?.answers?.answer === 0 && <div className='dotVal' />}
                                            </div>
                                            <div>{item?.answers?.answer === 1 && <div className='dotVal' />}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {item?.type === 'word_cloud' && (
                                        <div className='previewList'>
                                          <p className='previewPara'>{get(Questions, 'title', '')} </p>
                                          <div className='previewUpload'> </div>
                                          <div className='imgPreview'>
                                            {!item?.question?.imageUrl ? <PictureOutlined style={{ fontSize: 32 }} /> : <img src={'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fimg21.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'} height={32} alt="" />}
                                          </div>
                                          {/* <div className='previewAns'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div> */}
                                        </div>
                                      )}
                                      {item?.type === 'open_ended' && (
                                        <div className='previewList'>
                                          <p className='previewPara'>{get(Questions, 'title', '')} </p>
                                          <div className='previewUpload'> </div>
                                          <div className='imgPreview'>
                                            {!item?.question?.imageUrl ? <PictureOutlined style={{ fontSize: 32 }} /> : <img src={'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fimg21.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'} height={32} alt="" />}
                                          </div>
                                          {/* <div className='previewAns'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div> */}
                                        </div>
                                      )}
                                      {item?.type === 'brainstorm' && (
                                        <div className='previewList'>
                                          <p className='previewPara'>{get(Questions, 'title', '')} </p>
                                          <div className='previewUpload'> </div>
                                          <div className='imgPreview'>
                                            {!item?.question?.imageUrl ? <PictureOutlined style={{ fontSize: 32 }} /> : <img src={'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fimg21.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'} height={32} alt="" />}
                                          </div>
                                          {/* <div className='previewAns'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div> */}
                                        </div>
                                      )}
                                      {item?.type === 'drop_pin' && (
                                        <div className='previewList'>
                                          <p className='previewPara'>{get(Questions, 'title', '')} </p>
                                          <div className='previewUpload'> </div>
                                          <div className='previewAns'>
                                            <div className='imgPreview'>
                                              {!item?.question?.imageUrl ? <PictureOutlined style={{ fontSize: 32 }} /> : <img src={'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/1game%2Fimg21.jpg?alt=media&token=aab2bab4-4996-4df5-be39-85fb5bbb27ef&_gl=1*15oz94y*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5NzAwMDgyNS4xLjEuMTY5NzAwMDk1Ny45LjAuMA'} height={32} alt="" />}
                                            </div>

                                            {/* <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div> */}



                                          </div>
                                        </div>
                                      )}
                                      {item?.type === 'puzzle' && (
                                        <div className='previewList'>
                                          <p className='previewPara'>{get(Questions, 'title', '')} </p>
                                          <div className='previewUpload'> </div>
                                          <div className='previewAns'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {/* <div className='previewWidth'>
                <div className='previewCard'>
                  <div className='previewIcon'>
                    <i className="fa-solid fa-copy"></i>
                    <i className="fa-solid fa-trash-can"></i>
                  </div>
                  <label className='previewName'>2 Puzzle</label>
                  <div className='previewUpr'>
                    <div className='previewList'>
                      <p className='previewPara'> </p>
                      <div className='previewUpload'> </div>
                      <div className='previewAns'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                              </div>
                            </div>

                          )}
                        </Draggable>
                      )
                    }
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
          {/* )
        })} */}
        </div>
      </div>
      <DeleteModal isOpens={checkDelete} isLoading={loaderBtn} isClose={setCheckDelete} title={`${getName} question`} message={'Are you sure you want to delete this question? This action can’t be undone.'} confirmDelete={() => {
        handleDeleteConfirmation();
      }} />
    </>
  )
}

export { DrawerMessenger };

