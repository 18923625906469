import { useTimer } from 'react-timer-hook';


const CountdownStopwatch = ({ expiryTimestamp, onCounterExpire = () => {} }) => {
    const {
        seconds,
    } = useTimer({ expiryTimestamp, onExpire: () => onCounterExpire() });

    return (<>
        <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '200px',fontWeight:600 }} className="tiMEDEISGN">
                <span>{seconds}</span>
            </div>       
        </div>
    </>
    );
};

export default CountdownStopwatch;
