import BlueMonsterdata2 from '../../utils/assets/lottie animations/BlueMonsterdata.json';
import BlueMonsterdata3 from '../../utils/assets/lottie animations/GradientMonster2data.json';
import BlueMonsterdata4 from '../../utils/assets/lottie animations/GradientMonsterdata.json';
import BlueMonsterdata5 from '../../utils/assets/lottie animations/GreenMonsterdata.json';
import BlueMonsterdata6 from '../../utils/assets/lottie animations/LimeMonsterdata.json';
// import BlueMonsterdata7 from '../../utils/assets/lottie animations/OrangeMonster2data.json';
import BlueMonsterdata8 from '../../utils/assets/lottie animations/OrangeMonsterdata.json';
import BlueMonsterdata9 from '../../utils/assets/lottie animations/PinkMonsterdata.json';
import BlueMonsterdata10 from '../../utils/assets/lottie animations/PurpleMonsterdata.json';
// import BlueMonsterdata11 from '../../utils/assets/lottie animations/RedMonster3data.json';
import BlueMonsterdata12 from '../../utils/assets/lottie animations/SkyBlueMonsterdata.json';
import BlueMonsterdata13 from '../../utils/assets/lottie animations/Teal Monster2data.json';
// import BlueMonsterdata14 from '../../utils/assets/lottie animations/TealMonster3data.json';
// import BlueMonsterdata from '../../utils/assets/lottie animations/VioletMonsterdata.json';
import BlueMonsterdata16 from '../../utils/assets/lottie animations/YellowMonsterdata.json';
import BlueMonsterdata17 from '../../utils/assets/lottie animations/redmonster2data.json';
// import BlueMonsterdata18 from '../../utils/assets/lottie animations/redmonsterdata.json';
export const avtarData=[
//     {
//     imgs: BlueMonsterdata,
//     name:1
// },
{
    imgs: BlueMonsterdata2,
    name:2
},
{
    imgs: BlueMonsterdata3,
    name:3
},
{
    imgs: BlueMonsterdata4,
    name:4
},
{
    imgs: BlueMonsterdata5,
    name:5
},
{
    imgs: BlueMonsterdata6,
    name:6
},
// {
//     imgs: BlueMonsterdata7,
//     name:7
// },
{
    imgs: BlueMonsterdata8,
    name:8
},
{
    imgs: BlueMonsterdata9,
    name:9
},
{
    imgs: BlueMonsterdata10,
    name:10
},
// {
//     imgs: BlueMonsterdata11,
//     name:11
// },
{
    imgs: BlueMonsterdata12,
    name:12
},
{
    imgs: BlueMonsterdata13,
    name:13
},
// {
//     imgs: BlueMonsterdata14,
//     name:14
// },
// {
//     imgs: BlueMonsterdata15,
//     name:15
// },
{
    imgs: BlueMonsterdata16,
    name:16
},
{
    imgs: BlueMonsterdata17,
    name:17
},
// {
//     imgs: BlueMonsterdata18,
//     name:18
// },
] 