//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create Question ----------

export const listTutorialVideo = (returnedPayload) => ({ type: ActionTypes.tutorial_video.listtutorialvideo, payload: returnedPayload });
export const listImageCategory = (returnedPayload) => ({ type: ActionTypes.tutorial_video.listImageCategory, payload: returnedPayload });
export const listGameGallery = (returnedPayload) => ({ type: ActionTypes.tutorial_video.listGameGallery, payload: returnedPayload });