import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Dropzone from "react-dropzone";

import { Progress } from 'antd';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import { v4 } from 'uuid';
import { storage } from '../../firebaseConfig';
import { useGameGalleryList, useGameImageCategory } from '../../store/TutorialVideo/selectors';
import styled from 'styled-components';
import { filter, map } from 'lodash';

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuBtn = styled.button`
  background-color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;

const ImageModal = ({ isOpens, isClose, onSubmit = () => { }, currentGameId }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedPredefinedImage, setSelectedPredefinedImage] = useState(null);
    const [percent, setPercent] = useState(0);
    const [showMsg, setShowMsg] = useState(false);
    const [filtertedData, setFilteredData] = useState([]);
    const gameGallery = useGameGalleryList();
    const gameImageCategory = useGameImageCategory()
    const [dataTab, setDataTab] = useState(gameImageCategory?.meta_data ? gameImageCategory?.meta_data[0]?.id : "");

    const filterTutorialGallery = filter(gameGallery, (item) => item.category === dataTab);
    const getCategory = gameImageCategory?.meta_data?.find((val) => val.id === dataTab)

    useEffect(() => {
        setDataTab(gameImageCategory?.meta_data ? gameImageCategory?.meta_data[0]?.id : "")
    }, [gameImageCategory])

    useEffect(() => {
        if (getCategory?.value === "All") {
            setFilteredData(gameGallery);
            return;
        }
        setFilteredData(filterTutorialGallery);
    }, [dataTab, gameGallery])

    const handleCloseModal = () => {
        isClose(false);
        setSelectedImage()
        setSelectedPredefinedImage(null)
    };

    const handleSubmitModal = () => {
        if (selectedImage) {
            uploadToStorage(selectedImage);
        } else if (selectedPredefinedImage) {
            uploadToStorage(selectedPredefinedImage)
        } else {
            console.log('No image selected.');
        }
    };

    const handleShowErrorMsg = () => {
        setShowMsg(true);
        setTimeout(() => {
            setShowMsg(false);
        }, 5000)
    }

    const handleImageUpload = (acceptedFiles) => {
        // const image = event.target.files[0];
        // if (acceptedFiles[0]?.type === "" || acceptedFiles[0]?.type.startsWith('image/')) {
        if (acceptedFiles[0]?.type.startsWith('image/') && acceptedFiles[0]?.type !== "") {
            setSelectedImage(acceptedFiles[0]);
            setSelectedPredefinedImage(null);
            const reader = new FileReader();
            reader.onload = () => {
            };
            reader.readAsDataURL(acceptedFiles[0]);
        }
        else {
            handleShowErrorMsg();
        }

    };

    const predefinedimage = (image) => {
        setSelectedPredefinedImage(image);
        setSelectedImage(null)

    };

    const uploadToStorage = async (fileOrUrl) => {
        if (fileOrUrl) {
            const location = currentGameId ? `/1Game/${currentGameId}` : `/1Game`;
            const id = `${moment().format('YYYY-MM-DD')}-${v4()}`;

            if (typeof fileOrUrl === 'string') {
                // Handle predefined image URL
                const fileName = getFileNameFromUrl(fileOrUrl);
                const storageRef = ref(storage, `${location}/${id}/${fileName}`);

                try {
                    const blob = await fetch(fileOrUrl).then((response) => response.blob());
                    const uploadTask = uploadBytesResumable(storageRef, blob);

                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            // update progress
                            setPercent(percent);
                        },
                        (err) => console.log(err),
                        async () => {
                            // download url
                            const url = await getDownloadURL(uploadTask.snapshot.ref);
                            if (url) {
                                onSubmit(url);
                                isClose(false);
                                setSelectedImage();
                                setPercent(0);
                                setSelectedPredefinedImage(null)
                            }
                        }
                    );
                } catch (error) {
                    console.log('Upload completion error:', error.message);
                }
            } else if (fileOrUrl instanceof File) {
                // Handle local file
                const storageRef = ref(storage, `${location}/${id}/${fileOrUrl.name}`);
                try {
                    const uploadTask = uploadBytesResumable(storageRef, fileOrUrl);
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            // update progress
                            setPercent(percent);
                        },
                        (err) => console.log(err),
                        async () => {
                            // download url
                            const url = await getDownloadURL(uploadTask.snapshot.ref);
                            if (url) {
                                console.log('system url', url);
                                onSubmit(url);
                                isClose(false);
                                setSelectedImage();
                                setPercent(0);
                            }
                        }
                    );
                } catch (error) {
                    console.log('Upload completion error:', error.message);
                }
            }
        } else {
            console.log('Upload error: No file or URL provided');
        }
    };

    const predefinedImages = [
        "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gallery-image1.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222",
        "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gallery-image2.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222",
        "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gallery-image3.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222",
        "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gallery-image4.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222",
        "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gallery-image5.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222",
        "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gallery-image6.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222",
        "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gallery-image7.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222",
        "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gallery-image8.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222",
    ];

    const getFileNameFromUrl = (url) => {
        const urlObject = new URL(url);
        const searchParams = new URLSearchParams(urlObject.search);
        const fileNameParam = searchParams.get('alt');

        if (fileNameParam) {
            const fileNameMatch = fileNameParam.match(/filename=([^&]+)/);
            if (fileNameMatch) {
                return fileNameMatch[1];
            }
        }

        return null;
    };

    function navigationToMenu(id) {
        setDataTab(id);
    }

    return (
        <Modal show={isOpens} onHide={handleCloseModal} className='galleryPicUpr'>
            <div className='galleryMdlCls' onClick={handleCloseModal}>
                <img src={require('../../utils/assets/images/close.png')} alt="" title="" />
            </div>
            <Modal.Body>
                <div className='galleryPic '>
                    <label>Gallery images</label>

                    <MenuWrapper top={"10px"} style={{ width: "100%" }}>
                            {map(gameImageCategory?.meta_data, (x, index) => {
                                return (
                                    <MenuBtn style={{ borderBottom: dataTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: dataTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => navigationToMenu(x.id)}>
                                        {x?.value}
                                    </MenuBtn>
                                )
                            })}
                        </MenuWrapper>
                    <ul className='allGalleryImg'>
                        {/* {predefinedImages.map((image, index) => (
                            <li
                                key={index}
                                className={selectedPredefinedImage === image ? 'selectedpreimage' : ''}
                                onClick={() => predefinedimage(image)}
                            >
                                <img src={image} alt={`Predefined ${index + 1}`} />
                            </li>
                        ))} */}
                        {/* <MenuWrapper top={"10px"} style={{ width: "100%" }}>
                            {map(gameImageCategory?.meta_data, (x, index) => {
                                return (
                                    <MenuBtn style={{ borderBottom: dataTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: dataTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => navigationToMenu(x.id)}>
                                        {x?.value}
                                    </MenuBtn>
                                )
                            })}
                        </MenuWrapper> */}
                        {map(filtertedData, (item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={selectedPredefinedImage === item?.image_url ? 'selectedpreimage' : ''}
                                    // className={`allGalleryImg ${selectedPredefinedImage === item?.image_url ? 'selectedpreimage' : ''}`}
                                    onClick={() => predefinedimage(item?.image_url)}
                                >
                                    <img src={item?.image_url} key={index} />
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='galleryUpload'>
                    <Dropzone onDrop={handleImageUpload} accept="image/*" minSize={1024} maxSize={3072000}>
                        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                            const additionalClass = isDragAccept ? "accept" : isDragReject ? "reject" : "";

                            return (
                                <div
                                    {...getRootProps({
                                        className: `dropzone ${additionalClass}`,
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <p>Drag and drop images here, or click to select files</p>
                                </div>
                            );
                        }}
                    </Dropzone>
                    {selectedImage && (
                        <div className='nwGaleryPrevw'>
                            <p>Preview:</p>
                            <img src={URL.createObjectURL(selectedImage)} alt="Preview" />
                        </div>
                    )}
                </div>
                {percent !== 0 && (
                    <Progress style={{ marginTop: 10 }} percent={percent} />
                )}
                {showMsg && (
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red', marginTop: 10, fontSize: 14 }}>Please choose a valid image file.</span>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSubmitModal}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}

export { ImageModal };

