import axios from 'axios';
import * as GamesActions from './actions';
import * as Sentry from '@sentry/react';
const GAME_QUESTION = `/games/getGames`;
const CREATE_GAME = `/games/createGames`;
const DELETE_GAME = `/games/deleteGames/:id`;
const GENERATE_QUESTION_AI = `/gameQuestionsAI/generateAIQuestion`;
const GAME_BY_ID = `/sync/GetGameById`;
const GAME_RESULT_SYNC_BY_ID = `/sync/syncSessionResult`;
const GAME_BY_FILTER = `/games/getGamesByFilter`;
const GAME_DUPLICATE = `/games/duplicateGames`;
const FETCH_GAME_BY_ID = `/fetchGameById/:game_id`

// Server should return AuthModel
export const getGames = () => async (dispatch) => {
    try {
        const gameQuestion = await axios.get(GAME_QUESTION);
        await dispatch(GamesActions.listCreatedGame(gameQuestion?.data));
        return gameQuestion?.data
    } catch (error) {
        // Capture and report the error to Sentry with a custom message
        Sentry.captureException(`008 getGames ${error}.`);
        // Rethrow the error to propagate it further
        throw error;
    }
}

export const fetchGameById = async (id) => {
    try {
        const url = FETCH_GAME_BY_ID.replace(':game_id', id);
        const gameData = await axios.get(url);
        return gameData?.data;
    } catch (error) {
        console.error('Error fetching game by ID:', error);
        Sentry.captureException(`fetchGameById ${error}.`);
        throw error;
    }
}

export const CreateGames = (data, saveCurrentGameContext) => async (dispatch) => {
    try {
        const gameQuestionData = await axios.post(CREATE_GAME, data);
        const createdGame = gameQuestionData?.data[0];
        if (data?.game_id) {
            await dispatch(GamesActions.updateGameSuccess(gameQuestionData?.data[0]));
        } else {
            await dispatch(GamesActions.createGameSuccess(gameQuestionData?.data[0]));
        }
        // Save the created game data into current game context
        saveCurrentGameContext(createdGame);

        return gameQuestionData?.data[0]
    } catch (error) {
        console.error('Error creating or updating game:', error);
        Sentry.captureException(`009 createGames ${error}. Data: ${JSON.stringify(data)}}`);

        // dispatch(GamesActions.createOrUpdateGameFailure(error));
        throw error;
    }
}

export const generateQuestionsAnswersAI = (data) => async (dispatch) => {
    try {
        const generateQuestionsAnswersData = await axios.post(GENERATE_QUESTION_AI, data);
        return generateQuestionsAnswersData;
    } catch (e) {
        Sentry.captureException(`010 generateAIQuestion ${e}. Data: ${JSON.stringify(data)}`);
    }
}

export const deleteGames = (id) => async (dispatch) => {
    try {
        const url = DELETE_GAME.replace(':id', id);
        const deleteQuestion = await axios.delete(url);
        await dispatch(GamesActions.deleteGameSuccess(id));
        return deleteQuestion
    } catch (error) {
        console.error('Error deleting game:', error);
        Sentry.captureException(`011 deleteGames ${error}. ID: ${id}`);

        // dispatch(GamesActions.createOrUpdateGameFailure(error));
        throw error;
    }
}


export const GetGamesById = (data) => async (dispatch) => {
    try {
        const GamesById = await axios.post(GAME_BY_ID, data);
        // await dispatch(GamesActions.createGameSuccess(GamesById?.data));
        return GamesById?.data
    } catch (e) {
        Sentry.captureException(`012 GetGameById ${e}. Data: ${JSON.stringify(data)}`);

    }
}
export const handleSyncGameResult = async (session_id) => {
    try {
        const GamesById = await axios.get(`${GAME_RESULT_SYNC_BY_ID}/${session_id}`);
        return GamesById?.data
    } catch (error) {
        return error;
    }
}

// Filter Games

export const getFilterGames = async (filterData) => {
    try {
        const { data } = await axios.post(GAME_BY_FILTER, filterData);
        // await dispatch(GamesActions.createGameSuccess(GamesById?.data));
        return data?.data
    } catch (e) {
        Sentry.captureException(`013 getGamesByFilter ${e}. Data: ${JSON.stringify(filterData)}`);

    }
}

export const getDuplicateGames = async (duplicateData) => {
    try {
        const { data } = await axios.post(GAME_DUPLICATE, duplicateData);
        // await dispatch(GamesActions.createGameSuccess(GamesById?.data));
        return data?.data[0]
    } catch (e) {
        Sentry.captureException(`014 duplicateGames ${e}. Data: ${JSON.stringify(duplicateData)}`);

    }
}