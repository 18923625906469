//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';
import { cloneDeep, filter } from 'lodash';
//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const gamesInitial = [];

export const createGames = (state = gamesInitial, action) => {
    switch (action.type) {
        case ActionTypes.games.listCreatedGame:
            return action.payload;
        case ActionTypes.games.createGameSuccess:
            return [...state, action.payload]
        case ActionTypes.games.updateGameSuccess:
            const updateGame = state.map(x => { if (x.game_id === action.payload?.game_id) { return { ...x, ...action.payload }; } return x; });
            return updateGame;
        case ActionTypes.games.deleteGameSuccess:
            const delv = cloneDeep(state)
            const result = filter(delv, k => k.game_id !== action.payload)
            return result;
        default:
            return state;
    }
}