import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

const ChartsPieWidget9 = ({ className, data, type }) => {
  const [chartWidth, setChartWidth] = useState('100%');
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      const height = chartRef.current.chart.el.offsetHeight;

      const chartOptions = getChartOptions(data, type);
      chartRef.current.chart.updateOptions(chartOptions);
    }
  }, [data]);

  const { series, options } = getChartOptions(data, type);

  useEffect(() => {
    const handleResize = () => {
      let newWidth;
      if (window.innerWidth < 768) {
        newWidth = '375px';
      } else if (window.innerWidth < 1292) {
        newWidth = '520px';
      } else {
        newWidth = '650px';
      }
      setChartWidth(newWidth);
    };

    handleResize(); // Set initial width

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="chart" className={className}>
      <Chart options={options} series={series} type="pie" width={chartWidth} ref={chartRef} />
    </div>
  );
};

export { ChartsPieWidget9 };

function getChartOptions(data, type) {

  const items = _.keys(data);
  const quantities = _.values(data);
  const colors = ['#723ea3', '#25aceb', '#5213cc'];
  return {
    series: type === 'poll' ? quantities : [data.correct_response, data.in_correct_response, data.no_response],
    options: {
      chart: {
        type: 'pie',
      },
      colors: colors,
      labels: type === 'poll' ? items : ['Correct', 'Incorrect', 'No response'],
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
        },
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [`${name} : ${opts.w.globals.series[opts.seriesIndex]} ${type === 'poll' ? '%' : ''}`];

        },
      },
      legend: {
        show: false,
      },
    },
  };
};