//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create Game ----------

export const listCreatedGame = (returnedPayload) => ({ type: ActionTypes.games.listCreatedGame, payload: returnedPayload });
export const createGameSuccess = (returnedPayload) => ({ type: ActionTypes.games.createGameSuccess, payload: returnedPayload });
export const updateGameSuccess = (returnedPayload) => ({ type: ActionTypes.games.updateGameSuccess, payload: returnedPayload });
export const deleteGameSuccess = (returnedPayload) => ({ type: ActionTypes.games.deleteGameSuccess, payload: returnedPayload });