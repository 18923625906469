import { LoadingOutlined } from "@ant-design/icons";
import { CircularProgress } from "@material-ui/core";
import { Button, Spin, notification } from "antd";
import { deleteObject, ref } from "firebase/storage";
import { get } from 'lodash';
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { KTSVG } from '../../../../../_metronic/helpers';
import { useAuth } from "../../../auth";
import { ImageModal } from "../components/ImageModal";
import { storage } from "../firebaseConfig";
import { CreateGameQuestion } from "../store/GameQuestions/thunks";
import Acent from "../components/Acent";

const YourComponent = ({ questionType, isEditable, edit, CardTime, CardOption }) => {
    const [items, setItems] = useState([
        { id: "item-1", content: "", placeholder: "Item 1", bgColor: 'red' },
        { id: "item-2", content: "", placeholder: "Item 2", bgColor: 'pink' },
        { id: "item-3", content: "", placeholder: "Item 3", bgColor: 'green' },
        { id: "item-4", content: "", placeholder: "Item 4", bgColor: 'orange' }
    ]);
    const [rightItems, setRightItems] = useState([
        { id: "item-1", content: "", placeholder: "Item 1", bgColor: 'red' },
        { id: "item-2", content: "", placeholder: "Item 2", bgColor: 'pink' },
        { id: "item-3", content: "", placeholder: "Item 3", bgColor: 'green' },
        { id: "item-4", content: "", placeholder: "Item 4", bgColor: 'orange' }
    ]);
    // const [answerData, setAnswerData] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { currentUser, currentGameContext } = useAuth()
    const dispatch = useDispatch();
    const location = useLocation()
    const currentURL = location.pathname;
    const currentGameId = currentURL.split('/').pop();
    const [uploadedImage, setUploadedImage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [removeImageLoader, setRemoveImageLoader] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const getCurrentGame = currentGameContext;
    const gametype = get(getCurrentGame, 'game_type', {}) ?? {}

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newItems = [...rightItems];
        const [movedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, movedItem);

        setRightItems(newItems);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    // const handleChange = (key, value,) => {
    //     setAnswerData({
    //         ...answerData,
    //         [key]: value,
    //     });
    // };

    const handleRemoveImage = (imageUrl) => {
        if (imageUrl) {
            setRemoveImageLoader(true)
            // const storageRef = ref(storage, imageUrl);
            // deleteObject(storageRef).then(() =>
            try{
                if (isEditable) {
                    const question = {
                        title: inputValue,
                        imageUrl: '',
                        time: CardTime,
                        option: CardOption

                    }
                    const UpdateData = {
                        ...edit,
                        question,
                    }
                    dispatch(CreateGameQuestion(UpdateData))
                    setRemoveImageLoader(false)
                    setUploadedImage('')
                }
                setUploadedImage('')
            }
            catch (error) {
                setRemoveImageLoader(false)
            }
        }
    }

    useEffect(() => {
        if (isEditable) {
            const Question = get(edit, 'question', '') ?? {};
            const Answer = get(edit, 'answers', '') ?? {};
            // Set question title and image
            setInputValue(Question?.title);
            setUploadedImage(Question?.imageUrl);
            // Set options in state
            if (Answer.answer && Array.isArray(Answer.answer)) {
                const updatedItems = items.map((item, index) => ({
                    ...item,
                    content: Answer.options[index] || item.content // Use Answer.answer value if available, otherwise keep the existing content
                }));
                setItems(updatedItems);

            }
            if (Answer.description && Array.isArray(Answer.description)) {
                const updatedItems = rightItems.map((item, index) => ({
                    ...item,
                    content: Answer.description[index] || item.content // Use Answer.answer value if available, otherwise keep the existing content
                }));
                setRightItems(updatedItems);

            }

        } else {
            setInputValue("");
            setUploadedImage('')
            setItems((prevItems) => prevItems.map((item) => ({ ...item, content: '' })));
            setRightItems((prevItems) => prevItems.map((item) => ({ ...item, content: '' })));


        }
    }, [isEditable, edit]);

    // const handleTextareaChange = (event, index, isAccent) => {
    //     const updatedItems = [...items];

    //     if (isAccent) {
    //         // If it's an accent option, concatenate it to the existing content
    //         const existingContent = updatedItems[index]?.content || '';
    //         updatedItems[index].content = existingContent + event.target.value;
    //     } else {
    //         // If it's the textarea, update the content
    //         updatedItems[index].content = event.target.value;
    //     }

    //     // Update the state
    //     setItems(updatedItems);
    // };

    // const handleTextareaChange = (event, index, isAccent) => {
    //     const updatedItems = [...items];

    //     let inputValue = event.target.value;

    //     if (inputValue.length > 80) {
    //         // Truncate the input value to 80 characters
    //         inputValue = inputValue.substring(0, 80);
    //     }

    //     if (isAccent) {
    //         // If it's an accent option, concatenate it to the existing content
    //         const existingContent = updatedItems[index]?.content || '';
    //         updatedItems[index].content = existingContent + inputValue;
    //     } else {
    //         // If it's the textarea, update the content
    //         updatedItems[index].content = inputValue;
    //     }

    //     // Update the state
    //     setItems(updatedItems);
    // };

    const handleInputAreaChange = (accent, index, isAccent) => {
        const updatedItems = [...items];
        if (isAccent) {
            const textarea = document.getElementById(`textarea-${index}`);

            if (textarea) {
                const startPos = textarea.selectionStart;
                const endPos = textarea.selectionEnd;

                const newInputValue =
                    updatedItems[index].content.substring(0, startPos) +
                    accent +
                    updatedItems[index].content.substring(endPos);

                // Enforce a maximum character limit of 80 for individual content
                updatedItems[index].content = newInputValue.substring(0, 80);

                const newCursorPos = startPos + accent.length;

                setTimeout(() => {
                    textarea.setSelectionRange(newCursorPos, newCursorPos);
                    textarea.focus();
                }, 0);
            }
        } else {
            // Enforce a maximum character limit of 80 for individual content
            updatedItems[index].content = accent.substring(0, 80);
        }

        setItems(updatedItems);
    };

    const handleTextareaChange = (accent, index, isAccent) => {
        const updatedItems = [...rightItems];

        if (isAccent) {
            const textarea = document.getElementById(`textarea-${index}`);

            if (textarea) {
                const startPos = textarea.selectionStart;
                const endPos = textarea.selectionEnd;

                const newInputValue =
                    updatedItems[index].content.substring(0, startPos) +
                    accent +
                    updatedItems[index].content.substring(endPos);

                // Enforce a maximum character limit of 80 for individual content
                updatedItems[index].content = newInputValue.substring(0, 80);

                const newCursorPos = startPos + accent.length;

                setTimeout(() => {
                    textarea.setSelectionRange(newCursorPos, newCursorPos);
                    textarea.focus();
                }, 0);
            }
        } else {
            // Enforce a maximum character limit of 80 for individual content
            updatedItems[index].content = accent.substring(0, 80);
        }
        setRightItems(updatedItems);

    };
    const openCheckNotification1 = (type) => {
        api[type]({
            message: 'Invalid !',
            description:
                'Please fill all the option.',
            duration: 2,

        });
    };

    const openCheckNotification2 = (type) => {
        api[type]({
            message: 'Entry is invalid !',
            description:
                'Please fill unique value in option.',
            duration: 2,

        });
    };
    const hasDuplicate = (contentArray) => {
        // Check if contentArray is empty
        if (contentArray.length < 4) {
            openCheckNotification1('error');
            return { status: false }; // Return false if contentArray is empty or all elements are empty strings
        }
        const uniqueValues = new Set();
        for (const value of contentArray) {
            if (value !== "") {
                if (uniqueValues.has(value)) {
                    openCheckNotification2('error');
                    return { status: false }; // Return false if a non-unique value is found
                } else {
                    uniqueValues.add(value);
                }
            }
        }
        // If no duplicates found, return a status indicating success
        return { status: true };
    };


    const onHandleQuestion = async (type) => {
        const contentArrayOption = items.map((item) => item.content);
        const contentArrayAnswer = rightItems.map((item) => item.content);
        if (gametype === "1study") {
            var cleanOption = await hasDuplicate(contentArrayOption);
        } else {
            var cleanOption = await hasDuplicate(contentArrayAnswer);
        }
        if (cleanOption.status === true) {

            var cleanAnswer = await hasDuplicate(contentArrayAnswer);

            // Continue your logic based on the result of the second cleaning
            if (cleanAnswer.status) {
                // Function to shuffle the array using Fisher-Yates algorithm
                const shuffleArray = (array) => {
                    for (let i = array.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [array[i], array[j]] = [array[j], array[i]];
                    }
                    return array;
                }

                // Shuffle the array multiple times to ensure more randomness
                const shuffleMultipleTimes = (array, times) => {
                    let shuffledArray = array.slice();
                    for (let i = 0; i < times; i++) {
                        shuffledArray = shuffleArray(shuffledArray);
                    }
                    return shuffledArray;
                };
                let randomizedOptions;
                let descriptionRandom;

                if (gametype === "1study") {
                   
                    randomizedOptions = shuffleMultipleTimes([...contentArrayOption], 5);
                    descriptionRandom = shuffleMultipleTimes([...contentArrayAnswer], 5)
                    // Check if randomizedOptions is equal to contentArrayOption
                    if (arraysEqual(randomizedOptions, contentArrayOption)) {
                        randomizedOptions = shuffleMultipleTimes([...contentArrayOption], 5); // Reshuffle if it's the same
                    }
                } else {
                   
                    randomizedOptions = shuffleMultipleTimes([...contentArrayAnswer], 5);
                    // Check if randomizedOptions is equal to contentArrayAnswer
                    if (arraysEqual(randomizedOptions, contentArrayAnswer)) {
                        randomizedOptions = shuffleMultipleTimes([...contentArrayAnswer], 5); // Reshuffle if it's the same
                    }
                }

                const question = {
                    title: inputValue,
                    imageUrl: `${uploadedImage ? uploadedImage : ''}`,
                    time: CardTime,
                    option: CardOption
                }
                let answers;

                if (gametype === "1study") {
                    answers = {
                        // options: randomizedOptions,
                        options: contentArrayOption,
                        answer: contentArrayAnswer,
                        // description: contentArrayAnswer,
                        description: descriptionRandom,
                    };
                   
                } else {

                    answers = {
                        options: randomizedOptions,
                        answer: contentArrayAnswer,
                        description: contentArrayAnswer,
                    };
                    
                }



                // Function to check if two arrays are equal
                function arraysEqual(arr1, arr2) {
                    if (arr1.length !== arr2.length) return false;
                    for (let i = 0; i < arr1.length; i++) {
                        if (arr1[i] !== arr2[i]) return false;
                    }
                    return true;
                }


                if (isEditable) {
                    const data = {
                        ...edit,
                        org_id: get(currentUser, 'company_id', ''),
                        created_by: get(currentUser, 'user_id', ''),
                        type,
                        question,
                        answers,
                        game_id: currentGameId
                    }
                    await dispatch(CreateGameQuestion(data))
                } else {
                    const data = {
                        org_id: get(currentUser, 'company_id', ''),
                        created_by: get(currentUser, 'user_id', ''),
                        type,
                        question,
                        answers,
                        game_id: currentGameId
                    }
                    await dispatch(CreateGameQuestion(data))
                    // Update the items state to clear the content
                    setItems((prevItems) => prevItems.map((item) => ({ ...item, content: '' })));
                    setRightItems((prevItems) => prevItems.map((item) => ({ ...item, content: '' })));

                    setInputValue('');
                    setUploadedImage('')
                }
                setLoading(false);
            } else {
                setLoading(false);

            }

        }
        else {
            setLoading(false);
        }
    }

    const onImageUpload = (image) => {
        setUploadedImage(image)
        if (isEditable) {
            const question = {
                title: inputValue,
                imageUrl: image,
                time: CardTime,
                option: CardOption
            }
            const UpdateData = {
                ...edit,
                question,
            }
            dispatch(CreateGameQuestion(UpdateData))
        }
    }

    const SPANISH_ITEM_LOWER = ["á", "é", "í", "ó", "ú", "ü", "ñ", "¿", "¡"];
    const SPANISH_ITEM_UPPER = ["Á", "É", "Í", "Ó", "Ú", "Ü", "Ñ", "¿", "¡"];

    const textareaRef = useRef(null);

    const insertAccent = (accent) => {
        const textarea = textareaRef.current;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;

        const newInputValue =
            inputValue.substring(0, startPos) +
            accent +
            inputValue.substring(endPos);

        setInputValue(newInputValue.substring(0, 130));

        // Move the cursor to the right after the added accent
        const newCursorPos = startPos + accent.length;

        // Set the new cursor position immediately after the added accent
        setTimeout(() => {
            textarea.setSelectionRange(newCursorPos, newCursorPos);
            textarea.focus();
        }, 0);
    };

    const handleInputChange = (e) => {
        // const value = e.target.value;
        // if (value.length <= 130) {
        //     setInputValue(value);
        //     adjustTextareaHeight();
        // }
        const maxLength = 130;
        const value = e.target.value;
        const trimmedValue = value.slice(0, maxLength);
        setInputValue(trimmedValue);
        adjustTextareaHeight();
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [inputValue]);


    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight();
        }
    };

    const [dropdownVisible0, setDropdownVisible0] = useState(false);

    const toggleDropdown = (position) => {
        if (position === 0) {
            setDropdownVisible0(!dropdownVisible0);

        }
    }
    const [dropdownVisible, setDropdownVisible] = useState([false, false, false, false]);

    const toggleDropdown1 = (index) => {
        setDropdownVisible((prevVisibility) => {
            const newVisibility = [...prevVisibility];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };
    const handleBlur1 = () => {
        setDropdownVisible0(false);
        setDropdownVisible([false, false, false, false])


    };

    const adjustTextareaHeight1 = (index) => {
        const textareaRef = document.getElementById(`textarea-${index}`);
        if (textareaRef) {
            textareaRef.style.height = 'auto';
            textareaRef.style.height = `${textareaRef.scrollHeight}px`;
        }
    };

    const handleKeyDown1 = (e, index) => {
        if (e.key === 'Enter') {
            adjustTextareaHeight1(index);
        }
    };

    useEffect(() => {
        adjustTextareaHeight1(0);
    }, [rightItems[0].content]);
    useEffect(() => {
        adjustTextareaHeight1(1);
    }, [rightItems[1].content]);
    useEffect(() => {
        adjustTextareaHeight1(2);
    }, [rightItems[2].content]);
    useEffect(() => {
        adjustTextareaHeight1(3);
    }, [rightItems[3].content]);
    const textareaRef1 = useRef(null);
    const textareaRef2 = useRef(null);
    const textareaRef3 = useRef(null);
    const textareaRef4 = useRef(null);
    const getTextareaRef = (index) => {
        switch (index) {
            case 0:
                return textareaRef1;
            case 1:
                return textareaRef2;
            case 2:
                return textareaRef3;
            case 3:
                return textareaRef4;
            default:
                return null;
        }
    };

    return (
        <div className='layoutWraper'>
            {contextHolder}

            <div className='layoutWrapLeft'>
                <div className='addBkgroundImg'>
                    <div className='addImgShow'>
                        <div className='uploadImgUpr'>
                            {uploadedImage ? (
                                <div className="image-container">
                                    <img src={uploadedImage} alt="Uploaded" />
                                    <div className="edit-icon">
                                        <span onClick={() => handleRemoveImage(uploadedImage)} >{removeImageLoader ? <CircularProgress size={15} style={{ color: 'teal' }} /> : null} Remove</span>
                                    </div>
                                </div>
                            ) : (
                                <img src={require('../utils/assets/images/upload2.png')} alt="" />
                            )}
                        </div>
                    </div>
                    <div className='uploadImgIcn'>
                        <label className="btn btn-icon" onClick={() => {
                            setShowModal(true)
                        }}>
                            <i className="fa-solid fa-plus fs-7 uploadImgPlus"></i>
                        </label>
                    </div>
                    <div className='insertMediaDP'>
                        <label>Find and insert media</label>
                    </div>
                </div>
                <ImageModal isOpens={showModal} isClose={setShowModal} currentGameId={currentGameId} onSubmit={(e) => {
                    onImageUpload(e)
                }} />
            </div>

            <div className='quizLayout'>
                <div className='card-body p-0'>
                    <div className={gametype === '1study' ? 'addQuesBoxStudy' : 'addQuesBox'}>
                        <div className='playGameMidSrch my-textarea'>
                            <Acent inputRef={textareaRef} setValue={inputValue?.length < 130 ? setInputValue : null} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                            {/* <div style={{ position: 'relative', top: 0, left: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                <div className="dropdown-container">
                                    <button className="dropdown-button accent-select" onClick={() => toggleDropdown(0)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                        Á &#8659;
                                    </button>


                                    {dropdownVisible0 && (
                                        <div className="dropdown-content accent-select">
                                            <ul className="accent-list">
                                                {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                    <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                        {accent}
                                                    </li>
                                                ))}
                                            </ul>

                                            <ul className="accent-list">
                                                {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                    <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                        {accent}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            {/* <textarea
                                style={{ background: 'transparent', border: 0, fontSize: 20, color: 'white', marginTop: 20, resize: 'none' }}
                                rows={3}
                                type="text"
                                value={inputValue}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder={isFocused ? '' : 'Start typing your question...'}
                            /> */}
                            <div style={{ position: 'relative', marginTop: 5 }}>

                                <textarea
                                    ref={textareaRef}
                                    style={{
                                        background: 'transparent',
                                        border: 0,
                                        fontSize: 20,
                                        color: 'white',
                                        resize: 'none',
                                        // height: '40px', // Set the height for 2 rows of text
                                        overflowY: 'hidden', // Hide extra content beyond the defined height
                                        marginTop: "20",
                                        outline: 'none'

                                    }}
                                    type="text"
                                    value={inputValue}
                                    onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    onBlur={() => handleBlur()}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}

                                    placeholder={isFocused ? '' : 'Start typing your question...'}
                                    className="custom-textarea"
                                />
                                <div className='counter-position-puzzle'
                                    style={{
                                        position: 'absolute',
                                        // bottom: -3,
                                        right: 0,
                                        color: 'white',
                                        fontSize: 12,
                                    }}
                                >
                                    {inputValue.length}/130
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="puzzleMain card-p mt-n20 position-relative">
                    <div className="puzzleRow" style={{ flex: "0 0 140px", position: "sticky", top: "0" }}>
                        {/* Left Column */}
                        {/* Left Column (Draggable) */}
                        <div className="puzzleLeft">


                            <div >
                                {items.map((item, index) => (
                                    <div>
                                        <div className="puzzleLeftTxt" style={{ backgroundColor: `${item.bgColor}` }}>
                                            <div>
                                                <div>
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen032.svg'
                                                        className='svg-icon-3x d-block puzzleAnimatedPart'
                                                    />
                                                </div>
                                                {/* Render your left section content here */}
                                                <div className="pzleInputSection">
                                                    {gametype === "1study" ?
                                                        <input type="text" placeholder={item.placeholder} value={item.content}
                                                            onChange={(e) => handleInputAreaChange(e.target.value, index, false)}
                                                            style={{ backgroundColor: `${item.bgColor}`, textAlign: 'center', color: 'white', fontWeight: '600' }}
                                                        ></input>
                                                        : <input type="text" placeholder={item.placeholder} readOnly
                                                            style={{ backgroundColor: `${item.bgColor}`, textAlign: 'center', color: 'white', fontWeight: '600' }}
                                                        ></input>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                ))}

                            </div>

                        </div>

                    </div>

                    <div className="puzzleRight" style={{ flex: "1" }}>
                        {/* Right Section (Draggable) */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div className="puzzleRightTxt"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {rightItems.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="textareadrag">
                                                            <div className="textareadragLft">
                                                                <Acent inputRef={getTextareaRef(index)} setValue={(e) => handleTextareaChange(e, index, false)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />

                                                                {/* <div style={{ position: 'relative', top: 0, left: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <div className="dropdown-container">
                                                                        <button className="dropdown-button accent-select" onClick={() => toggleDropdown1(index)} onBlur={() => handleBlur1()} style={{ padding: "2px", position: "relative", top: "-13px" }}>
                                                                            Á &#8659;
                                                                        </button>
                                                                        {dropdownVisible[index] && (
                                                                            <div className="dropdown-content accent-select">
                                                                                <ul className="accent-list">
                                                                                    {SPANISH_ITEM_LOWER.map((accent) => (
                                                                                        <li key={index} className="accent-list-item" onMouseDown={() => handleTextareaChange(accent, index, true)}>
                                                                                            {accent}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                                <ul className="accent-list">
                                                                                    {SPANISH_ITEM_UPPER.map((accent) => (
                                                                                        <li key={index} className="accent-list-item" onMouseDown={() => handleTextareaChange(accent, index, true)}>
                                                                                            {accent}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div> */}
                                                                <textarea
                                                                    ref={getTextareaRef(index)}
                                                                    id={`textarea-${index}`}
                                                                    type="text"
                                                                    placeholder={`Answer ${index + 1}`}
                                                                    className='addAnswerMid'
                                                                    value={item.content}  // Set the value from state
                                                                    onKeyDown={(e) => handleKeyDown1(e, index)}
                                                                    onChange={(e) => handleTextareaChange(e.target.value, index, false)}
                                                                />
                                                                <div style={{ color: 'black', textAlign: 'right' }}>
                                                                    {item.content.length}/80
                                                                </div>
                                                            </div>
                                                            <div className='textareadragRght'>
                                                                {/* {item.content} */}
                                                                <i class='fa-solid fa-grip-lines'></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                {/* {inputValue !== '' && ( */}
                <div className="moreAnsBtnSave"><Button disabled={
                    gametype === "1study" ?
                        inputValue.trim() === '' || rightItems[0]?.content.trim() === '' || rightItems[1]?.content.trim() === '' || rightItems[2]?.content.trim() === '' || rightItems[3]?.content.trim() === '' || items[0]?.content.trim() === '' || items[1]?.content.trim() === '' || items[2]?.content.trim() === '' || items[3]?.content.trim() === ''
                        :
                        inputValue.trim() === '' || rightItems[0]?.content.trim() === '' || rightItems[1]?.content.trim() === '' || rightItems[2]?.content.trim() === '' || rightItems[3]?.content.trim() === ''
                } onClick={() => {
                    onHandleQuestion("puzzle")
                    setLoading(true);
                }}> {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} /> : <>  <AiOutlinePlusSquare />{`${isEditable ? 'Update' : 'save'}`}</>}</Button></div>
                {/* )} */}
            </div>
        </div>
    );
};

export default YourComponent;

// items