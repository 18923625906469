import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import { Provider } from "react-redux";
import store from "./app/modules/apps/IPlayGame/store/store"
import './_metronic/assets/sass/custom_style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/custom_styles.scss'

import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { FirebaseProvider } from '@auth/core/FirebaseContext'
import { ThemeModeProvider } from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { FunctionProviders } from './app/modules/auth/core/functionRoles'
import { SharedSessionProviders } from "./app/modules/auth/core/SessionShare"
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <MetronicI18nProvider>
          <ThemeModeProvider>
            <AuthProvider>
              <FunctionProviders>
                <SharedSessionProviders>
                    <FirebaseProvider>
                      <AppRoutes />
                    </FirebaseProvider>
                </SharedSessionProviders>
              </FunctionProviders>
            </AuthProvider>
          </ThemeModeProvider>
        </MetronicI18nProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
