/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { register } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { Modal, Button } from 'react-bootstrap'

import 'react-toastify/dist/ReactToastify.css'

interface FormValues {
  name: string
  phone: string
  email: string
  emailPhone: string
  password: string
  confirmPassword: string,
  terms: string
}

export function Registration() {
  let messageFrom = [
    {
      header: 'Success',
      body: `Please enter the OTP sent to your phone number to complete verification. If you haven't received it, click "Resend OTP". To modify your phone number, click "Edit Phone Number". Remember to verify your email for receiving important notifications. Contact support for assistance. Thank you for verifying your phone number and email.`,
      pos: true,
    },
    {
      header: 'Success',
      body: `Please enter the 6-digit OTP sent to your phone number to verify. If you haven't received it, click "Resend OTP". To modify your phone number, click "Edit Phone Number". For assistance, contact our support team. Thank you for verifying your phone number`,
      pos: true,
    },
    {
      header: 'Success',
      body: `Thank you for registering! We have sent a verification link to your email inbox. Please check your email and click on the link to verify your email address. If you haven't received the email, please check your spam or junk folder. If you still encounter any issues, please contact our support team for assistance. Thank you!`,
      pos: false,
    },
  ]
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { saveAuth } = useAuth()
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [isValid, setIsValid] = useState(false)
  const [errorMessages, setErrorMessages] = useState<any>([])
  // const [showPassword, setShowPassword] = useState(false)
  const [passwordType, setPasswordType] = useState('password')
  const [passwordType1, setPasswordType1] = useState('password')
  const [resValue, setResValue] = useState<any>({
    name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [ExistV, setExistV] = useState('');

  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState<Partial<FormValues>>({})

  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value
    setPassword(newPassword)
    if (newPassword && newPassword !== '') {
      const validationResult = validatePassword(newPassword)
      setErrorMessages(validationResult.errorMessages)
    }
  }

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value)
    setPasswordMatch(event.target.value === password)
    // setPasswordMatch(event.target.value === password)
  }

  const validatePassword = (password: any) => {
    const uppercaseRegex = /^(?=.*[A-Z])/
    const numberRegex = /^(?=.*\d)/
    const symbolRegex = /^(?=.*[@$!%*?&#()_-])/
    const lengthRegex = /^.{8,}$/

    const errors = []
    if (password) {
      if (!uppercaseRegex.test(password)) {
        errors.push('Password must contain at least one uppercase letter')
      }

      if (!numberRegex.test(password)) {
        errors.push('Password must contain at least one number')
      }

      if (!symbolRegex.test(password)) {
        errors.push('Password must contain at least one symbol')
      }

      if (!lengthRegex.test(password)) {
        errors.push('Password must be at least 8 characters long')
      }
    }
    else {
      errors.push('Password can not be blank');
    }

    return {
      isValid: errors.length === 0,
      errorMessages: errors,
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    var newErrors = errors;
    if (name === 'toc') {
      setResValue({ ...resValue, [name]: e.target.checked })
      if (e.target.checked) {
        newErrors.terms = ''
      }
      else {
        newErrors.terms = 'Before proceeding, please check the box to accept the terms and conditions.'
      }
    }
    else {
      setResValue({ ...resValue, [name]: value })
    }

    if ((name === 'email' && value !== '') || (name === 'phone' && value !== '')) {
      newErrors.emailPhone = ''
      if (name === 'phone') {
        if (value === '' || phoneNumber(value)) {
          newErrors.phone = ''
        }
        else {
          newErrors.phone = 'Invalid phone number'
        }
      }
      if (name === 'email')
        if (value === '' || isValidEmail(value)) {
          newErrors.email = ''
        }
        else {
          newErrors.email = 'Invalid email address'
        }
    }

    if (name === 'name') {
      if (value && value !== '') {
        newErrors.name = ''
      }
      else {
        newErrors.name = 'Please fill in your name as it is a mandatory field to continue'
      }
    }

    setErrors(newErrors)
  }
  const phoneNumber = (phoneNumber: string) => {
    var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
    return pattern.test(phoneNumber) && phoneNumber.length < 15 && phoneNumber.length > 9
  }
  const isValidEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const newErrors: Partial<FormValues> = {}
    const validationResult = validatePassword(password)

    if (!resValue.name) {
      newErrors.name = 'Please fill in your name as it is a mandatory field to continue'
    }
    if (!resValue.phone && !resValue.email) {
      newErrors.emailPhone = 'Please provide either your phone number or email address'
    } else {
      if (resValue.phone && resValue.phone !== '' && (!phoneNumber(resValue.phone))) {
        newErrors.phone = 'Invalid phone number'
      }
      if (resValue.email && resValue.email !== '' && (!isValidEmail(resValue.email))) {
        newErrors.email = 'Invalid email address'
      }
    }
    if (!resValue.toc) {
      newErrors.terms = 'Before proceeding, please check the box to accept the terms and conditions.'
    }

    setErrorMessages(validationResult.errorMessages)
    setPasswordMatch(confirmPassword === password)
    setErrors(newErrors)

    if (
      Object.keys(newErrors).length === 0 &&
      validationResult.isValid &&
      confirmPassword === password
    ) {
      //if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true)
        resValue.password = password
        resValue.is_admin = true
        const { data: auth } = await register(resValue)
        setLoading(false)

        saveAuth(auth)
        if (auth.code === 200) {
          if (auth.message === 'Email and phone number sent otp and url') {
            navigate('/auth/enter-otp', { state: messageFrom[0] })
          } else if (auth.message === 'Phone number sent otp') {
            navigate('/auth/enter-otp', { state: messageFrom[1] })
          } else if (auth.message === 'Email sent url') {
            navigate('/auth/enter-otp', { state: messageFrom[2] })
          } else {
            navigate('/organisation', { state: messageFrom[0] })
          }
        }

        if (!auth.hassuccessed) {
          setShowModal(true)
          setExistV(auth.messageCode)
        }
      } catch (e) {
        setLoading(false)
        setMessage('Network Error')
      }
    }
  }

  // const [mess, setMess] = useState<any>({header: '', body: '', pos: false})

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // const handleShowModal = () => {
  //   setShowModal(true)
  // }
  const goLogin = () => {
    setShowModal(false)
    navigate('/auth/login')
  }
  const togglePassword = (e: any) => {
    e.preventDefault()
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }
  const togglePassword1 = (e: any) => {
    e.preventDefault()
    if (passwordType1 === 'password') {
      setPasswordType1('text')
      return
    }
    setPasswordType1('password')
  }

  return (
    <>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework  w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'
              noValidate
              id='kt_login_signup_form'
              onSubmit={handleSubmit}
            >
              <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>

                <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
              </div>

              <div className='row g-3 mb-9'>
                <div className='col-md-6'>
                  <a
                    href='#'
                    className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                  >
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/auth/google-icon.svg')}
                      className='h-15px me-3'
                    />
                    Sign in with Google
                  </a>
                </div>

                <div className='col-md-6'>
                  <a
                    href='#'
                    className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                  >
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/auth/apple-black.svg')}
                      className='theme-light-show h-15px me-3'
                    />
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/auth/apple-black-dark.svg')}
                      className='theme-dark-show h-15px me-3'
                    />
                    Sign in with Apple
                  </a>
                </div>
              </div>

              <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                  <Modal.Title>{ExistV === 'EmailPhone' ? 'Email and Phone number' : ExistV === 'Email' ? 'Email' : 'Phone number'} already exists </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    "It seems that the provided {ExistV === 'EmailPhone' ? 'email and phone number' : ExistV === 'Email' ? 'email' : 'phone number'} already exists in our
                    database. Please try logging in using your existing account credentials. If you
                    have forgotten your password, you can use the Forgot Password option to reset
                    it. If you need further assistance, please contact our support team. Thank you."
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleCloseModal}>
                    Close
                  </Button>

                  <Button variant='primary' onClick={() => goLogin()}>
                    Go To Login
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className='separator separator-content my-14'>
                <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
              </div>

              <div className='fv-row mb-4'>
                <input
                  placeholder='Name'
                  type='text'
                  name='name'
                  autoComplete='off'
                  onChange={handleInput}
                  className='form-control bg-transparent'
                />
                {errors.name && <span className='loginfixderror'>{errors.name}</span>}
              </div>
              <div className='row mb-4'>
                <div className='col-xl-6'>
                  <div className='fv-row mb-4'>
                    <input
                      placeholder='Phone'
                      type='text'
                      onChange={handleInput}
                      name='phone'
                      className='form-control bg-transparent inputfixregister'
                    />
                    {errors.phone && <span className='loginfixderror'>{errors.phone}</span>}
                  </div>
                </div>
                <div className='col-xl-6'>
                  <div className='fv-row mb-4'>
                    <input
                      placeholder='Email'
                      type='email'
                      autoComplete='off'
                      name='email'
                      onChange={handleInput}
                      className='form-control bg-transparent'
                    />
                    {errors.email && <span className='loginfixderror'>{errors.email}</span>}
                  </div>
                </div>
                {errors.emailPhone && <span className='loginfixderror'>{errors.emailPhone}</span>}
              </div>

              <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <div className='position-relative mb-3' id='setpass'>
                    <input
                      className='form-control bg-transparent'
                      type={passwordType}
                      placeholder='password'
                      autoComplete='off'
                      name='password'
                      onChange={handlePasswordChange}
                    />
                    <div id='passbutton'>
                      <button className='btn  ' onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <i className='bi bi-eye-slash  '></i>
                        ) : (
                          <i className='bi bi-eye '></i>
                        )}
                      </button>
                    </div>

                    {!isValid && (
                       <div className='text-muted '>
                     <span className='loginfixderror'></span> 
                     </div>
                      // <ul className='loginfixderror'>

                      //   {/* {errorMessages.map((message: any, index: number) => (
                      //     <li key={index}>{message}</li>
                      //   ))} */}
                      // </ul>
                    )}
                  </div>
                </div>
                <div className='text-muted'>
                  Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
              </div>
              <div className='fv-row mb-8' id='setpass'>
                <input
                  placeholder='Confirm Password'
                  type={passwordType1}
                  autoComplete='off'
                  className='form-control bg-transparent'
                  onChange={handleConfirmPasswordChange}
                />
                <div id='passbutton'>
                  <button className='btn  ' onClick={togglePassword1}>
                    {passwordType1 === 'password' ? (
                      <i className='bi bi-eye-slash  '></i>
                    ) : (
                      <i className='bi bi-eye '></i>
                    )}
                  </button>
                </div>
                {!passwordMatch && <span className='loginfixderror'>The passwords you've entered do not match</span>}
              </div>
              <div className='fv-row mb-8'>
                <label className='form-check form-check-inline'>
                  <input className='form-check-input' type='checkbox' name='toc' value='1' onChange={handleInput} />
                  <span className='form-check-label fw-semibold text-gray-700 fs-base ms-1'>
                    I Accept the
                    <a href='#' className='ms-1 link-primary'>
                      Terms
                    </a>
                  </span>
                </label>
                <div>
                </div>
                {errors.terms && <span className='loginfixderror'>{errors.terms}</span>}
              </div>

              <div className='d-grid mb-10'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress fixloadersite'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                {message && (
                  <div className='alert alert-danger alert-dismissible text-center mt-10' role='alert'>
                    {message}
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='alert'
                      aria-label='Close'
                    ></button>
                  </div>
                )}
              </div>

              <div className='text-gray-500 text-center fw-semibold fs-6'>
                Already have an Account?
                <Link to='/auth/login'  className='link-primary forpasswordfix'>
                  Sign in
                </Link>
              </div>
            </form>
          </div>
        </div>

        <div className='w-lg-500px d-flex flex-stack px-10 mx-auto'>
          <div className='me-10'>
            <button
              className='btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-start'
              data-kt-menu-offset='0px, 0px'
            >
              <img
                data-kt-element='current-lang-flag'
                className='w-20px h-20px rounded me-3'
                src={toAbsoluteUrl('/media/auth/united-states.svg')}
                alt=''
              />
              <span data-kt-element='current-lang-name' className='me-1'>
                English
              </span>
              <i className='ki-duotone ki-down fs-5 text-muted rotate-180 m-0'></i>
            </button>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7'
              data-kt-menu='true'
              id='kt_auth_lang_menu'
            >
              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='English'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/united-states.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>English</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='Spanish'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/spain.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>Spanish</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='German'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/germany.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>German</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='Japanese'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/japan.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>Japanese</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='French'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/france.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>French</span>
                </a>
              </div>
            </div>
          </div>

          <div className='d-flex fw-semibold text-primary fs-base gap-5'>
            <a href='../../demo1/dist/pages/team.html' target='_blank'>
              Terms
            </a>
            <a href='../../demo1/dist/pages/pricing/column.html' target='_blank'>
              Plans
            </a>
            <a href='../../demo1/dist/pages/contact.html' target='_blank'>
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
