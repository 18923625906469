//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Game Category ----------

export const listGameCategory = (returnedPayload) => ({ type: ActionTypes.gamesCategory.listGameCategory, payload: returnedPayload });
export const createGameCategorySuccess = (returnedPayload) => ({ type: ActionTypes.gamesCategory.createGameCategorySuccess, payload: returnedPayload });
export const updateGameCategorySuccess = (returnedPayload) => ({ type: ActionTypes.gamesCategory.updateGameCategorySuccess, payload: returnedPayload });
export const deleteGameCategorySuccess = (returnedPayload) => ({ type: ActionTypes.gamesCategory.deleteGameCategorySuccess, payload: returnedPayload });
export const listCategoryUnit = (returnedPayload) => ({ type: ActionTypes.gamesCategory.listCategoryUnit, payload: returnedPayload });