import axios from 'axios'
import * as Sentry from '@sentry/react';

import * as GamesActions from './actions';
export const get_answer_by_session = `/submissions/get_answer_by_session`;

export const getCurrentSessionSubmissions = (session_id) => async (dispatch) => {
    try {
     const currentGameSessionData = await axios.post(get_answer_by_session,{
         session_id
     });
     if(currentGameSessionData.data&&currentGameSessionData.data.hassuccessed){
         await dispatch(GamesActions.setCurrentSessionSubmissions(currentGameSessionData?.data.data));
         return currentGameSessionData.data.data;
     }else{
         return null;
     }
    } catch (error) {
        Sentry.captureException(`050 session_submission ${error}. session_id: ${session_id}`);


        return null;
    } 
 }
 