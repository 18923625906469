import React, { FC, useState, createContext, useContext } from 'react';
import { WithChildren } from '../../../../_metronic/helpers';

type SessionSharedContextProps = {
    sharedSessionData: any;
    setSharedSession: (data: any) => void;
};

const initSessionSharedContextPropsState = {
    sharedSessionData: {},
    setSharedSession: () => { }
};

export const SharedSessionContext = createContext<SessionSharedContextProps>(initSessionSharedContextPropsState);

const useSharedSession = () => {
    return useContext(SharedSessionContext);
};

const SharedSessionProviders: FC<WithChildren> = ({ children }) => {
    const [sharedSessionData, setSharedSession] = useState<any>(initSessionSharedContextPropsState.sharedSessionData);

    return (
        <SharedSessionContext.Provider value={{ sharedSessionData, setSharedSession }}>
            {children}
        </SharedSessionContext.Provider>
    );
};

export { SharedSessionProviders, useSharedSession };
