import axios from 'axios'
import * as GameQuestionActions from './actions';
import * as Sentry from '@sentry/react';
import { useCurrentGameSession } from '../game_session/selectors';
export const GAME_QUESTION = `/gameQuestions/getGameQuestion/:id`;
export const GAME_QUESTION_PREVIEW = `/getGameQuestionPreview`;
export const GAME_PREVIEW = `/getGamePreview`
export const CREATE_GAME_QUESTION = `/gameQuestions/createGameQuestion`;
export const UPDATE_QUESTION_ORDER = `/gameQuestions/updateQuestionOrder`;
export const DELETE_GAME_QUESTION = `/gameQuestions/deleteGameQuestion`;

function shuffleArray(array) {
    if (array.length === 1) {
        return array;
    }
    let shuffledArray = array.slice();
    const originalPositions = array.map((_, index) => index);
    let shuffledPositions;
    do {
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        shuffledPositions = shuffledArray.map(item => array.indexOf(item));
    } while (originalPositions.some((pos, index) => pos === shuffledPositions[index]));

    return shuffledArray;
}

// Server should return AuthModel
export const getGameQuestion = (id, type) => async (dispatch) => {
    try {
        const url = GAME_QUESTION.replace(':id', id);
        const gameQuestion = await axios.get(url);
        if (type === 'modern') {
            const newData = shuffleArray(gameQuestion?.data);
            await dispatch(GameQuestionActions.listGameQuestion(newData));
            return newData;
        } else {
            await dispatch(GameQuestionActions.listGameQuestion(gameQuestion?.data));
            return gameQuestion?.data;
        }
    } catch (error) {
        // Capture and report the error to Sentry
        Sentry.captureException(`001 getGameQuestion ${error}. gameId: ${id}`);
        // Rethrow the error to propagate it further
        throw error;
    }
}

export const getGameQuestions = async (id, type) => {
    try {
        const url = GAME_QUESTION.replace(':id', id);
        const gameQuestion = await axios.get(url);
        if (type === 'modern') {
            const newData = shuffleArray(gameQuestion?.data);
            return newData;
        } else {
            return gameQuestion?.data
        }
    } catch (error) {
        // Capture and report the error to Sentry
        Sentry.captureException(`002(preview) getGameQuestion ${error}. gameId: ${id}`);
        // Rethrow the error to propagate it further
        throw error;
    }
}
export const getGameQuestionsWithoutAuth = async (id, type) => {
    try {
        // const url = GAME_QUESTION_PREVIEW.replace(':id', id);
        const gameQuestion = await axios.get(`${GAME_QUESTION_PREVIEW}/${id}`);
        if (type === 'modern') {
            const newData = shuffleArray(gameQuestion?.data);
            return newData;
        } else {
            return gameQuestion?.data
        }
    } catch (error) {
        // Capture and report the error to Sentry with a custom message
        Sentry.captureException(`003 getGameQuestionPreview ${error}. gameId: ${id}`);
        // Rethrow the error to propagate it further
        throw error;
    }
}

export const getGamePreviewWithoutAuth = async (id) => {
    try {
        const gameQuestion = await axios.get(`${GAME_PREVIEW}/${id}`);
        return gameQuestion?.data[0]
    } catch (error) {
        // Capture and report the error to Sentry with a custom message
        Sentry.captureException(`004 getGamePreview ${error}. gameId: ${id}`);
        // Rethrow the error to propagate it further
        throw error;
    }
}

export const CreateGameQuestion = (data) => async (dispatch) => {
    try {
        const gameQuestionData = await axios.post(CREATE_GAME_QUESTION, data);
        if (data?.id) {
            await dispatch(GameQuestionActions.updateGameQuestionSuccess(gameQuestionData?.data[0]));
        } else {
            await dispatch(GameQuestionActions.createGameQuestionSuccess(gameQuestionData?.data[0]));
        }
        return gameQuestionData?.data[0]
    } catch (error) {
        // Capture and report the error to Sentry with a custom message
        Sentry.captureException(`005 createGameQuestion ${error}. Data: ${JSON.stringify(data)}`);
        // Rethrow the error to propagate it further
        throw error;
    }
}

// Update Question Order

export const UpdateQuestionOrder = async (data) => {
    try {
        const updateQuestionData = await axios.post(UPDATE_QUESTION_ORDER, data);
        // await dispatch(GameQuestionActions.updateGameQuestionSuccess(updateQuestionData?.data[0]));
        return updateQuestionData?.data
    } catch (error) {
        // Capture and report the error to Sentry with a custom message
        Sentry.captureException(`006 updateQuestionOrder Error: ${error}. Data: ${JSON.stringify(data)}`);
        // Rethrow the error to propagate it further
        throw error;
    }
}


export const deleteGameQuestion = (id) => async (dispatch) => {
    try {
        const deleteQuestion = await axios.delete(`${DELETE_GAME_QUESTION}/${id}`);
        await dispatch(GameQuestionActions.deleteGameQuestionSuccess(id));
        return deleteQuestion
    } catch (error) {
        // Capture and report the error to Sentry with a custom message
        Sentry.captureException(`007 deleteGameQuestion Error: ${error}. ID: ${id}`);
        // Rethrow the error to propagate it further
        throw error;
    }
}