import { useEffect, useMemo, useState } from 'react';
import AddQuestion from './sections/AddQuestion';
import AddSlide from './sections/AddSlide';
import FlashCard from './sections/FlashCard';
import Brainstorm from './sections/Brainstorm';
import DropPinSection from './sections/DropPin';
import OpenEnded from './sections/OpenEnded';
import Puzzle from './sections/Puzzle';
import QuizSection from './sections/Quiz';
import TrueFalse from './sections/TrueFalse';
import TypeAnswer from './sections/TypeAnswer';
import WordCloud from './sections/WordCloud';
import { useActionValues, useActionValues1, useActionValues2 } from './store/TabChange/selectors';
import './utils/assets/css/animation.css';
import './utils/assets/css/index.css';
// import './utils/assets/css/homepage.css';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useAllGames } from '@store/GameCreate/selectors';
import { getGames } from '@store/GameCreate/thunks';
// import { useGameQuestions } from '@store/GameQuestions/selectors';
import { getGameQuestion } from '@store/GameQuestions/thunks';
import { ModalAI } from '@metronic_components/ModalAI';
import { getGameGalleryList, getGameImageCategoryList } from './store/TutorialVideo/thunks';
import { getParentUrl } from './utils/methods';

const Index = () => {

  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate();
  // const allGamesList = useAllGames();
  // const GameQuestions = useGameQuestions()
  const currentURL = location.pathname;
  const currentGameId = currentURL.split('/').pop();
  const { state } = location
  const type = state?.blank_ai ?? false;
  const content = useMemo(() => location.state?.content, [location])
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardTime, setSelectedCardTime] = useState('');
  const [selectedCardOption, setSelectedCardOption] = useState('');
  const [showSection, setShowSection] = useState(false);
  const [openAIModal, setOpenAIModal] = useState(true);
  const [activity, setactivity] = useState(null);
  const main = useActionValues();
  const main1 = useActionValues1();
  const main2 = useActionValues2();

  useEffect(() => {
    dispatch(getGameQuestion(currentGameId));
    dispatch(getGames())
    dispatch(getGameGalleryList())
    let domain = null
    if (document.referrer) {
      domain = new URL(document.referrer).hostname
    }
    dispatch(getGameImageCategoryList(domain));
  }, [])

  useEffect(() => {
    setShowSection(main?.showSection);
    setSelectedCard(main?.selectedCard);
    setSelectedCardTime(main1?.selectedTime);
    setSelectedCardOption(main1?.selectedOption);
  }, [main, main1])

  useEffect(() => {
    setactivity(main2?.activity)
  }, [main2])

  useEffect(() => {
    // Clear the state after the component has mounted
    if (openAIModal === false) {
      navigate({ ...location, state: null });
    }
  }, [openAIModal]);

  return (
    <>
      <div className='playGameMid'>
        <div className='playGameMidBg '>
          <div className='gmScenarioUpr'>
            <div className='gmScenario'>
              {type === true && <ModalAI gameOpen={openAIModal} setOpenAIModal={setOpenAIModal} defaultPage={5} comesFrom={"ai"} content={content} />}
            </div>
          </div>
          {showSection && selectedCard.label !== "Add question" && (
            <div>
              <div className='card-body p-0'>

                {showSection && selectedCard.label === "Quiz" && (
                  <QuizSection CardTime={selectedCardTime} CardOption={selectedCardOption} questionType={'quiz'} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "True or false" && (
                  <TrueFalse CardTime={selectedCardTime} CardOption={selectedCardOption} questionType={'true_or_false'} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "Type answer" && (
                  <TypeAnswer CardTime={selectedCardTime} CardOption={selectedCardOption} questionType={'type_answer'} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "Drop pin" && (
                  <DropPinSection CardTime={selectedCardTime} CardOption={selectedCardOption} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "Word cloud" && (
                  <WordCloud CardTime={selectedCardTime} CardOption={selectedCardOption} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "Open-ended" && (
                  <OpenEnded CardTime={selectedCardTime} CardOption={selectedCardOption} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "Brainstorm" && (
                  <Brainstorm CardTime={selectedCardTime} CardOption={selectedCardOption} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "Poll" && (
                  <QuizSection CardTime={selectedCardTime} CardOption={selectedCardOption} questionType={'poll'} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "Puzzle" && (
                  <Puzzle CardTime={selectedCardTime} CardOption={selectedCardOption} questionType={'puzzle'} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}

                {showSection && selectedCard.label === "Informational Page" && (
                  <AddSlide questionType={'informational_page'} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}
                {showSection && selectedCard.label === "Flash-Card" && (
                  <FlashCard questionType={'flash_card'} isEditable={selectedCard?.isEditable} edit={selectedCard?.questionData} activity={activity} />
                )}
              </div>
            </div>
          )}

          {showSection && selectedCard.label === "Add question" && (
            <AddQuestion />
          )}
        </div>
      </div>
      <div>
      </div>

    </>
  )
}
Index.propTypes = {}
export default Index;
