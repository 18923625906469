/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import QuestionDrawer from '../../../../app/modules/apps/IPlayGame/components/QuestionDrawer'
import { useDrawarValue } from '../../../../app/modules/apps/IPlayGame/store/drawarOpen/selectors'
import { drawarOpen } from '../../../../app/modules/apps/IPlayGame/store/drawarOpen/thunks'
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG } from '../../../helpers'
import { getAppBackendUrl } from '@app/modules/apps/IPlayGame/utils/methods'
const API_URL = getAppBackendUrl()

const Topbar = () => {
  const dispatch = useDispatch()
  const value = useDrawarValue()
  const { auth, currentUser, setCurrentUser } = useAuth()
  const [allCompany, setAllCompany] = useState([])
  const [company_id, setCompany_id] = useState('')
  const [open, setOpen] = useState(false);
  const [mobOpen, setMobOpen] = useState(false);

  // useEffect(() => {
  //   // setOpen(true);
  //   let data = {
  //     type:true
  //   }
  //   dispatch(drawarOpen(data))
  //   },[])
  var token = auth?.api_token
  const navigate = useNavigate()

  const fetchActivityNames = async () => {
    try {
      fetch(`${API_URL}/Company/GetCompanyByUserId/${currentUser?.user_id}`, {
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      })
        .then((response) => response.json())
        .then((data) => setAllCompany(data?.data))
        .catch((error) => console.error(error))
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const MoveAnotherCompany = (company_id) => {
    if (company_id) {
      fetch(`${API_URL}/Company/ChangeCompanyByUser/${currentUser?.user_id}/${company_id}`, {
        headers: {
          'Content-Type': 'application/json',
          token: token,
        }
      })
        .then((response) => response.json())
        .then((data) => {
          data['login'] = 'viasuperadmin';
          data['window'] = 3;
          data['data']['loggedinfromSA'] = true;
          localStorage.setItem('tmpuser', JSON.stringify(data));
          localStorage.setItem('tmpuser-ls', JSON.stringify(data));
          setCurrentUser(data?.data)
          navigate('/crafted/organization/settings')

        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  }

  useEffect(() => {
    MoveAnotherCompany(company_id)
    fetchActivityNames()
  }, [currentUser?.user_id, company_id])


  return (
    <div className='d-flex flex-shrink-0'>
      {/* begin::Invite user */}
      {/* <div className='d-flex ms-3'>
      <a
        href='#'
        className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_invite_friends'
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr075.svg'
          className='svg-icon-2 svg-icon-primary me-0 me-md-2'
        />
        <span className='d-none d-md-inline'>New Member</span>
      </a>
    </div> */}
      {/* end::Invite user */}

      {/* begin::Create app */}
      {/* <div className='d-flex ms-3'>
      <a
        href='#'
        className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
        id='kt_toolbar_primary_button'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_create_app'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen005.svg'
          className='svg-icon-2 svg-icon-primary me-0 me-md-2'
        />
        <span className='d-none d-md-inline'>New App</span>
      </a>
    </div> */}
      {/* end::Create app */}

      {/* begin::Theme mode */}
      {/* <div className='d-flex align-items-center  ms-3'>
        <ThemeModeSwitcher toggleBtnClass=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />
      </div> */}
      {/* end::Theme mode */}

      {/* CHAT */}
      <div className='d-flex align-items-center ms-3'>
        {/* begin::Menu wrapper */}
        {/* <div
          className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white'
          id='kt_drawer_chat_toggle'
        >
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
          <span className='pulse-ring' />
        </div> */}
        <div
          className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white mobHide'
          // id='kt_drawer_chat_toggle'
          onClick={() => {
            let data = {
              type: value?.type ? false : true
            }
            dispatch(drawarOpen(data))
          }  // setOpen(true)
          }
        >
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
          <span className='pulse-ring' />
        </div>
        <button onClick={() => setMobOpen(true)}
          className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white mobDeskss sideMenuBtnMobile'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
          <span className='pulse-ring' />
        </button>
        {/* end::Menu wrapper */}
      </div>
      {/* {currentUser?.is_superadmin !== 1 &&
        <div className='d-flex align-items-center ms-3'>
          <button
            className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white sideMenuBtnMobile'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <i className='fa-solid fa-user'></i>
          </button>


          <div
            className='usrInfoDetail menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3 alldashdrop'
            data-kt-menu='true'
          >
            <div className='menu-item px-3 cursor-pointer' >
              <div
                className={`menu-content fw-bold pb-2 px-3 fs-6 `}
              >
                Company Name
              </div>
            </div>

            {allCompany && allCompany.map((data) =>
              <div className='menu-item px-3' key={data.company_id}>
                <a className={clsx('menu-link px-3 dreopdownIcon', { active: data.company_id === currentUser?.company_id })} onClick={() => setCompany_id(data.company_id)} >
                  <span className='px-4'>{data.company_name}</span>
                </a>
              </div>

            )}


          </div>
        </div>} */}
      <div className="quesSideDrawer">
        <QuestionDrawer isOpen={mobOpen} onClose={() => {
          // setWidth(0);
          setMobOpen(false);
        }} title={'Game Preview'} />
      </div>
    </div>
  )
}

export { Topbar }
