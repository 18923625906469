import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StudyFlashCard from '../OneStudyPlay/StudyFlashCard';
import StudyQuiz from '../OneStudyPlay/StudyQuiz';
import { fetchGameById } from '../store/GameCreate/thunks';
import { getGameQuestionsWithoutAuth } from '../store/GameQuestions/thunks';
import { Session_auth } from '@app/modules/auth/components/1Game_Session_auth';
import { FullLoader } from '../components/FullLoader';

function IStudyGame() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const game_id = queryParams.get('game_id');
    const nosotros = queryParams.get('type');
    const [loader, setLoader] = useState(false);
    const [GameQuestions, setGameQuestions] = useState([]);
    const [oneStudyType, setOneStudyType] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const data = await getGameQuestionsWithoutAuth(game_id);
            if (data) {
                setGameQuestions(data)
                const result = await fetchGameById(game_id);
                if (result && result.game_id) {
                    setOneStudyType(result?.study_metadata?.type)
                }
            }
            setLoader(false);
        };

        fetchData();
    }, [game_id]);

    return (
        <>
            {loader && <FullLoader />}
            {oneStudyType === "quiz" && <StudyQuiz GameQuestions={GameQuestions} nosotros={nosotros} />}
            {oneStudyType === "flash_card" && <StudyFlashCard GameQuestions={GameQuestions} nosotros={nosotros} />}

        </>
    )
}

export default IStudyGame;