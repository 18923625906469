import { Button } from 'antd';
import { useCallback } from 'react';

import CountdownStopwatch from '../../components/CountDownFlip';

function WantingScreentLoader({ functionName, onExitGame, exitLoader, comesFrom, nosotros, isClose }) {
  const timess = new Date();
  timess.setSeconds(timess.getSeconds() + 6);
  const EButton = useCallback(() => {
    return (<div className='teacherVwNextBtn teacherVwBkBtn'><Button loading={exitLoader} onClick={() => {
      if (onExitGame) {
        onExitGame()
      }
    }}>Exit!</Button></div>)
  }, [exitLoader])

  const onBackClick = () => {
    if (window != window.top) {
        window.parent.postMessage({ action: 'close_one_game' }, '*');
    }
}
const ExitButton = useCallback(() => {
    return (<div className='teacherVwNextBtn teacherVwBkBtn' ><Button
        onClick={() => {
            if (nosotros) {
                onBackClick();
            }
            else {
                isClose(false);
            }
        }}>Exit!</Button></div>)
}, []);
  return (
    <div className='row g-0'>
      <div className='col-11 col-sm-11 col-md-10 col-lg-5 stdntCardUpr readyNxtUpr'>
        <div className='readyNxt'>


          <p style={{color: 'white',textShadow: 'rgb(0, 0, 0) 2px 0px 5px'}}>Let's get ready for the next one!</p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 40 }}>
            <>
              <CountdownStopwatch expiryTimestamp={timess} />

            </>
          </div>
          <div className='row g-0 correctNxtBtnUpr'>
            <div className='col-12 nextBkBtnUpr'>
              <>
              {(functionName?.includes("creator_view") && comesFrom != "preview") &&
                <EButton />
              }
              {comesFrom === "preview" && <ExitButton/>}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WantingScreentLoader