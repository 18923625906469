import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Button } from 'antd'
import { get, map } from 'lodash'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { IGameLogos, studyGameLogo } from '../utils/assets/images/index'
import './study-style.css'
import { LeftOutlined } from '@ant-design/icons';
import { RightOutlined } from '@ant-design/icons';

const StudyFlashCard = ({ GameQuestions, nosotros }) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [questions, setQuestions] = useState(GameQuestions); // Separate state for questions
  const [currentIndex, setCurrentIndex] = useState(0);
  const [speechWork,setSpeechWork] = useState(false)


  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (currentIndex < questions.length - 1) { // Check if currentIndex is within bounds
        window.speechSynthesis.cancel();
        setSpeechWork(false);
        swiperRef.current.swiper.slideNext();
        setIsFlipped(false);
        setCurrentIndex(prevIndex => prevIndex + 1); // Use functional form of setState
      }
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (currentIndex > 0) { // Check if currentIndex is within bounds
        window.speechSynthesis.cancel();
        setSpeechWork(false);
        swiperRef.current.swiper.slidePrev();
        setIsFlipped(false);
        setCurrentIndex(prevIndex => prevIndex - 1); // Use functional form of setState
      }
    }
  };

  const handleShuffle = (e) => {
    if (isFlipped) {
      handleFlip()
    }
    e.preventDefault();
    // Shuffling questions from currentIndex to end
    const questionsToShuffle = questions.slice(currentIndex); // Get questions from currentIndex onwards
    const shuffledQuestions = shuffleArray(questionsToShuffle); // Shuffle questions from currentIndex onwards
    const shuffledGameQuestions = [
      ...questions.slice(0, currentIndex), // Include questions before current index
      ...shuffledQuestions
    ]; // Combine shuffled questions with the remaining questions
    // Setting shuffled questions
    setQuestions(shuffledGameQuestions);
    window.speechSynthesis.cancel();
  }

  function shuffleArray(array) {
    if (array.length === 1) {
      return array; // If there's only one element in the array, return it as is
    }
    let shuffledArray = array.slice(); // Create a copy of the array to avoid mutating the original
    const originalPositions = array.map((_, index) => index); // Store the original positions of elements

    let shuffledPositions;

    do {
      // Fisher-Yates shuffle algorithm
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }

      // Get the positions of elements after shuffling
      shuffledPositions = shuffledArray.map(item => array.indexOf(item));
    } while (originalPositions.some((pos, index) => pos === shuffledPositions[index])); // Reshuffle if any element retains its original position

    return shuffledArray;
  }

  const handleFlip = () => {
    setIsFlipped(!isFlipped)
    window.speechSynthesis.cancel();
    setSpeechWork(false);
  }

  const onBackClick = () => {
    if (window !== window.top) {
      window.parent.postMessage({ action: 'close_one_game' }, '*');
    }
  }

  const speakTitle = (title) => {
    const speech = new SpeechSynthesisUtterance(title);
    speech.lang = 'es-ES';
    speech.addEventListener('start', () => {
      setSpeechWork(true)
    });

    speech.addEventListener('end', () => {
      setSpeechWork(false)
    });
    window.speechSynthesis.speak(speech);
  };

  return (
    <div>
      <div className='studyTestDesignTpc'>
        <div className='arrowLogoBtn'>
          <div
            className='createBckBtn'
            onClick={() => {
              window.speechSynthesis.cancel();
              if (nosotros) {
                onBackClick();
              } else {
                navigate(`/apps/home/`);
              }
            }}>
            <span className='arrowBckBtn'>
              <ArrowBackIosIcon />
            </span>
            Study Flashcards
          </div>
          <div className='oneGmPopup disabledCursor'>
            <Button className='gmPopupBtn'>
              <img src={studyGameLogo} alt='' />
            </Button>
          </div>
        </div>

        <div className='writeTpcEbk writeTpcEbkSection vamosSection quizTopVms'>
          <p>!Vamos a practicar! </p>
        </div>
        <Swiper
          effect={'flip'}
          grabCursor={true}
          pagination={false}
          navigation={false}
          simulateTouch={false}
          // modules={[EffectFlip, Pagination, Navigation]}
          className='mySwiper'
          ref={swiperRef}
        >
          <>
            {map(questions, (item, index) => {
              const question = item?.question || {}
              const answer = item?.answers || {}
              return (
                <SwiperSlide>

                  <div className={`flshCardSection studyFlashCard ${isFlipped ? 'flipped' : ''}`}>
                    <div className='flashCardInner'>
                      <div className='stdyCardWrap flashCardFront'>
                        <div className='stdyCardHad'>
                          <span>{index + 1}</span>
                          <label>PREGUNTA</label>
                        </div>
                        <div className='stdyCardTxt'>
                          <p>{question.title}</p>
                        </div>
                        {/* <div>
                          <div className='quesSelectTxtAll mainslider'>
                            <div className='fleshCardBtnSec'>
                              <Row gutter={20}>
                                {map(answer?.options, (obj, index) => {
                                  return (
                                    <Col className='gutter-row' lg={12} xs={24} sm={12} md={12}>
                                      <div className='FlshCrdOptionSection'>
                                        <label>{obj}</label>
                                      </div>
                                    </Col>
                                  )
                                })}
                              </Row>
                            </div>
                          </div>
                        </div> */}
                        <div className='flashcard-Image-container'>
                          {question?.imageUrl && (
                            <img
                              className='AQimage studyAQImg'
                              src={get(question, 'imageUrl', '')}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                      <div className='stdyCardWrap flashCardBack'>
                        <div className='stdyCardHad flashCardHad'>
                          <span>{index + 1}</span>
                          <label>RESPUESTA</label>
                        </div>
                        <div className='stdyCardTxt'>
                          <p>{get(answer, 'answer', '')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </>
        </Swiper>
        <div className='slideCardNavUpr'>
          <div className='slideCarShfl'>
            <img src={require('../utils/assets/images/mic.png')} alt='study' onClick={() => speakTitle(isFlipped ? questions[currentIndex]?.answers?.answer : questions[currentIndex]?.question?.title)} style={{ cursor: 'pointer', filter: `${speechWork ? 'drop-shadow(16px 16px 20px red) invert(75%)' : 'contrast(0.5)'} ` }} />
            <button onClick={(e) => handleShuffle(e)}>Shuffle</button>
          </div>
          <div className='slideCardNav'>
            <button className={`slideNavbtn slideNavPre ${currentIndex === 0 ? 'btnDisabled' : ''}`}
              disabled={currentIndex === 0}
              onClick={() => {
                handlePrev()
              }}
            >
              {/* <img src={require('../utils/assets/images/arow.png')} alt='study1' /> */}
              <LeftOutlined className='flashCardArrowIcon' />
            </button>
            <label
              onClick={() => {
                handleFlip()
              }}
            >
              Flip
            </label>
            <button className={`slideNavbtn slideNavNxt ${currentIndex === questions.length - 1 ? 'btnDisabled' : ''}`}
              disabled={currentIndex === questions.length - 1}
              onClick={() => {
                handleNext()
              }}
            >
              {/* <img src={require('../utils/assets/images/arw2.png')} alt='study' /> */}
              <RightOutlined className='flashCardArrowIcon'/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudyFlashCard;
