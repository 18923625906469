//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { orderBy } from "lodash"
import { useMemo } from 'react';

const selectore = (state) => {
    return state.gameQuestion
};
export const useGameQuestions = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    const resultData = useMemo(() => orderBy(selectedData, "order_num", "asc"), [selectedData])
    return selectedData;
}
