//  ----------------------------------------------------------------------------
//  Color constants
//  ----------------------------------------------------------------------------
//
//  App color palette
//
//  -- Description
//  Source of truth for app color values
//

export default {
    black: '#1b1e23',
    black100: '#35383d',
    black200: '#4e5156',
    black300: '#686b70',
    black400: '#818489',
    black500: '#9a9da2',
    black600: '#b4b7bc',
    black700: '#ced1d6',
    black800: '#e7eaef',
    black900: '#F4F7FC',
    gray100:"rgb(74,79,85)",
    green: '#03989e',
    green100: '#03989e',
    green200: '#03989e',
    green300: '#03989e',
    green400: '#03989e',
    green500: '#03989e',
    green600: '#03989e',
    green700: '#03989e',
    green800: '#03989e',
    green900: '#03989e',
    green1000: '#03989e',
    orange100:"#e67e22",
    orange200:"#e17055",
    white: '#ffffff',
    radish100: 'rgba(229,106,103)',
    radish200: 'rgba(219,97,73)',
    radish300: 'rgba(222,106,61)',
    blueish100: 'rgba(77,149,236)',
    blueish200: 'rgba(64,108,216)',
    blueish300: 'rgba(58,80,228)',
    blueish400: 'rgba(89,112,246)',
    blueishdeep: 'rgb(35,53,89)',
    pink100: 'rgba(212,64,223)',
    pink200: 'rgba(182,62,229)',
    pink300: 'rgba(161,61,232)',
    sky100: 'rgba(110,211,166)',
    sky200: 'rgba(99,206,185)',
    sky300: 'rgba(102,202,210)',
    sky400: 'rgba(140,226,230)',
    indigo100:"#3e3160",
    indigo200:"#5f4c82",
    indigo300:"rgb(74,61,108)",
    indigo400:"#c5a1cf",
    indigo500:"rgb(52,41,95)",
    indigo600:"rgb(87,70,130)",
    indigodeep:"rgba(55,58,78)",
    lightseagreen100:"#03989e",
    lightseagreen200:"rgb(67,150,156)",
    yellow:"rgba(224,157,64)",
    blue:"rgb(35,53,89)",
    red:"rgba(255,0,0,0.9)",
    redishpink:"rgb(219,0,100)",
    greentrue:"#0ccf0a",
    lightgreen:"rgb(218 239 203)",
    tabActive: "#002d75",
    bluelight:"rgb(72,82,175)",
    PinkExam: "rgba(255,44,169,1)",
    OrangeExam: "rgba(245,134,23,1)",
    WheelEditColor1: "rgba(221,62,55)",
    WheelEditColor2: "rgba(223,91,66,1)",
    WheelEditColor3: "rgba(228,134,79,1)"
};
