//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


const selectore = (state) => {
    return state.createGames
};

export const useAllGames = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}