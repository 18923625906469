import { ActionTypes } from '../constants';

const gamesInitial = { 
    session_id:'',
    answers:[]
 };

export const currentSessionMySubmission = (state = gamesInitial, action) => {
    switch (action.type) {
        case ActionTypes.my_submission.set_my_submission:
            return action.payload;
        case ActionTypes.my_submission.update_my_submission:
            return action.payload;
        case ActionTypes.my_submission.clear_my_submission:
            return gamesInitial;
        default:
            return state;
    }
}
