import { LoadingOutlined } from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import { Button, Spin } from 'antd';
import { deleteObject, ref } from 'firebase/storage';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../auth';
import { ImageModal } from '../components/ImageModal';
import { storage } from '../firebaseConfig';
import { CreateGameQuestion } from '../store/GameQuestions/thunks';
import Acent from '../components/Acent';

const Index = ({ isEditable, edit }) => {
    const [uploadedImage, setUploadedImage] = useState('');
    const [showModal, setShowModal] = useState(false);
    // const [isAnsType, setIsAnsType] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth()
    const dispatch = useDispatch();
    const location = useLocation()
    const currentURL = location.pathname;
    const currentGameId = currentURL.split('/').pop();
    const [removeImageLoader, setRemoveImageLoader] = useState(false);
    const textareaRef = useRef(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);



    useEffect(() => {
        if (isEditable) {
            const Question = get(edit, 'question', '') ?? {};
            setInputValue(Question?.title);
            setUploadedImage(Question?.imageUrl)
        } else {
            setInputValue("");
            setUploadedImage('')

        }
    }, [isEditable, edit])

    const ansTypeFocus = () => {
        // setIsAnsType(true);
    };
    const ansTypeBlur = () => {
        // setIsAnsType(false);
    };

    const onHandleQuestion = async (type) => {
        let answers = {
            options: [],
            answer: "",
        }
        if (isEditable) {
            const questionEdit = {
                title: inputValue,
                imageUrl: `${uploadedImage}`,
            }
            const UpdateData = {
                ...edit,
                org_id: get(currentUser, 'company_id', ''),
                created_by: get(currentUser, 'user_id', ''),
                type,
                question: questionEdit,
                answers,
                game_id: currentGameId
            }
            await dispatch(CreateGameQuestion(UpdateData))
        } else {
            const question = {
                title: inputValue,
                imageUrl: `${uploadedImage ? uploadedImage : ''}`,
            }
            const data = {
                org_id: get(currentUser, 'company_id', ''),
                created_by: get(currentUser, 'user_id', ''),
                type,
                question,
                answers,
                game_id: currentGameId
            }
            await dispatch(CreateGameQuestion(data))
            setUploadedImage('');
            setInputValue('');
        }
        setLoading(false);
    }

    const handleRemoveImage = (imageUrl) => {
        if (imageUrl) {
            setRemoveImageLoader(true)
            // const storageRef = ref(storage, imageUrl);
            // deleteObject(storageRef).then(() =>
            try {
                if (isEditable) {
                    const question = {
                        title: inputValue,
                        imageUrl: '',
                    }
                    const UpdateData = {
                        ...edit,
                        question,
                    }
                    dispatch(CreateGameQuestion(UpdateData))
                    setRemoveImageLoader(false)
                    setUploadedImage('')
                }
                setUploadedImage('')
            }
            catch (error) {
                console.log('error', error);
                setRemoveImageLoader(false)
            }
        }
    }

    const onImageUpload = (image) => {
        setUploadedImage(image)
        if (isEditable) {
            const question = {
                title: inputValue,
                imageUrl: image,
            }
            const UpdateData = {
                ...edit,
                question,
            }
            dispatch(CreateGameQuestion(UpdateData))
        }
    }

    const SPANISH_ITEM_LOWER = ["á", "é", "í", "ó", "ú", "ü", "ñ", "¿", "¡"];
    const SPANISH_ITEM_UPPER = ["Á", "É", "Í", "Ó", "Ú", "Ü", "Ñ", "¿", "¡"];

    const insertAccent = (accent) => {
        const textarea = textareaRef.current;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;

        const newInputValue =
            inputValue.substring(0, startPos) +
            accent +
            inputValue.substring(endPos);

        // setInputValue(newInputValue);
        setInputValue(newInputValue.substring(0, 130));
        setDropdownVisible(false);


        // Move the cursor to the right after the added accent
        const newCursorPos = startPos + accent.length;

        // Set the new cursor position immediately after the added accent
        setTimeout(() => {
            textarea.setSelectionRange(newCursorPos, newCursorPos);
            textarea.focus();
        }, 0);
    };

    const handleInputChange = (e) => {
        // const value = e.target.value;
        //  if (value.length <= 130) {
        //     setInputValue(value);
        //     adjustTextareaHeight();
        //  }
        const maxLength = 130;
        const value = e.target.value;
        const trimmedValue = value.slice(0, maxLength);
        setInputValue(trimmedValue);
        adjustTextareaHeight();
    };


    useEffect(() => {
        adjustTextareaHeight();
    }, [inputValue]);


    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {

            adjustTextareaHeight();
        }
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleBlur1 = () => {
        setDropdownVisible(false);
    };

    return (
        <div className='layoutWraper'>
            <div className='layoutWrapLeft'>
                <div className='addBkgroundImg'>
                    <div className='addImgShow'>
                        <div className='uploadImgUpr'>
                            {uploadedImage ? (
                                <div className="image-container">
                                    <img src={uploadedImage} alt="Uploaded" />
                                    <div className="edit-icon">
                                        <span onClick={() => handleRemoveImage(uploadedImage)} >{removeImageLoader ? <CircularProgress size={15} style={{ color: 'teal' }} /> : null} Remove</span>
                                    </div>
                                </div>
                            ) : (
                                <img src={require('../utils/assets/images/upload2.png')} alt="" />
                            )}
                        </div>
                    </div>
                    <div className='uploadImgIcn'>
                        <label className="btn btn-icon" onClick={() => {
                            setShowModal(true)
                        }}>
                            <i className="fa-solid fa-plus fs-7 uploadImgPlus"></i>
                        </label>
                    </div>
                    <div className='insertMediaDP'>
                        <label>Find and insert media</label>
                    </div>
                </div>
                <ImageModal isOpens={showModal} isClose={setShowModal} onSubmit={(e) => {
                    onImageUpload(e)
                }} />
            </div>

            <div className='quizLayout'>
                <div className='card-body p-0'>
                    <div className='addQuesBox'>
                        <div className='playGameMidSrch my-textarea'>
                            <div style={{ position: 'relative', top: 0, left: 0, display: "flex", justifyContent: "end" }}>
                                <Acent inputRef={textareaRef} setValue={inputValue?.length < 130 ? setInputValue : null} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                            </div>
                            <textarea
                                ref={textareaRef}
                                style={{
                                    background: 'transparent',
                                    border: 0,
                                    fontSize: 20,
                                    color: 'white',
                                    resize: 'none',
                                    // height: '40px', // Set the height for 2 rows of text
                                    // overflowY: 'hidden',
                                    // Hide extra content beyond the defined height
                                    marginTop: "20",
                                    outline: 'none'

                                }}
                                type="text"
                                value={inputValue}
                                onFocus={ansTypeFocus}
                                // onBlur={ansTypeBlur}
                                onBlur={() => ansTypeBlur()}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}

                                placeholder={"Insert some text..."}
                                className="custom-textarea"
                            />
                            <div className='counter-position-addslide'
                                style={{
                                    position: 'relative',
                                    // bottom: -3,
                                    right: 0,
                                    color: 'white',
                                    fontSize: 12,
                                    float: "right"
                                }}
                            >
                                {inputValue.length}/130
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-p mt-n20 position-relative'>
                    {/* {inputValue !== '' && ( */}
                    <div className="moreAnsBtnSave mt-20"><Button disabled={inputValue.trim() === ''} onClick={() => {
                        onHandleQuestion("informational_page")
                        setLoading(true);
                    }}>   {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} /> : <>  <AiOutlinePlusSquare />{`${isEditable ? 'Update' : 'save'}`}</>}</Button></div>
                    {/* )} */}
                </div>
            </div>
        </div>

    )
}

Index.propTypes = {}

export default Index;
