//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


const selectore = (state) => {
    return state.drawarOpenClose
};

export const useDrawarValue = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}