
const initial = {};

export const tabAction = (state = initial, action) => {
    switch (action.type) {
        case 'values':
            return { ...action.payload }
        default:
            return state
    }
}

export const featuresReducer = (state = {}, action) => {

    switch (action.type) {
      case 'features':
        return { ...action.payload };
      default:
        return state;
    }
  };

export const activityReducer = (state = {}, action) => {

    switch (action.type) {
      case 'activity':
        return { ...action.payload };
      default:
        return state;
    }
  };