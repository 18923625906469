//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux'
import * as GamesActions from './actions';
import { getCurrentSessionSubmissions  } from './thunks';
// import { useState } from 'react';
// import { useAuth } from "@modules/auth"


const currentSessionSumissionSelecter = state =>state.currentSessionSumission
export const useCurrentGameSessionSubmissions = () => {
    const currentSessionSumission = useSelector(currentSessionSumissionSelecter, shallowEqual)
    const dispatch = useDispatch();
    const fetchSessionSubmissions = (session_id) =>{
        dispatch(getCurrentSessionSubmissions(session_id))
    }
    const setSessionSubmissions = (submissions) =>{
         dispatch(GamesActions.setCurrentSessionSubmissions(submissions));
    }
    return { currentSessionSumission , fetchSessionSubmissions , setSessionSubmissions };
}