import { ActionTypes } from '../constants';

const gamesInitial = {
    session_id: '',
    submissions: {

    }
};

export const currentSessionSumission = (state = gamesInitial, action) => {

    switch (action.type) {
        case ActionTypes.session_submissions.set_session_submissions:
            return action.payload;
        case ActionTypes.session_submissions.update_session_submissions:
            return action.payload;
        case ActionTypes.session_submissions.clear_session_submissions:
            return gamesInitial;
        default:
            return state;
    }
}
