import { useAuth } from '@app/modules/auth';
import { Checkbox as ACheckbox, Modal as AntModal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Modal as BootModal, Button } from 'react-bootstrap';
import {
    audiomodalurl1,
    audiomodalurl2,
    audiomodalurl3,
    audiomodalurl4,
    audiomodalurl5,
    audiomodalurl6,
    audiomodalurl7,
    audiomodalurl8,
} from '../../utils/assets/images';
const AudioModal = ({ isOpens, currentGameId, isClose, waiting, setwaiting, playing, setplaying, onSubmit = () => { } }) => {
    const fileInputRef = useRef(null);
    const { currentGameContext } = useAuth()

    // const getAllGames = useAllGames();
    // const getCurrentGame = find(getAllGames, (item) => item.game_id === currentGameId);
    const getCurrentGame = currentGameContext;
    const [audioUrl, setAudioUrl] = useState(null);
    const [isOpensConf, setIsOpensConf] = useState(false);
    const [isOpensConfAudio, setIsOpensConfAudio] = useState(false);


    useEffect(() => {

        const fullVideoUrl = getCurrentGame?.media_urls?.audio; // Adjust this based on your actual data structure
        setwaiting(getCurrentGame?.media_urls?.waiting)
        setplaying(getCurrentGame?.media_urls?.playing)

        // Extract video ID from the full video URL
        const matchedSoundItem = soundItems.find(item => item.url === fullVideoUrl);

        if (matchedSoundItem) {
            setSelectedSounds(matchedSoundItem?.url);
            setAudioUrl(matchedSoundItem?.url)
        } else {
            setSelectedSounds("")
            setAudioUrl(null);
            // setVideoLink(fullVideoUrl)
        }

    }, [isOpens])
    const handleSaveModal = () => {
        if (waiting || playing) {

            onSubmit(selectedSounds)
            setwaiting(waiting)
            setplaying(playing)
            isClose(false);
            setSelectedSounds("")

        }
        else {
            // AntModal.confirm({
            //     title: 'Confirmation',
            //     content: 'You must need to select Waiting or Playing Area to continue!',
            //     onOk: () => {

            //     },
            //     cancelButtonProps: { style: { display: 'none' } },
            // });

            // uploadToStorage(selectedImage);
            handleOpenCnfAudio();
        }
    };

    const handleCloseModal = () => {
        isClose(false);
        setSelectedSounds("")
    };
    const [selectedSounds, setSelectedSounds] = useState("");

    const handleCheckboxChange = (soundUrl) => {

        if (selectedSounds === soundUrl) {
            setSelectedSounds("");
            setAudioUrl("");

        } else {
            setSelectedSounds(soundUrl);
            setAudioUrl(soundUrl);

        }
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
        // Toggle the selected state for the corresponding sound URL
    };

    const soundItems = [
        { url: `${audiomodalurl1}`, label: 'Track 1' },
        { url: `${audiomodalurl2}`, label: 'Track 2' },
        { url: `${audiomodalurl3}`, label: 'Track 3' },
        { url: `${audiomodalurl4}`, label: 'Track 4' },
        { url: `${audiomodalurl5}`, label: 'Track 5' },
        { url: `${audiomodalurl6}`, label: 'Track 6' },
        { url: `${audiomodalurl7}`, label: 'Track 7' },
        { url: `${audiomodalurl8}`, label: 'Track 8' }
    ];



    const handleCheckboxChange1 = (e) => {
        if (e.target.checked) {
            triggerFunction();
        }
        else {
            setwaiting(false);
        }
    };

    const triggerFunction = () => {
        // const mediaUrls = getCurrentGame?.media_urls;
        // if (mediaUrls && mediaUrls.video) {

        handleOpenCnf();

        // AntModal.confirm({
        //     title: 'Confirmation',
        //     content: 'If you select audio for the waiting room, the video will be removed. Do you want to continue?',
        //     onOk: () => {
        //         // Execute your logic here if the user clicks "OK"
        //         setwaiting(true)
        //     },
        //     onCancel: () => {
        //         // Execute your logic here if the user clicks "Cancel" or "Discard"
        //         setwaiting(false)
        //     },
        //     className: 'high-z-index'
        // });
        // } else {
        //     // Execute your logic here if no video is present
        //     setwaiting(true);

        // }
    };
    const handleOpenCnf = () => {
        setIsOpensConf(true);
    }
    const handleCloseCnf = () => {
        setIsOpensConf(false);
    }
    const handleOpenCnfAudio = () => {
        setIsOpensConfAudio(true);
    }
    const handleCloseCnfAudio = () => {
        setIsOpensConfAudio(false);
    }
    return (
        <>
            <BootModal show={isOpens} className='galleryPicUpr' scrollable>
                <BootModal.Header closeButton onClick={handleCloseModal} > <BootModal.Title>Gallery Audio</BootModal.Title></BootModal.Header>
                <BootModal.Body>
                    <div className='galleryPic'>
                        {/* <label>Gallery Audio</label> */}
                        <ul>
                            {soundItems.map((soundItem, index) => (
                                <li key={index} onClick={() => handleCheckboxChange(soundItem.url)} className='audioCheckBox' style={{ position: 'relative' }}>

                                    <input
                                        className='form-check-input checkIconsAns forborderPlay'
                                        type='checkbox'
                                        style={{ position: 'absolute', left: 0, top: 0 }}
                                        checked={selectedSounds === soundItem.url}
                                        onChange={() => handleCheckboxChange(soundItem.url)}


                                    />
                                    <img src={require('../../utils/assets/images/tuneIcon.png')} alt="" title="" />
                                    <p style={{ textAlign: 'center', fontWeight: '600', fontSize: 14, marginTop: 10 }}>{soundItem.label}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* {audioUrl && ( */}
                    <div className="mb-5">
                        <audio key={audioUrl} controls style={{ width: '100%' }}>
                            <source src={`${audioUrl}`} type="audio/ogg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                    {/* )} */}

                    <div className='chkBxPlaySbmt'>
                        <div className='chkBxPlay'>
                            <ACheckbox onChange={(e) => handleCheckboxChange1(e)} className='text-dark' checked={waiting}>Waiting Room</ACheckbox>
                            <ACheckbox onChange={() => setplaying(!playing)} className='text-dark' checked={playing}>Playing Area</ACheckbox>
                        </div>
                        {((!selectedSounds && getCurrentGame?.media_urls?.audio) || (selectedSounds)) && <div className='btnPlaySbmt'>
                            <Button variant="secondary" onClick={handleSaveModal}>Submit</Button>
                        </div>}
                    </div>
                </BootModal.Body>
            </BootModal>
            <BootModal show={isOpensConf} className='galleryPicUprConf' scrollable backdrop="static"
                keyboard={false}>
                <BootModal.Header closeButton onClick={() => handleCloseCnf()}> <BootModal.Title>Confirmation</BootModal.Title></BootModal.Header>
                <BootModal.Body>
                    <p style={{ fontWeight: '600' }}>If you select audio for the waiting room, the video will be removed. Do you want to continue?</p>
                </BootModal.Body>
                <BootModal.Footer>
                    <Button className="text-dark" variant="secondary" onClick={() => {
                        handleCloseCnf()
                        setwaiting(false)
                    }}>Cancel</Button>
                    <Button className="text-dark" variant="secondary" onClick={() => {
                        handleCloseCnf()
                        setwaiting(true)
                    }}>Ok</Button>
                </BootModal.Footer>
            </BootModal>

            <BootModal show={isOpensConfAudio} className='galleryPicUprConf' scrollable backdrop="static"
                keyboard={false}>
                <BootModal.Header closeButton onClick={() => handleCloseCnfAudio()}> <BootModal.Title>Confirmation</BootModal.Title></BootModal.Header>
                <BootModal.Body>
                    <p style={{ fontWeight: '600' }}>You must need to select Waiting or Playing Area to continue!</p>
                </BootModal.Body>
                <BootModal.Footer>
                    <Button style={{display:'none'}} className="text-dark" variant="secondary" onClick={() => {
                        // handleCloseCnf()
                        // setwaiting(false)
                    }}>Cancel</Button>
                    <Button className="text-dark" variant="secondary" onClick={() => {
                        handleCloseCnfAudio()
                    }}>Ok</Button>
                </BootModal.Footer>
            </BootModal>
        </>
    )
}

export { AudioModal };

