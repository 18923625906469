import axios from 'axios';
import * as GamesActions from './actions';
import * as Sentry from '@sentry/react';
const get_game_session = `/session/get_session`;
const join_game_session = `/session/join_session`;
const list_game_session_users = `/session/game_session_users`;
const game_session_state_url = `/session/game_session_status_update`;
const start_game_sessions_url = `/session/start_game`

// Server should return AuthModel
export const getCurrentGameSession = (session_id) => async (dispatch) => {
    try {
        const currentGameSessionData = await axios.post(get_game_session, {
            session_id
        });
        if (currentGameSessionData.data && currentGameSessionData.data.hassuccessed) {
            await dispatch(GamesActions.setCurrentSession(currentGameSessionData?.data.data));
            return currentGameSessionData.data.data;
        } else {
            return null;
        }
    } catch (error) {
        Sentry.captureException(`041 get_session ${error}. ID: ${session_id}`);

        console.log(error, "error in listing current game session");
        return null;
    }
}
export const startGameSession = (user_id, game_id, game) => async (dispatch) => {
    try {
        const currentGameSessionData = await axios.post(start_game_sessions_url, {
            user_id,
            game_id
        });
        if (currentGameSessionData.data && currentGameSessionData.data.hassuccessed) {
            const dt = { ...currentGameSessionData?.data.data, game }
            await dispatch(GamesActions.setCurrentSession(dt));
            return dt;
        } else {
            return null;
        }
    } catch (error) {
        Sentry.captureException(`042 start_game ${error}. User_ID: ${user_id}, game_id: ${game_id}, game: ${game}`);
        console.log(error, "error in listing current game session");
        return null;
    }
}
export const handlejoinCurrentSession = (session_id, user_id) => async (dispatch) => {
    try {
        const currentGameSessionData = await axios.post(join_game_session, {
            session_id,
            user_id
        });
        if (currentGameSessionData.data && currentGameSessionData.data.hassuccessed) {
            return currentGameSessionData.data.data;
        } else {
            return null;
        }
    } catch (error) {
        Sentry.captureException(`043 join_session ${error}. User_ID: ${user_id}, session_id: ${session_id}`);
        console.log(error, "error in listing current game session");
        return null;
    }
}
export const handleListSessionUsers = (session_id) => async (dispatch) => {
    try {
        const currentGameSessionData = await axios.post(list_game_session_users, {
            session_id
        });
        if (currentGameSessionData.data && currentGameSessionData.data.hassuccessed) {
            await dispatch(GamesActions.setCurrentSessionUsers(currentGameSessionData?.data.data));
        } else {
            return null;
        }
    } catch (error) {
        Sentry.captureException(`044 game_session_users ${error}. session_id: ${session_id}`);
        console.log(error, "error in listing current game session");
        return null;
    }
}
export const handleUpdateCurrentGameSessionStatus = (session_id, status) => async (dispatch) => {
    try {
        const currentGameSessionData = await axios.post(game_session_state_url, {
            session_id,
            status
        });
        if (currentGameSessionData.data && currentGameSessionData.data.hassuccessed) {
            await dispatch(GamesActions.updateCurrentSession(currentGameSessionData?.data.data));
        } else {
            return null;
        }
    } catch (error) {
        Sentry.captureException(`045 game_session_status_update ${error}. session_id: ${session_id}, status: ${status}`);
        console.log(error, "error in listing current game session");
        return null;
    }
}

