//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux';

const selectore = (state) => {
    return state.tutorialVideo
};
const gallerySelectore = (state) => {
    return state.gameGallery
};

const categorySelectore = (state) => {
    return state.gameImageCategory
}

export const useTutorialVideoList = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    // const resultData = useMemo(() => orderBy(selectedData, "order_num", "asc"), [selectedData])
    return selectedData;
}

export const useGameGalleryList = () => {
    const selectedData = useSelector(gallerySelectore, shallowEqual)
    return selectedData;
}

export const useGameImageCategory = () => {
    const selectedData = useSelector(categorySelectore, shallowEqual)
    return selectedData;
}