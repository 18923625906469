import * as Sentry from '@sentry/react';

export const setActionTab = ({ selectedCard, handleCardSelect, showSection }) => async (dispatch) => {
    try {
        let data = {
            selectedCard,
            handleCardSelect,
            showSection
        }

        dispatch({ type: 'values', payload: data })
    } catch (e) {
        console.log(e)
        Sentry.captureException(`052 tabChange ${e}. selectedCard: ${selectedCard}`);

    }

}


export const setFeatures = ({ selectedTime, selectedOption }) => async (dispatch) => {
    try {
        let data = {
            selectedTime,
            selectedOption
        }
        dispatch({ type: 'features', payload: data })
    } catch (e) {
        console.log(e)
        Sentry.captureException(`053 tabChange ${e}. selectedTime: ${selectedTime}`);

    }

}

export const setActivity = ({ activity }) => async (dispatch) => {
    try {
        let data = {
            activity
        }
        dispatch({ type: 'activity', payload: data })
    } catch (e) {
        console.log(e)
        Sentry.captureException(`054 tabChange ${e}. activity: ${activity}`);

    }

}