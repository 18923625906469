import React, {useState} from 'react'
import * as Sentry from '@sentry/react';

import {useLocation} from 'react-router-dom'
import { requestPasswordsave} from '../core/_requests'
import {useAuth} from '../core/Auth'
import 'react-toastify/dist/ReactToastify.css'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
interface FormValues {
  name: string
  phone: string
  email: string
  emailPhone: string
  password: string
  confirmPassword: string,
  terms: string
}

export function PasswordReset() {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [pass, setPass] = useState<any>()
  const [cpass, setCpass] = useState<any>()
  const [message, setMessage] = useState<any>('')
  const [showPassword, setShowPassword] = useState(false)
  const location = useLocation()
  const {saveAuth, setCurrentUser, saveSuperAuth} = useAuth()
  const {state}: any = location
  const userid = state?.userid
  const [errors, setErrors] = useState<Partial<FormValues>>({})

  const validatePassword = (password: any) => {
    const uppercaseRegex = /^(?=.*[A-Z])/
    const numberRegex = /^(?=.*\d)/
    const symbolRegex = /^(?=.*[@$!%*?&#()_-])/
    const lengthRegex = /^.{8,}$/

    const errors = []
    if (password) {
      if (!uppercaseRegex.test(password)) {
        errors.push('Password must contain at least one uppercase letter')
      }

      if (!numberRegex.test(password)) {
        errors.push('Password must contain at least one number')
      }

      if (!symbolRegex.test(password)) {
        errors.push('Password must contain at least one symbol')
      }

      if (!lengthRegex.test(password)) {
        errors.push('Password must be at least 8 characters long')
      }
    }
    else {
      errors.push('Password can not be blank');
    }

    return {
      isValid: errors.length === 0,
      errorMessages: errors,
    }
  }
  const resetPassWord = async (e: any) => {
    e.preventDefault()
    try {
      if (pass?.length > 0 && cpass?.length > 0) {


        const validationResult = validatePassword(pass)
        if(!validationResult.isValid){
          setMessage(validationResult.errorMessages[0])
          return
        }

        if (pass === cpass) {
          let userId = userid ? userid : currentUser?.user_id
          let data = {
            userid: userId,
            password: pass,
          }
          setLoading(true)
          var data1 :any = await requestPasswordsave(data)  
          
          if(data1?.data?.data?.is_superadmin){
            data1['login'] = 'supeadmin'
            data1['window'] = 2
            saveSuperAuth(data1?.data)
            data1.loggedinCompany = false;
            setCurrentUser(data1?.data?.data)
          }
          else{
            data1['login'] = 'direct'
            data1['window'] = 1
            saveAuth(data1?.data)
            setCurrentUser(data1?.data?.data)
          }
          // saveAuth(data)
          // setCurrentUser( user?.data)
          setLoading(false)
          // if(currentUser )
          // navigate('/organisation', {state: { moveOrg : true}})
        } else {
          setMessage('Password and confirm password should be same')
        }
      } else {
        setMessage('Please enter password')
      }
      //}
    } catch (e) {
      Sentry.captureException(`059 Error during Firebase sign-in ${e}.`);
      
      setLoading(false)
    }
  }
  return (
    <>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'
              noValidate
              id='kt_login_password_reset_form'
              onSubmit={resetPassWord}
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Set Password?</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-400 fw-bold fs-4'>Enter your password .</div>
                {/* end::Link */}
              </div>
              {message && (
                <div className='alert alert-danger alert-dismissible text-center mt-10' role='alert'>
                  {message}
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='alert'
                    aria-label='Close'
                  ></button>
                </div>
              )}
              {/* begin::Title */}
              {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Sorry, looks like there are some errors detected, please try again.
                  </div>
                </div>
              )}
              {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                  <div className='text-info'>Sent password reset. Please check your email</div>
                </div>
              )}

              <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Enter Password'
                  autoComplete='off'
                  className='form-control bg-transparent'
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Enter Confirm Password'
                  autoComplete='off'
                  className='form-control bg-transparent'
                  onChange={(e) => setCpass(e.target.value)}
                />
              </div>
              <div className='form-group form-check text-left mb-6'>
                <input
                  type='checkbox'
                  name='remember'
                  className='form-check-input'
                  id='remember_me'
                  onClick={() => setShowPassword(!showPassword)}
                />
                <label className='form-label fw-bolder text-gray-500 fs-6'>Show Password</label>
              </div>
              {/* for='remember_me' */}
              {/* end::Form group */}
              {/* begin::Form group */}
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder me-4'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>

              {/* end::Form group */}
              <div></div>
            </form>
          </div>
        </div>
        <div className='w-lg-500px d-flex flex-stack px-10 mx-auto'>
          <div className='me-10'>
            <button
              className='btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-start'
              data-kt-menu-offset='0px, 0px'
            >
              <img
                data-kt-element='current-lang-flag'
                className='w-20px h-20px rounded me-3'
                src={toAbsoluteUrl('/media/auth/united-states.svg')}
                alt=''
              />
              <span data-kt-element='current-lang-name' className='me-1'>
                English
              </span>
              <i className='ki-duotone ki-down fs-5 text-muted rotate-180 m-0'></i>
            </button>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7'
              data-kt-menu='true'
              id='kt_auth_lang_menu'
            >
              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='English'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/united-states.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>English</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='Spanish'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/spain.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>Spanish</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='German'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/germany.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>German</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='Japanese'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/japan.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>Japanese</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='French'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/france.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>French</span>
                </a>
              </div>
            </div>
          </div>

          <div className='d-flex fw-semibold text-primary fs-base gap-5'>
            <a href='../../demo1/dist/pages/team.html' target='_blank'>
              Terms
            </a>
            <a href='../../demo1/dist/pages/pricing/column.html' target='_blank'>
              Plans
            </a>
            <a href='../../demo1/dist/pages/contact.html' target='_blank'>
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
