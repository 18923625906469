import { Button, Dropdown, Menu } from 'antd';
import { useState } from 'react';
import styled from "styled-components";
// import color from '';
// import { SPANISH_ITEM_LOWER, SPANISH_ITEM_UPPER } from "utils/assets/constants/index.js";
import color from '../../utils/assets/constent/color';
import { SPANISH_ITEM_LOWER, SPANISH_ITEM_UPPER } from '../../utils/assets/constent';
const Wrapper = styled.div`
    position: relative;
`;
export default function Acent({ inputRef, setValue, style = {} }) {
    const [upperMenuVisible, setUpperMenuVisible] = useState(false);
    const [lowerMenuVisible, setLowerMenuVisible] = useState(false);

    const handleUpperMenuClick = (e) => {
        insertAccent(e.key);
        setUpperMenuVisible(false); // Hide upper menu after selection
    };

    const handleLowerMenuClick = (e) => {
        insertAccent(e.key);
        setLowerMenuVisible(false); // Hide lower menu after selection
    };

    const insertAccent = (accent) => {
        if (!inputRef) {
            return;
        }
        const textarea = inputRef.current ? inputRef.current : inputRef;
        if (!textarea) return;

        try {
            const { selectionStart, selectionEnd, value: inputValue } = textarea;
            const newInputValue = inputValue.substring(0, selectionStart) + accent + inputValue.substring(selectionEnd);

            updateTextareaValue(textarea, newInputValue);
            dispatchInputEvent(textarea);
            updateCursorPosition(textarea, selectionStart + accent.length);
        } catch (error) {
            console.log("Error in accent insertion:", error);
        }
    };

    const updateTextareaValue = (textarea, newValue) => {
        textarea.value = newValue;
        if (setValue) {
            setValue(newValue);
        }
    };

    const dispatchInputEvent = (textarea) => {
        const event = document.createEvent('Event');
        event.initEvent('input', true, true);
        textarea.dispatchEvent(event);
    };

    const updateCursorPosition = (textarea, newPosition) => {
        setTimeout(() => {
            textarea.setSelectionRange(newPosition, newPosition);
            textarea.focus();
        }, 0);
    };

    const Uppermenu = (
        <Menu onClick={handleUpperMenuClick}>
            <Menu.ItemGroup>
                {SPANISH_ITEM_UPPER.map((item, index) => (
                    <Menu.Item key={item}>{item}</Menu.Item>
                ))}
            </Menu.ItemGroup>
        </Menu>
    );

    const Lowermenu = (
        <Menu onClick={handleLowerMenuClick}>
            <Menu.ItemGroup>
                {SPANISH_ITEM_LOWER.map((item, index) => (
                    <Menu.Item key={item}>{item}</Menu.Item>
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
    return (
        <Wrapper style={style}>
    <Dropdown
        overlay={Uppermenu}
        onVisibleChange={(flag) => setUpperMenuVisible(flag)}
        visible={upperMenuVisible}
        trigger={['click']}
        getPopupContainer={() => document.body} // Example: Render dropdowns at the body level
    >
        <Button size="small" type="primary" style={{ backgroundColor: color.green100, borderColor: color.green100 }} onClick={() => setUpperMenuVisible(!upperMenuVisible)}>
            Á
        </Button>
    </Dropdown>
    <Dropdown
        overlay={Lowermenu}
        onVisibleChange={(flag) => setLowerMenuVisible(flag)}
        visible={lowerMenuVisible}
        trigger={['click']}
        getPopupContainer={() => document.body} // Example: Render dropdowns at the body level
    >
        <Button size="small" type="primary" style={{ marginLeft: 2, backgroundColor: color.green100, borderColor: color.green100 }} onClick={() => setLowerMenuVisible(!lowerMenuVisible)}>
            á
        </Button>
    </Dropdown>
</Wrapper>
   )
}
