
import { shallowEqual, useSelector } from 'react-redux'

const selectore = (state) => {
    return state.TabAction
};

const selectore1 = (state) => {
    return state.featuresReducer
};

const selectore2 = (state) => {
    return state.activityReducer
};

export const useActionValues = () => {
    const selectData = useSelector(selectore, shallowEqual)

    return selectData;


}

export const useActionValues1 = () => {
    const selectData1 = useSelector(selectore1, shallowEqual)
    return selectData1;


}

export const useActionValues2 = () => {
    const selectData2 = useSelector(selectore2, shallowEqual)
    return selectData2;


}