// //  ----------------------------------------------------------------------------
// //  Dependencies
// //  ----------------------------------------------------------------------------

// //  -- Constants ----------------------
import { ActionTypes } from '../constants';


// //  ----------------------------------------------------------------------------
// //  Actions
// //  ----------------------------------------------------------------------------

// //  -- Create Game ----------

export const setCurrentSession = (returnedPayload) => ({ type: ActionTypes.sessions.set_game_session, payload: returnedPayload });
export const updateCurrentSession = (returnedPayload) => ({ type: ActionTypes.sessions.update_game_session, payload: returnedPayload });
export const clearCurrentGameSession = (returnedPayload) => ({ type: ActionTypes.sessions.clear_game_session });


export const setCurrentSessionUsers = (returnedPayload) => ({ type: ActionTypes.sessions.set_current_session_users, payload: returnedPayload });
export const updateCurrentSessionUsers = (returnedPayload) => ({ type: ActionTypes.sessions.update_current_session_users, payload: returnedPayload });
export const clearCurrentGameSessionUsers = () => ({ type: ActionTypes.sessions.clear_current_session_users });


