//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';
import { cloneDeep, filter } from 'lodash';
//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const gamesCategoryInitial = [];
const categoryUnitInitial = [];

export const createGamesCategory = (state = gamesCategoryInitial, action) => {
    switch (action.type) {
        case ActionTypes.gamesCategory.listGameCategory:
            return action.payload;
        case ActionTypes.gamesCategory.createGameCategorySuccess:
            return [...state, action.payload]
        case ActionTypes.gamesCategory.updateGameCategorySuccess:
            const updateGame = state.map(x => { if (x.game_id === action.payload?.game_id) { return { ...x, ...action.payload }; } return x; });
            return updateGame;
        case ActionTypes.gamesCategory.deleteGameCategorySuccess:
            const delv = cloneDeep(state)
            const result = filter(delv, k => k.game_id !== action.payload)
            return result;
        default:
            return state;
    }
}

export const categoryUnit = (state = categoryUnitInitial, action) => {
    switch (action.type) {
        case ActionTypes.gamesCategory.listCategoryUnit:
            return action.payload;
        default:
            return state;
    }
}