import { useAuth } from '@auth/core/Auth';
import { useCurrentGameSession, useCurrentGameSessionUsers } from '@store/game_session/selectors';
import { useCurrentGameSessionSubmissions } from '@store/session_submissions/selectors';
import { Col, Row } from 'antd';
import { filter, find, forEach, get, map } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { matchUserWithSubmissions } from '../../utils/methods/QuizeMethodData';

const WinScreen1 = ({ }) => {

    return (<div className='studentEndUprNew no-select' onContextMenu={(e) => e.preventDefault()}>
        <div className='studentEndWrap'>
            <div className='row g-0'>
                <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdCardLstScreen'>
                    <div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                <div>
                                    <div className='gameLogo newLogos'>
                                        <div className='gameLogo waitingScreenLogo noCursor studentWinScreen'>
                                            <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className='spanishTextGame '>
                                        <div className='studentWinScreen1'>
                                            <p>¡Se nos acabó!</p>
                                            <p>el juego,mijo!</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                <div className='consoleGame'>
                                    <img src={require('@IPlayGame/utils/assets/images/consolegame.gif')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
const WinScreen2 = ({ }) => {
    return (<div className='studentEndUprNew  no-select' onContextMenu={(e) => e.preventDefault()}>
        <div className='studentEndWrap'>
            <div className='row g-0'>
                <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdCardLstScreen'>
                    <div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                <div>
                                    <div className='gameLogo newLogos'>
                                        <div className='gameLogo waitingScreenLogo noCursor studentWinScreen'>
                                            <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className='spanishTextGame'>
                                        <div className='studentWinScreen1'>
                                            <p>¡Se nos acabó!</p>
                                            <p>el juego,mijo!</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                <div className='smileImg studentWinScreen2'>
                                    <img src={require('@IPlayGame/utils/assets/images/img42.gif')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
const WinScreen3 = ({ }) => {

    return (
        <div className='studentEndUprNew no-select' onContextMenu={(e) => e.preventDefault()}>
            <div className='studentEndWrap'>
                <div className='row g-0'>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-8 stdntCardUpr stdCardLstScreen'>
                        <div>
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <div>

                                        <div className='gameLogo newLogos'>
                                            <div className='gameLogo waitingScreenLogo studentWinScreen'>
                                                <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                            </div>
                                        </div>
                                        <div className='spanishTextGame'>
                                            <div className='studentWinScreen1'>
                                                <p>¡Se nos acabó</p>
                                                <p>el juego...</p>
                                                <p>SIUUUU!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <div className='windowsSections'>
                                        <div>
                                            <img
                                                className='playr bounce-in'
                                                src={require('@IPlayGame/utils/assets/images/playerfiles.png')}
                                                alt=''
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WinScreen4 = ({ }) => {
    return (
        <div className='studentEndUprNew no-select' onContextMenu={(e) => e.preventDefault()}>
            <div className='studentEndWrap'>
                <div className='row g-0'>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdCardLstScreen'>
                        <div>
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <div>
                                        <div className='gameLogo newLogos'>
                                            <div className='gameLogo waitingScreenLogo studentWinScreen'>
                                                <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                            </div>
                                        </div>
                                        <div className='spanishTextGame'>
                                            <div className='studentWinScreen1'>
                                                <p>¡Hasta la</p>
                                                <p>vista che!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <div className='runningBoy'>
                                        <img className='bounce-in-right' src={require('@IPlayGame/utils/assets/images/running.png')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const WinScreen5 = ({ }) => {

    return (
        <div className='studentEndUprNew no-select' onContextMenu={(e) => e.preventDefault()}>
            <div className='studentEndWrap'>
                <div className='row g-0'>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdCardLstScreen'>
                        <div>
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <div>
                                        <div className='gameLogo newLogos'>
                                            <div className='gameLogo waitingScreenLogo studentWinScreen'>
                                                <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                            </div>
                                        </div>
                                        <div className='spanishTextGame'>
                                            <div className='studentWinScreen1'>
                                                <p>Se acabó...</p>
                                                <p>¡Nos vemos</p>
                                                <p>en la cancha</p>
                                                <p>cuate!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <div className='FootBallBouning'>
                                        <img className='bounce2' src={require('@IPlayGame/utils/assets/images/footballBounce.png')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const WinScreen6 = ({ }) => {

    return (
        <div className='studentEndUprNew no-select' onContextMenu={(e) => e.preventDefault()}>
            <div className='studentEndWrap'>
                <div className='row g-0'>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr stdCardLstScreen'>
                        <div>
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <div>
                                        {/* <animated.div className='gameLogo newLogos noCursor' style={LogoProps}>
                                            <a><img src={require('@IPlayGame/utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                        </animated.div> */}
                                        <div className='gameLogo newLogos'>
                                            <div className='gameLogo waitingScreenLogo studentWinScreen'>
                                                <a><img src={require('../../utils/assets/images/GameLogo.jpg')} alt="" /></a>
                                            </div>
                                        </div>
                                        <div className='spanishTextGame'>
                                            <div className='studentWinScreen1'>
                                                <p>¡Hasta</p>
                                                <p>pronto tío!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6'>
                                    <div className='FootBallBouning'>
                                        <img className='bounce-in' src={require('@IPlayGame/utils/assets/images/bulb.png')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StudentWinScreen = () => {
    const { currentUser } = useAuth();
    const [isEndScreen, setIsEndScreen] = useState(true);
    const [playerList, setPlayerList] = useState([])
    const [isTypingOut, setIsTypingOut] = useState(false);
    const { session_users } = useCurrentGameSessionUsers();
    const { currentSessionSumission } = useCurrentGameSessionSubmissions()
    const { currentGameSession } = useCurrentGameSession()
    const users = filter(get(session_users, "users", []), k => k.user_id !== currentGameSession.game_admin);
    const getMyUser = find(users, (obj) => obj.user_id === currentUser.user_id)

    // const matchUserWithSubmissions = () => {
    //     const result = map(users, user => {
    //         const userId = user.user_id;
    //         const currentSubmission = get(currentSessionSumission, 'submissions', '') ?? {};
    //         const { answers, score } = currentSubmission[userId] || [];
    //         const submissionsWithUserInfo = map(answers, submission => ({
    //             ...submission,
    //         }));
    //         return {
    //             userInfo: {
    //                 user_id: user.user_id,
    //                 user_name: user.user_name
    //             },
    //             submission: submissionsWithUserInfo,
    //             score: score
    //         };
    //     });

    //     return result;
    // };
    useEffect(() => {
        const results = [];
        const playerResult = matchUserWithSubmissions(users, currentSessionSumission);
        forEach(playerResult, k => {
            let result = {
                user_name: '',

                score: 0,
                user_id: ''
            };
            result.user_name = get(k, "userInfo.user_name", "");
            result.user_id = get(k, "userInfo.user_id", "");
            result.score = filter(k.submission, f => f.is_correct).length;
            results.push(result)
        })
        results.sort((a, b) => b.score - a.score).slice(0, 3)
        setPlayerList(results)
    }, [currentSessionSumission, session_users])
    // const WinnerScreen = useMemo(() => ([WinScreen1, WinScreen2, WinScreen3, WinScreen4, WinScreen5, WinScreen6][Math.floor(Math.random() * 7)])(), []);
    const WinnerScreen = useMemo(() => ([WinScreen1, WinScreen2, WinScreen3, WinScreen4, WinScreen5, WinScreen6][Math.floor(Math.random() * [WinScreen1, WinScreen2, WinScreen3, WinScreen4, WinScreen5, WinScreen6].length)]), []);


    const { textWidth } = useSpring({
        from: { textWidth: isTypingOut ? '100%' : '0%' },
        to: { textWidth: isTypingOut ? '0%' : '100%' },
        config: { duration: 300 },
    });

    const textStyles = {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        animation: 'typingAnim 3s steps(50)',
    };

    const animatedTextStyles = {
        ...textStyles,
        width: textWidth,
    };

    useEffect(() => {
        setIsEndScreen(false);
        setTimeout(() => setIsEndScreen(true), 8000)
    }, [])

    const currentUserIndex = playerList.findIndex(user => user.user_id === currentUser.user_id);

    return (
        <>
            {/* {!isEndScreen ? <ResultScreen playerList={playerList} /> : <WinnerScreen />}  */}

            {!isEndScreen ? (
                <div class="usrScoreAwardsUpr">
                    <div class="usrScoreAwards">
                        <div className="usrScorAwrdLbl">
                            <div className="">
                                <animated.p style={animatedTextStyles}>
                                    Your Rank
                                </animated.p>
                            </div>
                        </div>
                        <div className="allBeatingTxt no-select" onContextMenu={(e) => e.preventDefault()}>
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 5 }} lg={{ span: 6 }} className="resultFstWiner">
                                    <div className="resultLstImgPart">
                                        <animated.div style={animatedTextStyles}>
                                            {/* <img src={require('../../utils/assets/images/Beating.png')} /> */}
                                            <animated.label>{currentUserIndex + 1}</animated.label>
                                        </animated.div>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 11 }} lg={{ span: 10 }} className="resultFstWiner">
                                    <animated.div
                                        className="resultLstTxtPart"
                                    // style={thirdResultAnimation}
                                    >
                                        <span>{getMyUser?.user_name || ""}</span>
                                    </animated.div>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }} className="resultFstWiner">
                                    <div className="allBeatingImg">
                                        <img src={require('../../utils/assets/images/dance_human.gif')} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            ) : (
                <WinnerScreen />
            )}

        </>
    );
};

export default StudentWinScreen;