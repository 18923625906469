//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create Question ----------

export const listGameQuestion = (returnedPayload) => ({ type: ActionTypes.gameQuestion.listGameQuestion, payload: returnedPayload });
export const createGameQuestionSuccess = (returnedPayload) => ({ type: ActionTypes.gameQuestion.createGameQuestionSuccess, payload: returnedPayload });
// export const updateNotificationSuccess = (returnedPayload) => ({ type: ActionTypes.gameQuestion.updateNotificationSuccess, payload: returnedPayload });
export const updateGameQuestionSuccess = (returnedPayload) => ({ type: ActionTypes.gameQuestion.updateGameQuestionSuccess, payload: returnedPayload });
export const deleteGameQuestionSuccess = (returnedPayload) => ({ type: ActionTypes.gameQuestion.deleteGameQuestionSuccess, payload: returnedPayload });
