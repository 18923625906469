import { firebase_app } from "@IPlayGame/firebaseConfig";
import * as Sentry from '@sentry/react';

import { useAuth } from "@app/modules/auth/index";
import axios from 'axios';
import { signOut as firebaseSignOut, getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { ref as databaseRef, getDatabase, onValue, set as databaseSet } from 'firebase/database'
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
// import { set } from "lodash";
const auth = getAuth(firebase_app);
export const get_access_key = `/firebase/get_access_token`;

const FirebaseContext = createContext(null);

export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }) => {
    const [firebaseUser, setFirebaseUser] = useState(null);
    const firestore = useMemo(() => getFirestore(firebase_app), []);
    const database = useMemo(() => getDatabase(firebase_app), []);

    const signInWithCustomTokenApi = async (userId, retryCount = 0) => {
        try {
            const response = await axios.post(get_access_key, { user_id: userId });
            const data = response.data;
                        if (data.hassuccessed) {
                await signInWithCustomToken(auth, data.data);
            }
        } catch (error) {
           

           
            if (retryCount < 2) {
                setTimeout(() => {
                    signInWithCustomTokenApi(userId, retryCount + 1)
                }, 2000)
            } else {
                console.log("Max retry count reached.")
                console.error('Error during Firebase sign-in:', error);
                Sentry.captureException(`057 Error during Firebase sign-in ${error}.`);
            }

        }
    };

    const signOutUser = async () => {
        try {
            await firebaseSignOut(auth);
        } catch (error) {
            console.error('Error during Firebase sign-out:', error);
        Sentry.captureException(`058 Error during Firebase sign-out ${error}.`);

        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setFirebaseUser(user);
        });

        return () => unsubscribe();
    }, []);

    const { currentUser } = useAuth();

    useEffect(() => {
        if (currentUser && currentUser.user_id) {
            signInWithCustomTokenApi(currentUser.user_id);
        } else {
            console.log("signout called...");
            // signOutUser();
        }
    }, [currentUser]);

    return (
        <FirebaseContext.Provider value={{ firebaseUser, firestore, database, onValue, databaseRef, databaseSet, signInWithCustomTokenApi, signOutUser }}>
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;
