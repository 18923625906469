//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux'
import * as GamesActions from './actions';
import { getCurrentGameSession ,handlejoinCurrentSession , handleListSessionUsers , handleUpdateCurrentGameSessionStatus , startGameSession } from './thunks';
import { useState } from 'react';
import { useAuth } from "../../../../auth"
const selectore = (state) => {
    return state.currentGameSession
};

export const useCurrentGameSession = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    const dispatch = useDispatch();
    const [game_session_error,setSessionError] = useState(null)
    const { currentUser } = useAuth();
    const setCurrentSessionData = (data) =>{
        dispatch(GamesActions.updateCurrentSession(data));
    }
    const fetchCurrentGameSession = async (session_id) => {
        setSessionError(null)
         const data = await dispatch(getCurrentGameSession(session_id));
         if(data){
            setSessionError(null)
         }else{
            setSessionError('Unable to fetch game session')
         }
       
    }
    const startNewGameSession = async (game_id, user_id, game) => {
        setSessionError(null)
        const data = await dispatch(startGameSession(user_id, game_id, game));
        if (data) {
            setSessionError(null)
        } else {
            setSessionError('Unable to start game session')
        }
        return data;
    }
    const joinCurrentSession = async (session_id) => {
        setSessionError(null)
         const data = await dispatch(handlejoinCurrentSession(session_id,currentUser.user_id));
         return data;
       
    }
    const updateCurrentGameSessionStatus = async (session_id,status) => {
          await dispatch(handleUpdateCurrentGameSessionStatus(session_id,status))
    }
    const clearCurrentGameSession = async (session_id) => { 
        dispatch(GamesActions.clearCurrentGameSession(session_id));
    }
    return { currentGameSession: selectedData ,setCurrentSessionData , game_session_error , fetchCurrentGameSession ,clearCurrentGameSession , joinCurrentSession , updateCurrentGameSessionStatus , startNewGameSession  };
}


const session_user_selecter = state =>state.currentGameSessionUsers
export const useCurrentGameSessionUsers = () => {
    const session_users = useSelector(session_user_selecter, shallowEqual)
    const dispatch = useDispatch();
    // const [game_session_error,setSessionError] = useState(null)
    // const { currentUser } = useAuth();

    const fetchSessionGameUsers = async(session_id) =>{
         await dispatch(handleListSessionUsers(session_id))
    }
    const setSessionUsers = async(session_id,users) =>{
        await dispatch(GamesActions.setCurrentSessionUsers({session_id,users}))
   }
    const clearSessionUsers = async(session_id) =>{
        await dispatch(GamesActions.clearCurrentGameSessionUsers())
    }
   
    return { session_users , fetchSessionGameUsers , clearSessionUsers  , setSessionUsers  };
}