//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Spin } from 'antd';
import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import styled from 'styled-components';

//  -- Constants ----------------------
// import COLOR from 'utils/constants/color';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
// export const Wrapper = styled.div`
//     align-items: center;
//     display: flex;
//     justify-content: center;
//     padding: 2px 0px;̦̦
// `;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Logo
//
//  -- Description
//  Experimental app logo
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const Loading = ({ isOpen, isClose }) => {
    return (
        <Modal show={isOpen} fullscreen={true} className="my-modal" onHide={isClose}>
        <Spin size="large" style={{ position: 'absolute', top: '50%', left: '50%' }} />
    </Modal>
    )
};

// //  -- Prop types ---------------------
// Loading.propTypes = {
//     size: number,
//     white: bool,
// };

// //  -- Default props ------------------
// Loading.defaultProps = {
//     size: 32,
// };

export default Loading;
