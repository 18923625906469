/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal } from 'antd';
const DeleteModal = ({ isOpens, isClose, title, confirmDelete = () => null, isLoading, message }) => {
    const handleCancelEbk = () => {
        isClose(false)
    };
    return (
        <Modal
            open={isOpens}
            onOk={handleCancelEbk}
            onCancel={handleCancelEbk}
         
            footer={[
                <Button key="back" onClick={handleCancelEbk}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" danger loading={isLoading} onClick={confirmDelete}>
                    Delete
                </Button>,
            ]}
        >
            <p style={{ textAlign: "left", fontSize: 22, fontWeight: '700', color: 'rgb(51, 51, 51)', textTransform: 'capitalize' }}>{`Delete ${title}`}</p>
            <p style={{ textAlign: "left", fontSize: 16, fontWeight: '500', marginTop: 18, marginBottom: 18 }}>{message}</p>

        </Modal>
    )
}

export { DeleteModal };

