//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';
//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const tutorialVideoInitial = []
const gameGalleryInitial = []
const gameImageCategoryInitial = []

export const tutorialVideo = (state = tutorialVideoInitial, action) => {
    switch (action.type) {
        case ActionTypes.tutorial_video.listtutorialvideo:
            return action.payload;
        default:
            return state;
    }
}

export const gameGallery = (state = gameGalleryInitial, action) => {
    switch (action.type) {
        case ActionTypes.tutorial_video.listGameGallery:
            return action.payload;
        default:
            return state;
    }
}

export const gameImageCategory = (state = gameImageCategoryInitial, action) => {
    switch (action.type) {
        case ActionTypes.tutorial_video.listImageCategory:
            return action.payload;
        default:
            return state;
    }
}