// import { Col, Row } from 'antd';
// import { useState } from 'react';
// import { ChartsWidget1, ChartsWidget2, ChartsWidget3, ChartsWidget4, ChartsWidget5, ChartsWidget6, ChartsWidget7, ChartsWidget8 } from '../../../../_metronic/partials/widgets';

import { Col, Row } from 'antd';
import { ChartsWidget6 } from '../../../../_metronic/partials/widgets';
import { ChartsWidget6barGraph } from '../../../../_metronic/partials/widgets/charts/ChartsWidget6barGraph';

// const BestPlayer = () => {

//     const [currentSection, setCurrentSection] = useState(1);
//     const nextSection = () => {
//         setCurrentSection(prev => prev + 1);
//     };



//     return (
//         <div className="classicLayBgProj">
//             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                 <Row className='containerProj'>
//                     {/* <Col xs={24} lg={24} className='titlePro'>PROJECTOR/TEACHER VIEW</Col> */}
//                     <Col xs={24} lg={24} className='titlePro'>
//                         {/* PROJECTOR/TEACHER VIEW */}
//                     </Col>
//                     <Col xs={24} lg={24}>
//                         <Row>
//                             <Col xs={24} lg={12} className='bestPlayerContainer'>
//                                 <img src={require('../IPlayGame/utils/assets/images/bestplayer.png')} className="bestImage" alt="" />
//                             </Col>
//                             <Col xs={24} lg={12} style={{ height: 520 }}>
//                                 <ChartsWidget6 />
//                             </Col>
//                         </Row>
//                     </Col>
//                     {/* <Col lg={24} xs={24}>
//                         <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
//                             <Button style={{ backgroundColor: '#002d75', color: 'white', borderRadius: 40, minWidth: 90, height: 55, fontWeight: '800', fontSize: 18 }}>
//                             NEXT!
//                         </Button>
//                         </div>
//                     </Col> */}
//                 </Row>
//             </div>
//         </div>
//     );
// };

// export default BestPlayer;




const Index = () => {
    return (
        <div className='row g-0'>
            <div className='col-11 col-sm-11 col-md-10 col-lg-11 stdntCardUpr'>
                <Row>

                    <Col xs={24} lg={24} className='titlePro'>

                    </Col>
                    <Col xs={24} lg={24}>
                        <Row>
                            <Col xs={24} lg={12} className='bestPlayerContainer'>
                                <img src={require('../IPlayGame/utils/assets/images/bestplayer.png')} className="bestImage" alt="" />
                            </Col>
                            <Col xs={24} lg={12} style={{ height: 520 }}>
                                <ChartsWidget6barGraph />
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>
        </div>
    );
};
export default Index;


