/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useState } from 'react'
import { useLayout } from '../../core'
import { AsideTabs } from './AsideTabs'
import { TabsBase } from './Tabs/_TabsBase'
import { useLayout as layoutUsed } from '@metronic/layout/core';
const AsideDefault = () => {
  const { config } = useLayout()
  const { classes } = useLayout();
  const [link, setLink] = useState<string>('IPlayGame');
  var MenuTheme = localStorage.getItem('kt_theme_mode_menu');
  
  return (
    <div
      id='kt_aside'
      // className={clsx('aside aside-extended', classes.aside.join(' '))} 
      className={clsx('aside aside-extended', classes.aside.join(' '))} 
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Primary */}
      <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>

        <div
          className='aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 py-10'
          id='kt_aside_nav'
        >
          <AsideTabs link={link} setLink={setLink} />
        </div>
        {/* end::Nav */}
        {/* <AsideFooter /> */}
      </div>
      {/* end::Primary */}
      {config.aside.secondaryDisplay && (
        <>
          {/* begin::Secondary */}
          <div className='aside-secondary d-flex flex-row-fluid' style={{zIndex:999999}}> 
            {/* begin::Workspace */}
            <div  className={`aside-workspace p-5 asideMenuBar ${MenuTheme ? MenuTheme === 'light' ? 'menuLight' : 'menuDark': 'menuLight'}`} id='kt_aside_wordspace'>
              <TabsBase link={link} />
            </div>
            {/* end::Workspace */}
          </div>
          {/* end::Secondary */}
          {/* begin::Aside Toggle */}
          {/* <button
            
            id='kt_aside_toggle'
            className={clsx(
              'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
              classes.asideToggle.join(' ')
            )}
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            style={{ marginBottom: '1.35rem',marginLeft:'1.3rem' }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr063.svg'
              className='svg-icon-2 rotate-180'
            />
          </button>
          {/* end::Aside Toggle */}
        </>
      )}
    </div>
  )
}

export { AsideDefault }
