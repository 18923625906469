export const getAppBackendUrl = () => {
    return process.env.REACT_APP_STAGE === "local" ? process.env.REACT_APP_API__LOCAL_URL : process.env.REACT_APP_API_URL
}

export const checkIsCorrectStatus = (answer_by_student,question) => {
    return false;
}

export  function truncateString(inputString,len=8) {
    if (inputString.length > len) {
        return inputString.substring(0, len) + "...";
    } else {
        return inputString;
    }
}