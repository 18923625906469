/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal } from 'antd';
const LeavePageModal = ({ isOpens, isClose, title, confirmDelete = () => null, isLoading, message }) => {
    const handleCancelEbk = () => {
        isClose(false)
    };
    return (
        <Modal
            open={isOpens}
            onOk={handleCancelEbk}
            onCancel={handleCancelEbk}
            className="leaveModalConfrm"
            footer={[
                <Button key="back" onClick={handleCancelEbk}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" danger loading={isLoading} onClick={confirmDelete}>
                    Leave Game
                </Button>
            ]}
        >
            <p style={{ textAlign: "left", fontSize: 22, fontWeight: '700', color: 'rgb(51, 51, 51)' }}>{`${title}`}</p>
            <div className='bullt'>
            {message.map((item, index) => (
            <p style={{ textAlign: "left", fontSize: 13, fontWeight: '600', marginTop: 10, marginBottom: 10 }}>{item}</p>
            ))}
            </div>
            
        </Modal>
    )
}

export { LeavePageModal };

