import React from 'react';
import { notification, Button } from 'antd';

const NotificationMessage = () => {
  const [api, contextHolder] = notification.useNotification();
  const openCheckNotification = (type) => {
    notification.error({
      message: 'Oops! Something went wrong.',
      description: 'Please try again ...',
      onClose: () => {
      
        onBackClick()
      },
    });
  };
  openCheckNotification()
  setTimeout(() => {
    onBackClick()
  },5000)
  return (
    <div>

   {contextHolder}
    </div>
  );
};

export default NotificationMessage;


export   const onBackClick = () => {
  if (window != window.top) {
          window.parent.postMessage({ action: 'close_one_game' }, '*');
  }
}
