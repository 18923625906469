import axios from 'axios'
import * as Sentry from '@sentry/react';

import * as TutorialGameActions from './actions';

export const GETVIDEOTUTORIAL = `/gameVideo/getGameVideo`;
export const GETGALLERY = `/gameGallery/getGameGallery`;
export const GETIMAGECATEGORY = `/imageCategory/getGameImageCategory`
// Server should return AuthModel
export const getTutorialVideoList = () => async (dispatch) => {
    try {
        const url = GETVIDEOTUTORIAL;
        const gameQuestion = await axios.get(url);
        await dispatch(TutorialGameActions.listTutorialVideo(gameQuestion?.data));
        return gameQuestion?.data
    } catch (error) {
        Sentry.captureException(`055 tutorialvideo ${error}.`);
        throw error;

    }
}

// Call Game Gallery API

export const getGameGalleryList = () => async (dispatch) => {
    try {
        const url = GETGALLERY;
        const {data} = await axios.get(url);
        await dispatch(TutorialGameActions.listGameGallery(data?.data));
        return data?.data
    } catch (error) {
        Sentry.captureException(`getGameGalleryList ${error}.`);
        throw error;

    }
}

// Call Game Image Category API

export const getGameImageCategoryList = (getUrl) => async (dispatch) => {
    try {
        const url = GETIMAGECATEGORY;
        const {data} = await axios.post(url, {
            getUrl
        });
        await dispatch(TutorialGameActions.listImageCategory(data?.data[0]));
        return data?.data[0]
    } catch (error) {
        Sentry.captureException(`getGameGalleryList ${error}.`);
        throw error;

    }
}