import axios from 'axios';
export const CREATE_GAME = `/games/createGames`;
const CREATE_GAME_CATEGORY = `/gameCategory/createGameCategory`;


export const CreateGamesLogin = async (data: any) => {
    const gameQuestionData = await axios.post(CREATE_GAME, data);
    // if (data?.game_id) {
    //     dispatch(GamesActions.updateGameSuccess(gameQuestionData?.data[0]));
    // } else {
    //     dispatch(GamesActions.createGameSuccess(gameQuestionData?.data[0]));
    // }
    return gameQuestionData?.data[0]
}

// CreategameCategory
export const CreateGameCategoryLogin = async (data: any) => {
    const gameQuestionData = await axios.post(CREATE_GAME_CATEGORY, data);
    // await dispatch(GamesActions.createGameSuccess(gameQuestionData?.data[0]));
    return gameQuestionData?.data?.data[0]
}