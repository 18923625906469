import { Col, Input, QRCode, Row } from 'antd';
import { Button, Modal } from 'react-bootstrap';
const GamePinGenerator = ({ isOpens, isClose, onSubmit = () => { } }) => {
    // const [selectedImage, setSelectedImage] = useState(null);
    const handleCloseModal = () => {
        isClose(false)
    };

    // const handleImageUpload = (event) => {
    //     const image = event.target.files[0];
    //     setSelectedImage(image);

    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         const imageDataURL = reader.result;
    //     };
    //     reader.readAsDataURL(image);
    //     onSubmit(image)
    // };

    return (
        <Modal show={isOpens} onHide={handleCloseModal} className='galleryPicUpr' scrollable>
            <div className='galleryMdlCls' onClick={handleCloseModal}>
                {/* <img src={require('../../utils/assets/images/close.png')} alt="" title="" /> */}
            </div>
            <Modal.Body style={{ background: '#3b3b3b' }}>
                <div className='galleryPic'>
                    {/* <label style={{ color: 'white' }}>Game Pin</label> */}
                </div>
                <div>
                    <Row>
                        <Col xs={24} lg={12}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: 200 }}>
                                <label style={{ color: 'white', fontSize: 40, fontWeight: 700, marginBottom: 6 }}>Game Pin</label>
                                <Input placeholder='Enter Pin' style={{height:46,fontSize:16,fontWeight: 700}} />
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <QRCode
                                    color='white'
                                    errorLevel="H"
                                    size={200}
                                    // iconSize={size / 4}
                                    value="https://nosotrosonline.com/"
                                    icon="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                                />

                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ background: '#3b3b3b' }}>
                <Button variant="secondary" onClick={handleCloseModal}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}

export { GamePinGenerator };

