/* eslint-disable jsx-a11y/anchor-is-valid */
import { Spin } from 'antd'
import * as Sentry from '@sentry/react';

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLayout } from '../../../../_metronic/layout/core'
import { useAuth } from '../core/Auth'
import { useSharedSession } from '../core/SessionShare'
import { getKeyVefificationForAutologin } from '../core/_requests'

import { get } from 'lodash'
import { CreateGameCategoryLogin, CreateGamesLogin } from '../../apps/IPlayGame/store/GameCreate/createGame'
import { UserModel } from '../core/_models'
import { fetchGameById, getDuplicateGames } from '@app/modules/apps/IPlayGame/store/GameCreate/thunks'
import NotificationMessage from '@app/modules/Notication/NotificationMessage'


// const loginSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   password: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Password is required'),
// })

// const initialValues = {
//   email: '',
//   password: '',
// }
// const initialValues = {
//   email: 'admin@demo.com',
//   password: 'demo',
// }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Session_auth() {
  const navigate = useNavigate()
  const location = useLocation();
  const [loading, setLoading] = useState(false)
  const { saveAuth, currentGameContext,saveCurrentGameContext, setCurrentUser, saveSuperAuth } = useAuth()
  const [loader, setLoader] = useState(false)
  const { setSharedSession } = useSharedSession()
 
  const [showNotification,setShowNotification] =useState(false)
 


  const handleAutologin = async () => {
    // setLoader(true);
    try{
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('key');
    if (paramValue) {
      setLoading(true);
      setLoader(true);
      const auth = await getKeyVefificationForAutologin(paramValue);
      setSharedSession(auth.session_data)

      if (auth?.data?.is_superadmin) {
        auth['login'] = 'supeadmin'
        auth['window'] = 2
        saveSuperAuth({
          api_token: auth.api_token,
          loggedinfromSA: false
        })
        auth.loggedinCompany = false;
        setCurrentUser(auth.data as unknown as UserModel)
      }
      else {
        auth['login'] = 'direct'
        auth['window'] = 1
        saveAuth(auth);
        handleClick(auth.data, auth.session_data, auth);
      }
      setLoading(false);
    }
  }catch (e) {
   
    setShowNotification(true)
    Sentry.captureException(`056 1game_session_auth ${e}.`);
    
  
  }
  }

  const handleClick = async (authUser: any, authData: any, auth: any) => {
    const metadata = authData?.metadata || {};
    if (metadata?.action === 'create_game') {
      const categoryData = {
        category_name: metadata?.categoryTitle,
        image_url: metadata?.categoryImage,
        page_id: metadata?.page,
        book_id: metadata?.bookId,
        page_name: metadata?.pageTitle,
      };
      const CreateGameCategoryData = await CreateGameCategoryLogin(categoryData);
      const data = {
        created_by: get(authUser, 'user_id', ''),
        org_id: get(authUser, 'company_id', ''),
        status: false,
        category_id: get(CreateGameCategoryData, 'id', ''),
        game_type: metadata?.game_type,
        study_metadata: metadata?.game_type === '1study' ? metadata?.study_metadata : null,
        game_mode: metadata?.game_type === '1game' ? metadata?.game_mode : null,
      };
      const result = await CreateGamesLogin(data);
      if (result && result.game_id) { // Check if game_id is available in the result
        
        saveCurrentGameContext(result)
        navigate(`/apps/create/${result.game_id}?init_from=nosotros&from=outer`, { replace: true, state: { blank_ai: authData?.metadata?.templet === "ai" ? true : false  , content:authData?.metadata?.content } });
      } else {
        // Handle the case when game_id is not available
        console.error('Error: game_id not available');
      }

      setCurrentUser(auth.data as unknown as UserModel);
      setLoader(false);
    } else if (metadata?.action === 'join_game' && metadata?.game_id) { // Check if game_id is available
      if (metadata.role === 'teacher') {
        setCurrentUser(auth.data as unknown as UserModel);
        setTimeout(() => {
          navigate(`/apps/game-quiz/${metadata.game_id}?session=${metadata.session_id}&init_from=nosotros&from=outer`, { replace: true });
        }, 1000);
      } else {
        setCurrentUser(auth.data as unknown as UserModel);
        setTimeout(() => {
          navigate(`/apps/student-waiting-screen/${metadata.game_id}?session=${metadata.session_id}&init_from=nosotros&from=outer`, { replace: true });
        }, 1000);
      }
    } else if (metadata?.action === 'home_game') {
      setCurrentUser(auth.data as unknown as UserModel);
      setLoader(false);
      navigate(`/apps/home/`, { replace: true });
    } else if (metadata?.action === 'edit_game' && metadata?.game_id) { // Check if game_id is available

      const result = await fetchGameById(metadata?.game_id)


      if (result && result.game_id) {
      saveCurrentGameContext(result)
      }
      setCurrentUser(auth.data as unknown as UserModel);
      setLoader(false);
      navigate(`/apps/create/${metadata.game_id}?init_from=nosotros&from=outer`, { replace: true });
    } else if (metadata?.action === 'duplicate_game' && metadata?.game_id) { // Check if game_id is available
      setCurrentUser(auth.data as unknown as UserModel);
      const data = {
        game_id: metadata?.game_id,
        created_by: get(authUser, 'user_id', ''),
        org_id: get(authUser, 'company_id', ''),
      }
      await getDuplicateGames(data).then((response) => {
        if (response) {
          setLoader(false);
          saveCurrentGameContext(response)
          navigate(`/apps/create/${response.game_id}?init_from=nosotros&from=outer`, { replace: true });
        }
      }).catch((error) => {
        console.error('Error deleting game:', error);
        setLoader(false);
        navigate(`/apps/home/`, { replace: true });
      });
    } else {
      setCurrentUser(auth.data as unknown as UserModel);
      setLoader(false);
      navigate(`/apps/home/`, { replace: true });
    }
  };


  useEffect(() => {
    handleAutologin()
    // setLoader(false);
  }, [location]);

  const { config } = useLayout()
  const { header } = config
  const [offset, setOffset] = useState<string>(`{default: '200px', lg: '300px'}`)
  useEffect(() => {
    let newString = `{default: '200px', lg: '300px'}`
    if (header.fixed.desktop) {
      if (!header.fixed.tabletAndMobile) {
        newString = `{lg: '300px'}`
      }
    } else {
      newString = `{default: '200px', lg: false}`
    }

    setOffset(newString)
  }, [header.fixed])
  return (<>
     {

      showNotification&& <NotificationMessage />

     }
    {/* <div className='d-flex justify-content-center align-items-center vh-100'>

      <div className='yourMagic'>
        <div className='yourMagicImg'>
        </div>
        <div className='yourMagicTxt'>
          <p>Loading, Please Wait !</p>
        </div>
        <div className="maginSpinner">
          <Spin size="large" indicator={
            <div className="magicIndicator">
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </div>
          } />
        </div>
      </div>
    </div> */}
    <div className='d-flex justify-content-center align-items-center vh-100'>
    <div className="contentss">
      <div className="planet">
        <div className="ring"></div>
        <div className="cover-ring"></div>
        <div className="spots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>

        </div>
      </div>
      <p>loading</p>
    </div>
  </div>
  </>)
}
