import { message } from 'antd';
import { useEffect, useState } from 'react';

const InternetLostMessage = () => {
  const [isInternetLost, setIsInternetLost] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const openConnectionMessage = (type) => {
    messageApi.open({
      type: type,
      content: 'It seems like the internet connection has been lost.',
      duration: 0,
    });
  };

  useEffect(() => {
    const handleConnectionStatus = () => {
      setIsInternetLost(!navigator.onLine);
    }

    window.addEventListener('online', handleConnectionStatus);
    window.addEventListener('offline', handleConnectionStatus);

    return () => {
      window.addEventListener('online', handleConnectionStatus);
      window.addEventListener('offline', handleConnectionStatus);
    }
  }, [])
  useEffect(() => {
    if (isInternetLost) {
      openConnectionMessage('error');
    }
  }, [isInternetLost])

  if (isInternetLost) {
    return (
      <div className='layoutWraper notificationText'>
        {contextHolder}
      </div>
    )
  }
  return null;
}

export default InternetLostMessage;