import {useGameQuestions} from '@app/modules/apps/IPlayGame/store/GameQuestions/selectors'
import {useAuth} from '@app/modules/auth'
import {Modal} from 'antd'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Outlet, useLocation} from 'react-router-dom'
import QuestionDrawer from '../../app/modules/apps/IPlayGame/components/QuestionDrawer'
import {useAllGames} from '../../app/modules/apps/IPlayGame/store/GameCreate/selectors'
import {CreateGames} from '../../app/modules/apps/IPlayGame/store/GameCreate/thunks'
import {useDrawarValue} from '../../app/modules/apps/IPlayGame/store/drawarOpen/selectors'
import {MenuComponent} from '../assets/ts/components'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {Content} from './components/Content'
import {Footer} from './components/Footer'
import {ScrollTop} from './components/ScrollTop'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {PageDataProvider} from './core'
import {
  useGameGalleryList,
  useGameImageCategory,
} from '@app/modules/apps/IPlayGame/store/TutorialVideo/selectors'
import {filter, map} from 'lodash'
import styled from 'styled-components'

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
  margin-top: ${(p) => (p.top ? p.top : '50px')};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`

const MenuBtn = styled.button`
  background-color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`

const MasterLayout = () => {
  const GameQuestions = useGameQuestions()
  const gameGallery = useGameGalleryList()
  const gameImageCategory = useGameImageCategory()
  const {currentUser, currentGameContext, saveCurrentGameContext} = useAuth()

  const [open, setOpen] = useState(true)
  const [mobOpen, setMobOpen] = useState(true)
  const [dataTab, setDataTab] = useState(
    gameImageCategory?.meta_data ? gameImageCategory?.meta_data[0]?.id : ''
  )
  const [filtertedData, setFilteredData] = useState([])
  const [width, setWidth] = useState(260)
  const getAllGames = useAllGames()
  const dispatch = useDispatch()
  const location = useLocation()
  const currentURL = location.pathname
  const currentGameId = currentURL.split('/').pop()
  // const getCurrentGame = find(getAllGames, (item) => item.game_id === currentGameId);
  const getCurrentGame = currentGameContext

  const [selectedPredefinedImage, setSelectedPredefinedImage] = useState(null)

  const filterTutorialGallery = filter(gameGallery, (item) => item.category === dataTab)
  const getCategory = gameImageCategory?.meta_data?.find((val) => val.id === dataTab)

  const data = useDrawarValue()

  useEffect(() => {
    setDataTab(gameImageCategory?.meta_data ? gameImageCategory?.meta_data[0]?.id : "")
}, [gameImageCategory])

  useEffect(() => {
    if (getCategory?.value === 'All') {
      setFilteredData(gameGallery)
      return;
    }
    setFilteredData(filterTutorialGallery)
  }, [dataTab, gameGallery])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleOk = () => {
    setIsModalVisible(false)
    const data = {
      ...getCurrentGame,
      games_metadata: {
        ...getCurrentGame?.games_metadata,
        theme: selectedPredefinedImage,
      },
    }
    dispatch(CreateGames(data, saveCurrentGameContext))
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setSelectedPredefinedImage(null)
  }

  const [selectedImage, setSelectedImage] = useState('')
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1)

  const handleImageSelect = (imageUrl) => {
    if (imageUrl === selectedPredefinedImage) {
      setSelectedPredefinedImage('')
    } else {
      setSelectedPredefinedImage(imageUrl)
      //setIsModalVisible(false);
    }
  }
  useEffect(() => {
    setOpen(true)
    setWidth(260)
  }, [data])
  const predefinedImages = [
    'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/theme-image1.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222',
    'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/theme-image2.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222',
    'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/theme-image3.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222',
    'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/theme-image4.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222',
    'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/theme-image5.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222',
    'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/theme-image6.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222',
    'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/theme-image7.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222',
    'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/theme-image8.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222',
  ]

  const backgroundStyle = {
    backgroundImage: getCurrentGame?.games_metadata?.theme
      ? `url('${getCurrentGame?.games_metadata?.theme}')`
      : '',
    backgroundSize: 'cover', // Change 'cover' to 'contain' for a responsive and contained image
    backgroundPosition: 'bottom center', // Adjusted for centering horizontally and placing at the top vertically
    backgroundRepeat: 'no-repeat',
  }

  function navigationToMenu(id) {
    setDataTab(id)
  }

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        {/* begin::Page */}
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          {/* begin::Wrapper */}
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper
              changeTheme={() => {
                setIsModalVisible(!isModalVisible)
              }}
            />

            {/* begin::Content */}
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                <div
                  className='addBannerCover'
                  style={getCurrentGame?.games_metadata?.theme ? backgroundStyle : {}}
                >
                  <Modal
                    contentBg='red'
                    title='Theme change'
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={900}
                    className='chngThemeBG'
                  >
                    <MenuWrapper top={'10px'} style={{width: '100%'}}>
                      {map(gameImageCategory?.meta_data, (x, index) => {
                        return (
                          <MenuBtn
                            style={{
                              borderBottom:
                                dataTab === x?.id
                                  ? '5px solid rgb(28,49,115)'
                                  : '5px solid #fcfcfc',
                              color: dataTab === x?.id ? 'rgb(28,49,115)' : 'black',
                            }}
                            key={x.id}
                            onClick={() => navigationToMenu(x.id)}
                          >
                            {x?.value}
                          </MenuBtn>
                        )
                      })}
                    </MenuWrapper>
                    <div className='chngBGImgs allGalleryImg'>
                      {/* {predefinedImages.map((image, index) => (
                        <img src={image} key={index}
                          className={selectedPredefinedImage === image ? 'bgImgselected' : ''}
                          onClick={() => handleImageSelect(image)} />

                      ))} */}
                      {/* <MenuWrapper top={"10px"} style={{ width: "100%" }}>
                        {map(gameImageCategory?.meta_data, (x, index) => {
                          return (
                            <MenuBtn style={{ borderBottom: dataTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: dataTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => navigationToMenu(x.id)}>
                              {x?.value}
                            </MenuBtn>
                          )
                        })}
                      </MenuWrapper> */}
                      {map(filtertedData, (item, index) => {
                        return (
                          <div key={index}>
                            <img
                              src={item?.image_url}
                              key={index}
                              className={
                                selectedPredefinedImage === item?.image_url ? 'bgImgselected' : ''
                              }
                              onClick={() => handleImageSelect(item?.image_url)}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </Modal>
                  <Outlet />
                </div>
              </Content>
            </div>
            {/* end::Content */}
            <Footer />
          </div>
          {/* end::Wrapper */}
          {/* right section */}
          {/* <div
            className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white'
            // id='kt_drawer_chat_toggle'
            onClick={() => setOpen(true)}
          >
            <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
            <span className='pulse-ring' />
          </div> */}
          <div
            style={{
              width: window.innerWidth <= 991 ? 0 : GameQuestions.length > 0 ? width : 0,
              height: '100vh',
            }}
          >
            {/* <button className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white' onClick={() => {
              setOpen(!open)
              setWidth(open?50:360)
            }}>
              
              <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
              <span className='pulse-ring' />
            </button> */}
            <QuestionDrawer
              open={open}
              onClose={() => {
                setWidth(0)
                setOpen(false)
              }}
              title={'Game Preview---'}
            />
          </div>
          {/* end right section */}
        </div>
        {/* end::Page */}
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
