import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Button, Col, Row, Tooltip } from 'antd'
import { get } from 'lodash'
import { useRef, useState } from 'react'
import { studyGameLogo } from '../utils/assets/images/index'
import { isImageUrl } from '../utils/methods/QuizeMethodData'

function StudyQuizResult({ GameQuestions, userResponses, onBackBtnClick = () => { }, onStartAgain = () => { } }) {
    const [speechGlow, setSpeechGlow] = useState(null);
    const speechRef = useRef(null);

    const speakTitle = (data) => {
        if (speechRef.current && speechRef.current.text === data?.question?.title && !speechRef.current.paused) {
            window.speechSynthesis.pause();
            speechRef.current.paused = true;
            setSpeechGlow(null);
            return;
        }
        if (speechRef.current && speechRef.current.text === data?.question?.title && speechRef.current.paused) {
            window.speechSynthesis.resume();
            speechRef.current.paused = false;
            setSpeechGlow(data?.id);
            return;
        }
        const speechData = data?.question?.title;
        const speech = new SpeechSynthesisUtterance(speechData);
        speech.lang = 'es-ES';
        speechRef.current = speech;
        speech.addEventListener('start', () => {
            setSpeechGlow(data?.id);
        });
        speech.addEventListener('end', () => {
            setSpeechGlow(null);
            speechRef.current = null;
        });

        window.speechSynthesis.speak(speech);
    };

    return (
        <div>
            <div className='studyTestDesignTpc'>
                <div className='arrowLogoBtn'>
                    <div className='createBckBtn' />
                    <div className='oneGmPopup'>
                        <Button className='gmPopupBtn'>
                            <img src={studyGameLogo} alt='' />
                        </Button>
                    </div>
                </div>
                <div className='writeTpcEbk writeTpcEbkSection vamosResultSection'>
                    <div className='studyResultExit'>
                        <p style={{ cursor: 'pointer' }}
                            onClick={() => {
                                onStartAgain();
                            }}>Start again</p>
                        <p
                            style={{ cursor: 'pointer', marginTop: 2 }}
                            onClick={() => {
                                onBackBtnClick()
                            }}
                        >
                            Exit
                        </p>
                    </div>
                    <p>Results</p>
                </div>
                {/* Mapping over GameQuestions to render each question */}
                {GameQuestions.map((question, index) => {
                    const questions = question?.question || {}
                    return (
                        <div key={question.id} className='allStudyTestBtm'>
                            <div className='quesSelectTxtAll'>
                                <div className='studyTestImg'>
                                    <img
                                        src={require('../utils/assets/images/mic.png')}
                                        alt=''
                                        onClick={() => {
                                            speakTitle(question);
                                        }
                                        }
                                        style={{ cursor: 'pointer', filter: `${speechGlow === question?.id ? 'drop-shadow(16px 16px 20px red) invert(75%)' : 'contrast(0.5)'} ` }}
                                    />
                                    <p>
                                        {index + 1}. {question.question.title}
                                    </p>
                                </div>
                                {questions?.imageUrl && (
                                    <div className='flashcard-Image-container'>
                                        <img
                                            className='AQimage studyAQImg'
                                            src={get(questions, 'imageUrl', '')}
                                            alt=""
                                        />
                                    </div>
                                )}
                                <div className='quesSelectTrueFalse'>
                                    <Row gutter={20}>
                                        {/* Checking if the question type is 'true_or_false' */}
                                        {question.type === 'true_or_false' && (
                                            <>
                                                <Col className='gutter-row' lg={12} xs={24} sm={12} md={12}>
                                                    <div
                                                        className={`quizFlshCrdBtnSection ${userResponses[index]?.response === 'True'
                                                            ? userResponses[index].isCorrect === true
                                                                ? 'greenBackground'
                                                                : 'redBackground'
                                                            : question?.answers?.answer[0] === 'True' ||
                                                                question?.answers?.answer === 'True'
                                                                ? 'greenBackground'
                                                                : ''
                                                            }`}
                                                    >
                                                        {/* Checking if the user response matches the option */}
                                                        <p>
                                                            {userResponses[index]?.response === 'True' ? (
                                                                userResponses[index].isCorrect === true ? (
                                                                    <CheckCircleIcon className='trueSuffleIcon' />
                                                                ) : (
                                                                    <CancelIcon className='falseSuffleIcon' />
                                                                )
                                                            ) : question?.answers?.answer[0] === 'True' ||
                                                                question?.answers?.answer === 'True' ? (
                                                                <CheckCircleIcon className='trueSuffleIcon' />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </p>
                                                        <Button>TRUE</Button>
                                                    </div>
                                                </Col>
                                                <Col className='gutter-row' lg={12} xs={24} sm={12} md={12}>
                                                    <div
                                                        className={`quizFlshCrdBtnSection ${userResponses[index]?.response === 'False'
                                                            ? userResponses[index].isCorrect === true
                                                                ? 'greenBackground'
                                                                : 'redBackground'
                                                            : question?.answers?.answer[0] === 'False' ||
                                                                question?.answers?.answer === 'False'
                                                                ? 'greenBackground'
                                                                : ''
                                                            }`}
                                                    >
                                                        {/* Checking if the user response matches the option */}
                                                        <p>
                                                            {userResponses[index]?.response === 'False' ? (
                                                                userResponses[index].isCorrect === true ? (
                                                                    <CheckCircleIcon className='trueSuffleIcon' />
                                                                ) : (
                                                                    <CancelIcon className='falseSuffleIcon' />
                                                                )
                                                            ) : question?.answers?.answer[0] === 'False' ||
                                                                question?.answers?.answer === 'False' ? (
                                                                <CheckCircleIcon className='trueSuffleIcon' />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </p>
                                                        <Button>FALSE</Button>
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                        {/* Checking if the question type is 'quiz' */}
                                        {question.type === 'quiz' &&
                                            question?.answers?.options?.map((option, optionIndex) => (
                                                <Col className='gutter-row' lg={12} xs={24} sm={12} md={12} key={optionIndex}>
                                                    <div
                                                        className={`quizFlshCrdBtnSection ${userResponses[index]?.response === option
                                                            ? userResponses[index].isCorrect === true
                                                                ? 'greenBackground'
                                                                : 'redBackground'
                                                            : question?.answers?.answer[0] === option ||
                                                                question?.answers?.answer === option
                                                                ? 'greenBackground'
                                                                : ''
                                                            }`}
                                                    >
                                                        {/* Checking if the user response matches the option */}
                                                        <p>
                                                            {userResponses[index]?.response === option ? (
                                                                userResponses[index].isCorrect === true ? (
                                                                    <CheckCircleIcon className='trueSuffleIcon' />
                                                                ) : (
                                                                    <CancelIcon className='falseSuffleIcon' />
                                                                )
                                                            ) : question?.answers?.answer[0] === option ||
                                                                question?.answers?.answer === option ? (
                                                                <CheckCircleIcon className='trueSuffleIcon' />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </p>
                                                        {isImageUrl(option) ? (
                                                            <Button>
                                                                <img src={option} alt={`Option ${String.fromCharCode(65 + index)}`} className='imgContainerAQ studyAnswerImg' />
                                                            </Button>
                                                        ) : (
                                                            <button>
                                                                {option}
                                                            </button>
                                                        )}
                                                        {/* <Button>
                                                    {isImageUrl(option) ? (
                                                      <img src={option} alt={`Option ${String.fromCharCode(65 + index)}`} className='imgContainerAQ answerImg' />
                                                    ): (
                                                      <label>{option}</label>
                                                    )}
                                                      </Button> */}
                                                    </div>
                                                </Col>
                                            ))}
                                    </Row>
                                </div>

                                {question.type === 'puzzle' && (
                                    <>
                                        <div className='puzzleWrapper'>
                                            {/* Render correct order at the bottom */}
                                            <div className='dragDropTxt stdyResltdragDropTxt addingHeightMobile' style={{ marginRight: 20 }}>
                                                {question.answers.options?.map((item, itemIndex) => (
                                                    <p key={itemIndex}>{`${item}`}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {/* Render puzzle items */}
                                                {userResponses[index]?.response.map((item, itemIndex) => (
                                                    <div key={itemIndex} className='puzzleItem'>
                                                        <div key={index} className='textareadragPart'>
                                                            <div className='responseDragDrop'>
                                                                {item && item?.length > 8 ? (
                                                                    <Tooltip title={item}>
                                                                        <p>{item.slice(0, 8) + '...'}</p>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <p>{item}</p>
                                                                )}
                                                                {/* <div className='resultStudyPuzlDrp'> */}
                                                                {userResponses[index]?.isCorrect[itemIndex] === true ? (
                                                                    <CheckCircleIcon className='trueSuffleIcon studyResultTrueIcon' />
                                                                ) : (
                                                                    <CancelIcon className='falseSuffleIcon studyResultTrueIcon' />
                                                                    // </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>

                                        <div className="correctOrder">
                                            <span>Correct order:</span>
                                            {question?.answers?.answer.map((item, index) => (
                                                <div key={index} className="correctOrderItem">
                                                    {item && item.length > 8 ? (
                                                        <Tooltip title={item}>
                                                            <p>{` ${item.slice(0, 8) + '...'}`}</p>
                                                        </Tooltip>
                                                    ) : (
                                                        <p>{`${item}`}</p>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                    </>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default StudyQuizResult
