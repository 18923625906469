import axios from 'axios'
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { useAuth } from './Auth'
  type AuthContextProps = {
    setfunctionCategory:(data:any)=>void
    functionCategory:any
    setfunction:(data:any)=>void
    functionName:any
  }
  interface FunctionRoleResponse {
    functionCategory: string[]; 
    functionNames: string[]; 
  }
  const initAuthContextPropsState = {
    setfunction:()=>{},
    functionName:undefined,
    setfunctionCategory:()=>{},
    functionCategory:undefined

  }
  
  const FunctionProvider = createContext<AuthContextProps>(initAuthContextPropsState)
  
  const useFunction = () => {
    return useContext(FunctionProvider)
  }

  
  
  const FunctionProviders: FC<WithChildren> = ({children}) => {
    const [functionName, setfunction] = useState<any>()
    const [functionCategory,setfunctionCategory]=useState<any>()
    const { currentUser } = useAuth()
    useEffect(() => {
      if (currentUser && currentUser.user_id) {
        axios.get<FunctionRoleResponse>(`/functionRole/userRolesFunction/${currentUser.user_id}/${currentUser.company_id}`)
          .then((response) => {
            setfunctionCategory(response.data.functionCategory);
            setfunction(response.data.functionNames);
          })
          .catch((error) => console.error(error));
      }
    }, [currentUser])
   
  
    return (
      <FunctionProvider.Provider value={{functionName, setfunction,setfunctionCategory,functionCategory}}>
        {children}
      </FunctionProvider.Provider>
    )
  }
  
 
  
  export { FunctionProviders, useFunction }
  