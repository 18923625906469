import axios from 'axios';
import * as Sentry from '@sentry/react';
import * as GamesCategoryActions from './actions';
const GAME_CATEGORY = `/gameCategory/getGameCategory`;
const CREATE_GAME_CATEGORY = `/gameCategory/createGameCategory`;
const GAME_BY_CATEGORY = `/gameCategory/GamesByCategory`;
const USER_CATEGORY = `/gameCategory/userGameCategory`;
const USER_SUB_CATEGORY = `/gameCategory/userGameSubCategory`
const CATEGORY_UNIT = `/gameCategory/getUnitsById`

// GetGameCategory
export const getGameCategory = () => async (dispatch) => {
    try {
        const gameCategory = await axios.get(GAME_CATEGORY);
        await dispatch(GamesCategoryActions.listGameCategory(gameCategory?.data));
        return gameCategory?.data
    } catch (e) {
        Sentry.captureException(`015 getGameCategory ${e}.`);
    }
}

// CreategameCategory
export const CreateGameCategory = (data) => async (dispatch) => {
    try {
        const gameQuestionData = await axios.post(CREATE_GAME_CATEGORY, data);
        // await dispatch(GamesCategoryActions.createGameCategorySuccess(gameQuestionData?.data[0]));
        return gameQuestionData?.data?.data[0]
    } catch (e) {
        Sentry.captureException(`016 createGameCategory ${e}. Data: ${JSON.stringify(data)}`);

    }

}

// CreategameCategory
export const GameByCategory = async (id) => {
    try {
        const { data } = await axios.post(GAME_BY_CATEGORY, id);
        // await dispatch(GamesCategoryActions.createGameCategorySuccess(gameQuestionData?.data[0]));
        return data?.data;
    } catch (e) {
        Sentry.captureException(`017 GamesByCategory ${e}. ID: ${id}`);

    }
}

// User Specific Category
export const UserGameCategory = (id) => async (dispatch) => {
    const { data } = await axios.post(USER_CATEGORY, id);
    try {
        await dispatch(GamesCategoryActions.listGameCategory(data?.data));
        return data?.data;
    } catch (error) {
        Sentry.captureException(`018 userGameCategory ${error}. ID: ${id}`);
        return null;
    }
}

// User Specific Sub-Category
export const UserGameSubCategory = async (id) => {
    try {
        const { data } = await axios.post(USER_SUB_CATEGORY, id);
        return data?.data;
    } catch (e) {
        Sentry.captureException(`019 userGameSubCategory ${e}. ID: ${id}`);

    }
}

// fetch unit By Id

export const getCategoryUnit = (id) => async (dispatch) => {
    try {
        const { data } = await axios.post(CATEGORY_UNIT, id);
        await dispatch(GamesCategoryActions.listCategoryUnit(data?.data));
        return data?.data;
    } catch (e) {
        Sentry.captureException(`020 getUnitsById ${e}. ID: ${id}`);

    }
}
