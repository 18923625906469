import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import IPlayGame from '../modules/apps/IPlayGame'
import ProjectorScreen from '../modules/apps/IPlayGame/IPlayGameProjector'
import BestPlayer from '../modules/apps/IPlayGame/IPlayGameProBestPlayer'
import StudentWinScreen from '../modules/apps/IPlayGame/StudentEnd/StudentWaitingScreen/StudentWinScreen'
import TutorialVideo from '@app/modules/apps/IPlayGame/TutorialVideo';
import UpdateVideo from '@app/modules/apps/IPlayGame/UpdateVideo';
const HomeGame = lazy(() => import('../modules/apps/IPlayGame/Home/HomeGame'));

const PrivateRoutes = () => {

  const IPlayGameListing = lazy(() => import('./../modules/apps/IPlayGame/IPlayGameListing'));
  // const HomePages = lazy(() => import('../modules/apps/IPlayGame/Homepages'));

  const IPlayGameQuiz = lazy(() => import('../modules/apps/IPlayGame/IPlayGameQuiz'));
  const IPlayGameTypeAnswer = lazy(() => import('../modules/apps/IPlayGame/IPlayGameTypeAnswer'));
  const IPlayGameTrueFalse = lazy(() => import('../modules/apps/IPlayGame/IPlayGameTrueFalse'));
  const IPlayGamePoll = lazy(() => import('../modules/apps/IPlayGame/IPlayGamePoll'));
  const IPlayGameDropPin = lazy(() => import('../modules/apps/IPlayGame/IPlayGameDropPin'));
  const IPlayGamePuzzle = lazy(() => import('../modules/apps/IPlayGame/IPlayGamePuzzle'));
  const IPlayGameWordCloud = lazy(() => import('../modules/apps/IPlayGame/IPlayGameWordCloud'));
  const IPlayGameOpenEnded = lazy(() => import('../modules/apps/IPlayGame/IPlayGameOpenEnded'));
  const IPlayGameBrainstorm = lazy(() => import('../modules/apps/IPlayGame/IPlayGameBrainstorm'));
  const ScoreBestPlayer1 = lazy(() => import('../modules/apps/IPlayGame/StudentEnd/ScoreAndBestPlayer/ScoreBestPlayer1'));

  // Student Side Screens
  const Podiums1 = lazy(() => import('../modules/apps/IPlayGame/StudentEnd/PodiumsScreens/podiums1'));
  const Podiums2 = lazy(() => import('../modules/apps/IPlayGame/StudentEnd/PodiumsScreens/podiums2'));
  const Podiums3 = lazy(() => import('../modules/apps/IPlayGame/StudentEnd/PodiumsScreens/podiums3'));
  const IPlayStudentListing = lazy(() => import('../modules/apps/IPlayGame/IPlayStudentListing'));
  const StudentQuizOptions = lazy(() => import('../modules/apps/IPlayGame/StudentSide/StudentQuiz/StudentQuizOptions'));
  const StudentTrueFalseOptions = lazy(() => import('../modules/apps/IPlayGame/StudentSide/StudentTrueFalse/StudentTrueFalseOptions'));
  const StudentTypeAnswer = lazy(() => import('../modules/apps/IPlayGame/StudentSide/StudentTypeAnswer'));
  const StudentOpenEnded = lazy(() => import('../modules/apps/IPlayGame/StudentSide/StudentOpenEnded'));
  const StudentResult = lazy(() => import('../modules/apps/IPlayGame/StudentSide/Result'));

  // Teacher Quiz
  const TeacherPlayGameQuiz = lazy(() => import('../modules/apps/IPlayGame/TeacherPlayWraper/TeacherPlayGameQuiz'));
  const TeaherPlayGameTrueFalse = lazy(() => import('../modules/apps/IPlayGame/TeacherPlayWraper/TeaherPlayGameTrueFalse'));

  // Student End Screen
  const QuizQuestions = lazy(() => import('../modules/apps/IPlayGame/StudentEnd/PlayingQuizEnd/QuizQuestions'));
  const ModernQuizQuestions = lazy(() => import('../modules/apps/IPlayGame/StudentEnd/PlayingQuizEnd/ModernQuizQuestions'));
  const QuizQuestionsAnimation = lazy(() => import('../modules/apps/IPlayGame/StudentEnd/PlayingQuizEnd/PlayingQuizViewDemo'));
  const StudentWaitingScreen1 = lazy(() => import('../modules/apps/IPlayGame/StudentEnd/StudentWaitingScreen/StudentWaitingScreen1'));

  // OneStudyPlay Screen
   const StudyTestDesignSecond = lazy(() => import('../modules/apps/IPlayGame/OneStudyPlay/StudyTestDesignSecond'));
   const StudyTestDesignThird = lazy(() => import('../modules/apps/IPlayGame/OneStudyPlay/StudyTestDesignThird'));

  return (
    <Routes>
      <Route
        path='apps/quiz-questions/:id'
        element={
          <SuspensedView>
            <QuizQuestions />
          </SuspensedView>
        }
      />
      <Route
        path='apps/modern-quiz-questions/:id'
        element={
          <SuspensedView>
            <ModernQuizQuestions />
          </SuspensedView>
        }
      />
      <Route
        path='apps/quiz-questions-animation/*'
        element={
          <SuspensedView>
            <QuizQuestionsAnimation />
          </SuspensedView>
        }
      />
      <Route
        path='apps/student-waiting-screen/:id'
        element={
          <SuspensedView>
            <StudentWaitingScreen1 />
          </SuspensedView>
        }
      />
      <Route
        path='apps/student-win-screen/*'
        element={
          <SuspensedView>
            <StudentWinScreen />
          </SuspensedView>
        }
      />

      <Route
        path='apps/teacher-quiz-play/*'
        element={
          <SuspensedView>
            <TeacherPlayGameQuiz />
          </SuspensedView>
        }
      />
      <Route
        path='apps/teacher-true-false/*'
        element={
          <SuspensedView>
            <TeaherPlayGameTrueFalse />
          </SuspensedView>
        }
      />
      <Route
        path='apps/home/*'
        element={
          <SuspensedView>
            <HomeGame />
          </SuspensedView>
        }
      />
      <Route
        path='apps/home/TutorialVideo'
        element={
          <SuspensedView>
            <TutorialVideo />
          </SuspensedView>
        }
      />
      <Route
        path='apps/home/updateVideo'
        element={
          <SuspensedView>
            <UpdateVideo />
          </SuspensedView>
        }
      />
      <Route
        path='apps/student-listing/*'
        element={
          <SuspensedView>
            <IPlayStudentListing />
          </SuspensedView>
        }
      />
      <Route
        path='apps/podiums1/*'
        element={
          <SuspensedView>
            <Podiums1 />
          </SuspensedView>
        }
      />
      <Route
        path='apps/podiums2/*'
        element={
          <SuspensedView>
            <Podiums2 />
          </SuspensedView>
        }
      />
      <Route
        path='apps/podiums3/*'
        element={
          <SuspensedView>
            <Podiums3 />
          </SuspensedView>
        }
      />
      
      <Route
        path='apps/student-quiz-options/*'
        element={
          <SuspensedView>
            <StudentQuizOptions />
          </SuspensedView>
        }
      />

      <Route
        path='apps/student-true-false-options/*'
        element={
          <SuspensedView>
            <StudentTrueFalseOptions />
          </SuspensedView>
        }
      />

      <Route
        path='apps/student-type-answer/*'
        element={
          <SuspensedView>
            <StudentTypeAnswer />
          </SuspensedView>
        }
      />
      <Route
        path='apps/student-open-ended/*'
        element={
          <SuspensedView>
            <StudentOpenEnded />
          </SuspensedView>
        }
      />
      <Route
        path='apps/student-open-ended/*'
        element={
          <SuspensedView>
            <StudentOpenEnded />
          </SuspensedView>
        }
      />

      <Route
        path='apps/teachers-question/*'
        element={
          <SuspensedView>
            <ScoreBestPlayer1 />
          </SuspensedView>
        }
      />

      <Route
        path='apps/student-result/*'
        element={
          <SuspensedView>
            <StudentResult />
          </SuspensedView>
        }
      />

      <Route
        path='apps/game-listing/*'
        element={
          <SuspensedView>
            <IPlayGameListing />
          </SuspensedView>
        }
      />
      <Route
        path='apps/game-quiz/:id'
        element={
          <SuspensedView>
            <IPlayGameQuiz />
          </SuspensedView>
        }
      />
      <Route
        path='apps/game-type-answer/*'
        element={
          <SuspensedView>
            <IPlayGameTypeAnswer />
          </SuspensedView>
        }
      />
      <Route
        path='apps/true-false/*'
        element={
          <SuspensedView>
            <IPlayGameTrueFalse />
          </SuspensedView>
        }
      />
      <Route
        path='apps/poll/*'
        element={
          <SuspensedView>
            <IPlayGamePoll />
          </SuspensedView>
        }
      />
      <Route
        path='apps/drop-pin/*'
        element={
          <SuspensedView>
            <IPlayGameDropPin />
          </SuspensedView>
        }
      />
      <Route
        path='apps/puzzle/*'
        element={
          <SuspensedView>
            <IPlayGamePuzzle />
          </SuspensedView>
        }
      />

      <Route
        path='apps/word-cloud/*'
        element={
          <SuspensedView>
            <IPlayGameWordCloud />
          </SuspensedView>
        }
      />

      <Route
        path='apps/open-ended/*'
        element={
          <SuspensedView>
            <IPlayGameOpenEnded />
          </SuspensedView>
        }
      />

      <Route
        path='apps/brain-storm/*'
        element={
          <SuspensedView>
            <IPlayGameBrainstorm />
          </SuspensedView>
        }
      />
      <Route
        path='apps/projector-screen/*'
        element={
          <SuspensedView>
            <ProjectorScreen />
          </SuspensedView>
        }
      />
      <Route
        path='apps/best-player/*'
        element={
          <SuspensedView>
            <BestPlayer />
          </SuspensedView>
        }
      />

       {/* OneStudyPlay */}
      <Route
        path='apps/study-test-design-second/*'
        element={
          <SuspensedView>
            <StudyTestDesignSecond />
          </SuspensedView>
        }
      />
      <Route
        path='apps/study-test-design-third/*'
        element={
          <SuspensedView>
            <StudyTestDesignThird />
          </SuspensedView>
        }
      />
      {/* End OneStudyPlay */}

      <Route element={<MasterLayout />}>

        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={
          <Navigate to='/apps/IPlayGame/' />} />
        <Route path='passwordSet/*' element={<Navigate to='/apps/IPlayGame/' />} /> */}

        <Route path='auth/*' element={
          <Navigate to='/apps/home/' />} />
        <Route path='passwordSet/*' element={<Navigate to='/apps/home/' />} />

        {/* Pages */}

        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* <Route path='menu-test' element={<MixedWidget2 />} /> */}
        {/* Lazy Modules */}

        {/* Added for the custom form */}
        <Route
          path='apps/create/:id'
          element={
            <>
              <IPlayGame />
              {/* <Navigate to='/apps/create/:id' />  */}
            </>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
