import { useEffect, useState } from 'react';
import './modern-style.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const ModernScoreAsked = ({ modernResultDecide = {}, modernLoader }) => {
    const [count, setCount] = useState(10);
    const [load, setLoad] = useState('');
    useEffect(() => {
        const interval = setInterval(() => {
            if (count > 0) {
                setCount(count - 1);
            } else {
                clearInterval(interval);
                modernResultDecide('maybe');
                handleLoader('maybe');
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [count]);
    
    const handleLoader = (loader) => {
        setLoad(loader)
    }

    return (
        <div>
            <div className="modernAllMaybeSection">
                <label style={{float: 'right'}} className="quesAnsTimerCount">{count}</label>
            </div>
            <div className='pointBtn' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh', gap: '20px', }}>
                <div className='ScoreBtnInfo'>
                    <button class="btn" onClick={() => {
                        modernResultDecide('maybe');
                        handleLoader('maybe');
                    }}>
                        {modernLoader && load === 'maybe' ? <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />
                            }
                        /> : 'Maybe'}
                    </button>
                    <p>Correct answer + 1</p>
                </div>
                <div className='ScoreBtnInfo'>
                    <button class="btn" onClick={() => {
                        modernResultDecide('yes');
                        handleLoader('yes');
                    }}>
                        {modernLoader && load === 'yes' ? <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                    spin
                                />
                            }
                        /> : 'Yes' }
                        
                    </button>
                    <p>Correct answer + 2</p>
                    <p style={{ marginTop: '-15px' }}>Incorrect answer - 1</p>
                </div>

            </div>
        </div>
    )
}

export default ModernScoreAsked;