import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useLocation, useNavigate} from 'react-router-dom'
import {verify_otp} from '../core/_requests'
import {Form} from 'react-bootstrap'
import './styless.scss'
import {useAuth} from '../core/Auth'
import {useRef} from 'react'

// const initialValues = {
//   email: 'admin@demo.com',
// }

// const forgotPasswordSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
// })

export function EnterOtp() {
  const navigate = useNavigate()
  const {saveAuth, auth, setCurrentUser, saveSuperAuth} = useAuth()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const location = useLocation()

  const {state}: any = location
  const inputRefs = useRef<Array<HTMLInputElement | any>>([])
  const [digits, setDigits] = useState(['', '', '', '', '', ''])
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const emailParam = urlParams.get('email')
    const emailValue = emailParam ? decodeURIComponent(emailParam) : ''
    if (emailParam) setTonken(emailParam)
  }, [])

  const handleDigitChange = (event: any, index: any) => {
    const {value} = event.target
    if (value.length === 1) {
      // Move focus to the next input field
      const nextIndex = index + 1
      if (inputRefs.current[nextIndex]) {
        inputRefs?.current[nextIndex].focus()
      }
    }

    const updatedDigits = [...digits]
    updatedDigits[index] = value
    setDigits(updatedDigits)
  }
  const setTonken = async (otp: any) => {
    try {
      setLoading(true)
      const data = {
        token: otp,
        auth: auth?.api_token,
      }

      const {data: auths} = await verify_otp(data)

      setLoading(false)
      if (auths?.code === 200) {
        if(auths?.data?.is_superadmin){
          auths['login'] = 'supeadmin'
          auths['window'] = 2
          saveSuperAuth(auths)
          auths.loggedinCompany = false;
          setCurrentUser(auths.data)
        }
        else{
          auths['login'] = 'direct'
          auths['window'] = 1
          saveAuth(auths)
          setCurrentUser(auths?.data)
        }
        saveAuth(auths)
        setCurrentUser(auths.data)

        // navigate('/organization', {
        //   state: `Congratulations! Your email address (${auths.data?.email}) has been successfully verified. You are now one step away from accessing your account. Please provide your organization details to complete the registration process..`,
        // })
      } else if (auths?.code == 202) {
        setMessage(auths?.message)
      } else if (auths?.code == 201) {
        setMessage(auths?.message)
      } else {
        setMessage(auths?.message)
      }
    } catch (e) {
      setLoading(false)

      console.log('eeeee', e)
    }
  }
  const setOtp = async (e: any) => {
    e.preventDefault()

    try {
      if (digits.join('').length === 6) {
        setLoading(true)
        const data = {
          otp: digits.join(''),
          auth: auth?.api_token,
          user_token: state.token,
        }

        const {data: auths} = await verify_otp(data)

        setLoading(false)
        if (auths?.code === 200) {
          setCurrentUser(auths.data)
          saveAuth(auths)
          //state?.token !==

          // navigate('/organization', {
          //   state: `Congratulations! Your phone number has been successfully verified. You are now one step away from accessing your account. Please provide your organization details to complete the registration process`,
          // })
        } else if (auths?.code == 202) {
          setMessage(auths?.message)
        } else if (auths?.code == 201) {
          setMessage(auths?.message)
        }

        // setCurrentUser(auth.data)
      } else {
        setMessage('An OTP has been sent to your registered email or phone number. Please check your email or phone and enter the OTP to continue')
      }
    } catch (e) {
      setLoading(false)

      setMessage('Networ Error')
    }
  }

  return (
    <>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            {/* <h5 className='mb-20 setbutton '>{state?.body}</h5> */}
            {!state?.pos && (
              <div className='bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
                <div>
                  <div className="font-size-lg mb-20">
                    <div>{state?.body}</div>
                  </div>
                </div>
              </div>
            )}

            {state?.pos && (
              <Form className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
                <div>
                
                  <div className="font-size-lg mb-10">
                    <div>{state?.body}</div>
                  </div>
                </div>
                <p className="mb-5 fw-bold">Enter a 6-digit number:</p>
                <div className='d-flex'>
                  {digits?.map((digit, index) => (
                    <Form.Control
                      key={index}
                      value={digit}
                      type='text'
                      pattern='[0-9]'
                      maxLength={1}
                      onChange={(e: any) => handleDigitChange(e, index)}
                      // placeholder='-'
                      min='0'
                      max='9'
                      required
                      className='me-2 text-center'
                      ref={(ref: any) => {
                        inputRefs.current[index] = ref
                      }}
                    />
                  ))}
                </div>
                <button
                  type='button'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary ms-50px w-30 mt-10 mb-5 center'
                  disabled={loading}
                  onClick={(e) => {
                    setOtp(e)
                  }}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress fixloadersite'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </Form>
            )}
            {message && (
              <div className='alert alert-danger alert-dismissible text-center mt-10' role='alert'>
                {message}
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='alert'
                  aria-label='Close'
                ></button>
              </div>
            )}
          </div>
        </div>
        {/* <form
              className='form w-100'
              id='kt_password_reset_form'
              data-kt-redirect-url='../../demo1/dist/authentication/layouts/corporate/new-password.html'
              action='#'
            >
              <div className='text-center mb-10'>
                <label>
                  <input type='checkbox' className='form-control bg-transparent' />
                  Check me
                </label>

                <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>

                <div className='text-gray-500 fw-semibold fs-6'>
                  Enter your email to reset your password.
                </div>
              </div>

              <div className='fv-row mb-8'>
                <input
                  type='text'
                  placeholder='Email'
                  name='email'
                  autoComplete='off'
                  className='form-control bg-transparent'
                />
              </div>

              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='button'
                  id='kt_password_reset_submit'
                  className='btn btn-primary me-4'
                >
                  <span className='indicator-label'>Submit</span>

                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
                <a
                  href='../../demo1/dist/authentication/layouts/corporate/sign-in.html'
                  className='btn btn-light'
                >
                  Cancel
                </a>
              </div>
            </form> */}

        <div className='w-lg-500px d-flex flex-stack px-10 mx-auto'>
          <div className='me-10'>
            <button
              className='btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-start'
              data-kt-menu-offset='0px, 0px'
            >
              <img
                data-kt-element='current-lang-flag'
                className='w-20px h-20px rounded me-3'
                src='assets/media/flags/united-states.svg'
                alt=''
              />
              <span data-kt-element='current-lang-name' className='me-1'>
                English
              </span>
              <i className='ki-duotone ki-down fs-5 text-muted rotate-180 m-0'></i>
            </button>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7'
              data-kt-menu='true'
              id='kt_auth_lang_menu'
            >
              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='English'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/united-states.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>English</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='Spanish'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/spain.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>Spanish</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='German'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/germany.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>German</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='Japanese'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/japan.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>Japanese</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='French'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/france.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>French</span>
                </a>
              </div>
            </div>
          </div>

          <div className='d-flex fw-semibold text-primary fs-base gap-5'>
            <a href='../../demo1/dist/pages/team.html' target='_blank'>
              Terms
            </a>
            <a href='../../demo1/dist/pages/pricing/column.html' target='_blank'>
              Plans
            </a>
            <a href='../../demo1/dist/pages/contact.html' target='_blank'>
              Contact Us
            </a>
          </div>
        </div>
      </div>
      {/* <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
       
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
         
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
       
        </div>


        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
      
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
    

   
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
       
      </form> */}
    </>
  )
}
