import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { tabAction } from './TabChange/reducers'
import { featuresReducer } from './TabChange/reducers'
import { activityReducer } from './TabChange/reducers'
import { gameQuestion } from './GameQuestions/reducers'
import { createGames } from './GameCreate/reducers'
import { openDrawar } from './drawarOpen/reducers'
import { categoryUnit, createGamesCategory } from './GameCategory/reducers'
import { currentGameSession , currentGameSessionUsers } from "./game_session/reducers"
import { currentSessionMySubmission } from "./my_submission/reducers"
import { currentSessionSumission } from "./session_submissions/reducers"
import { gameGallery, gameImageCategory, tutorialVideo } from "./TutorialVideo/reducers"


const reducer = combineReducers({
  TabAction: tabAction,
  featuresReducer: featuresReducer,
  activityReducer: activityReducer,
  gameQuestion: gameQuestion,
  createGames: createGames,
  drawarOpenClose: openDrawar,
  createGamesCategory: createGamesCategory,
  categoryUnit: categoryUnit,
  currentGameSession,
  currentGameSessionUsers,
  currentSessionMySubmission,
  currentSessionSumission,
  tutorialVideo,
  gameGallery,
  gameImageCategory
})

let initialState = {
  //   cart: {
  //     cartItems: localStorage.getItem("cartItems")
  //       ? JSON.parse(localStorage.getItem("cartItems"))
  //       : [],
  //     shippingInfo: localStorage.getItem("shippingInfo")
  //       ? JSON.parse(localStorage.getItem("shippingInfo"))
  //       : {},
  //   },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store