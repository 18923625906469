import { storage } from '@IPlayGame/firebaseConfig';
import { LoadingOutlined } from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import { Button, Spin, notification } from 'antd';
import { deleteObject, ref } from 'firebase/storage';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { AiOutlinePlusSquare } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useAuth } from '../../../auth';
import { ImageModal } from '../components/ImageModal';
import { CreateGameQuestion } from '../store/GameQuestions/thunks';
import { setActivity } from '../store/TabChange/thunks';
import Acent from '../components/Acent';

const Index = ({ questionType, isEditable, edit, CardTime, CardOption, activity }) => {
    const [clonedDivs, setClonedDivs] = useState([]);
    const [answerData, setAnswerData] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth()
    const dispatch = useDispatch();
    const location = useLocation()
    const currentURL = location.pathname;
    const currentGameId = currentURL.split('/').pop();
    const [showModal, setShowModal] = useState(false);
    const [uploadedImage, setUploadedImage] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [removeImageLoader, setRemoveImageLoader] = useState(false);
    const SPANISH_ITEM_LOWER = ["á", "é", "í", "ó", "ú", "ü", "ñ", "¿", "¡"];
    const SPANISH_ITEM_UPPER = ["Á", "É", "Í", "Ó", "Ú", "Ü", "Ñ", "¿", "¡"];

    const textareaRef = useRef(null);
    const textarea1Ref = useRef(null);

    useEffect(() => {
        if (isEditable) {
            const Question = get(edit, 'question', '') ?? {};
            const Answer = get(edit, 'answers', '') ?? {};
            // Set question title and image
            setInputValue(Question?.title);
            setUploadedImage(Question?.imageUrl);
            // Set options in state
            if (Answer.options && Array.isArray(Answer.options)) {
                const updatedAnswerData = Answer.options.reduce((acc, option, index) => {
                    acc[`answer${index + 1}`] = option;
                    return acc;
                }, {});
                setAnswerData(updatedAnswerData);
                if (Answer.options.length === 5 || Answer.options.length === 6) {
                    if (clonedDivs.length === 0) {
                        setClonedDivs([...clonedDivs, {}]);
                    }
                }
            }
        } else {
            if (activity === false) {
                const blankAns = {
                    answer1: '',
                }
                setAnswerData(blankAns);
                setInputValue('');
                setClonedDivs([]);
                setUploadedImage('')
            }
        }
    }, [isEditable, edit, activity]);

    const openCheckNotification1 = (type) => {
        api[type]({
            message: 'Entry is invalid !',
            description:
                'Please fill the option correctly.',
            duration: 2,

        });
    };

    const openCheckNotification2 = (type) => {
        api[type]({
            message: 'Entry is invalid !',
            description:
                'Please fill unique value in option.',
            duration: 2,

        });
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const [isAnsFocused, setIsAnsFocused] = useState(false);

    const handleChange = (key, value, isAccent, textareaRef, type) => {
        if (type === "image") {
            setAnswerData({
                ...answerData,
                [key]: value,
            });
        }
        else {
            if (isAccent) {
                const textarea = textareaRef.current;
                const startPos = textarea.selectionStart;
                const endPos = textarea.selectionEnd;

                // If an accent is provided, concatenate it to the existing value
                const newValue =
                    (answerData[key] || '').substring(0, startPos) +
                    value +
                    (answerData[key] || '').substring(endPos);

                // Enforce a maximum character limit of 80 for individual content
                const trimmedValue = newValue.substring(0, 80);

                setAnswerData({
                    ...answerData,
                    [key]: trimmedValue,
                });

                // Move the cursor to the right after the added accent
                const newCursorPos = startPos + value.length;

                // Set the new cursor position immediately after the added accent
                setTimeout(() => {
                    textarea.setSelectionRange(newCursorPos, newCursorPos);
                    textarea.focus();
                }, 0);
            } else {
                const maxLength = 80;
                // Enforce a maximum character limit of 80 for individual content
                const trimmedValue = value.slice(0, maxLength);

                // If no accent is provided, update the key-value pair in the state
                setAnswerData({
                    ...answerData,
                    [key]: trimmedValue,
                });
            }

        }
    };

    const cleaning = (data) => {
        const nonEmptyIndexes = [];
        const emptyIndexes = [];

        Object.keys(data).forEach((key, index) => {
            const value = data[key];

            if (value !== "") {
                nonEmptyIndexes.push(index);
            } else {
                // If a value was found previously, ignore empty values
                emptyIndexes.push(index);
            }
        });

        if (emptyIndexes.length !== 0) {
            let shouldReturnFalse = true;
            nonEmptyIndexes.forEach((nonEmptyIndex) => {
                const emptyIndex = emptyIndexes[0];
                if (emptyIndex < nonEmptyIndex) {
                    shouldReturnFalse = false;
                }
            });

            if (shouldReturnFalse === false) {
                openCheckNotification1('error')
                return { status: false, data: {} };
            }
            else {
                emptyIndexes.forEach(emptyIndex => {
                    const keyToDelete = `answer${emptyIndex + 1}`;

                    if (data.hasOwnProperty(keyToDelete)) {
                        delete data[keyToDelete];
                    } else {
                        console.warn(`Key ${keyToDelete} is undefined or does not exist.`);
                    }
                });
                // setCleanAnswerData(data); // Store cleaned data in state
                return { status: true, data: data };
            }
        } else {
            // No empty indexes, simply set the cleaned data
            // setCleanAnswerData(data);
            return { status: true, data: data };
        }
    };

    const hasDuplicate = (data) => {
        // Check if data is empty
        if (Object.keys(data).length === 0) {
            return { status: false, data: {} }; // Return false if data is empty
        }
        const uniqueValues = new Set();

        for (const [value] of Object.entries(data)) {
            if (value !== "") {

                if (uniqueValues.has(value)) {
                    openCheckNotification2('error');
                    return { status: false, data: {} }; // Return false if a non-unique value is found
                } else {
                    uniqueValues.add(value);
                }
            }
        }
        // If no duplicates found, return a status indicating success
        return { status: true, data: data };
    };

    const onHandleQuestion = async () => {

        var clean1 = await cleaning(answerData);
        var clean = await hasDuplicate(clean1.data);
        if (clean.status === true) {
            var cleananswerData = clean.data;
            const question = {
                title: inputValue,
                imageUrl: `${uploadedImage ? uploadedImage : ''}`,
            }
            if (questionType === 'flash_card') {
                let updatedAnswerData;

                if (clonedDivs?.length === 0) {
                    // Ensure indices 4 and 5 are within the array bounds
                    if (Object.keys(cleananswerData).length > 5) {
                        // Create a shallow copy of cleananswerData
                        updatedAnswerData = { ...cleananswerData };

                        // Remove properties at indices 4 and 5
                        delete updatedAnswerData[Object.keys(cleananswerData)[4]];
                        delete updatedAnswerData[Object.keys(cleananswerData)[5]];
                    }
                    else {
                        updatedAnswerData = { ...cleananswerData };

                    }
                }
                else {
                    updatedAnswerData = { ...cleananswerData };
                }
                if (isEditable) {
                    let answers = {
                        options: Object.values(updatedAnswerData),
                        answer: answerData?.answer1,
                    }
                    const data = {
                        ...edit,
                        org_id: get(currentUser, 'company_id', ''),
                        created_by: get(currentUser, 'user_id', ''),
                        type: questionType,
                        question,
                        answers,
                        game_id: currentGameId
                    }
                    await dispatch(CreateGameQuestion(data))
                }
                else {
                    let answers = {
                        options: Object.values(updatedAnswerData),
                        answer: answerData?.answer1,
                    }
                    const data = {
                        org_id: get(currentUser, 'company_id', ''),
                        created_by: get(currentUser, 'user_id', ''),
                        type: questionType,
                        question,
                        answers,
                        game_id: currentGameId
                    }
                    await dispatch(CreateGameQuestion(data))
                    const blankAns = {
                        answer1: '',
                    }
                    setAnswerData(blankAns);
                    setInputValue('');
                    setClonedDivs([]);
                    setUploadedImage('')
                }
            }
            setLoading(false);
        }
        else {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        }
    }

    const handleRemoveImage = (imageUrl) => {
        if (imageUrl) {
            setRemoveImageLoader(true)
            // const storageRef = ref(storage, imageUrl);
            // deleteObject(storageRef).then(() =>
            try{
                if (isEditable) {
                    const question = {
                        title: inputValue,
                        imageUrl: '',
                        time: CardTime,
                        option: CardOption

                    }
                    const UpdateData = {
                        ...edit,
                        question,
                    }
                    dispatch(CreateGameQuestion(UpdateData))
                    setRemoveImageLoader(false)
                    setUploadedImage('')
                }
                setUploadedImage('')
            }
            catch (error) {
                console.log('error', error);
                setRemoveImageLoader(false)
            }
        }
    }

    useEffect(() => {
        if (
            (CardTime !== '20 seconds' ||
                CardOption.id !== 1 ||
                inputValue ||
                Object.values(answerData).some(Boolean)) && !isEditable
        ) {
            dispatch(setActivity({ activity: true }));
        }
        else {
            dispatch(setActivity({ activity: false }));
        }
    }, [CardTime, CardOption, inputValue, answerData]);

    const onImageUpload = (image) => {
        setUploadedImage(image)
        if (isEditable) {
            const question = {
                title: inputValue,
                imageUrl: image,
                time: CardTime,
                option: CardOption

            }
            const UpdateData = {
                ...edit,
                question,
            }
            dispatch(CreateGameQuestion(UpdateData))
        }
    }

    const insertAccent = (accent) => {
        const textarea = textareaRef.current;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;

        const newInputValue =
            inputValue.substring(0, startPos) +
            accent +
            inputValue.substring(endPos);

        setInputValue(newInputValue.substring(0, 130));
        setDropdownVisible0(false);

        // Move the cursor to the right after the added accent
        const newCursorPos = startPos + accent.length;

        // Set the new cursor position immediately after the added accent
        setTimeout(() => {
            textarea.setSelectionRange(newCursorPos, newCursorPos);
            textarea.focus();
        }, 0);
    };

    const handleInputChange = (e) => {
        // const value = e.target.value;
        // if (value.length <= 130) {
        //     setInputValue(value);
        // }
        const maxLength = 130;
        const value = e.target.value;
        const trimmedValue = value.slice(0, maxLength);
        setInputValue(trimmedValue);
        // adjustTextareaHeight();
    };

    const [dropdownVisible0, setDropdownVisible0] = useState(false);
    const [dropdownVisible1, setDropdownVisible1] = useState(false);

    const toggleDropdown = (position) => {
        if (position === 0) {
            setDropdownVisible0(!dropdownVisible0);
        }
        if (position === 1) {
            setDropdownVisible1(!dropdownVisible1);
        }
    };

    const handleBlur1 = () => {
        setDropdownVisible0(false);
        setDropdownVisible1(false);
    };

    useEffect(() => {
        adjustTextareaHeightInitial();
    }, [inputValue]);


    const textareaRefs = [textarea1Ref];
    const defaultTextareaHeight = 60;

    const adjustTextareaHeight = () => {
        const maxCharCountTextarea = textareaRefs.reduce((maxTextarea, currentTextarea) => {
            if (currentTextarea && currentTextarea.current) {
                return currentTextarea.current.value.length > maxTextarea.current.value.length ? currentTextarea : maxTextarea;
            }
            return maxTextarea;
        }, { current: { value: '' } });

        const minHeight = maxCharCountTextarea.current.value.length * 1.5 + defaultTextareaHeight;

        textareaRefs.forEach((ref) => {
            if (ref && ref.current) {
                ref.current.style.height = 'auto';
                ref.current.style.height = `${minHeight}px`;
            }
        });
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [answerData?.answer1]);


    const adjustTextareaHeightInitial = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            adjustTextareaHeightInitial();
        }
    };
    const handleKeyDown1 = (e) => {
        if (e.key === 'Enter') {
            adjustTextareaHeight();
        }
    };

    return (
        <>
            <div className='layoutWraper notificationText'>
                {contextHolder}
                <div className='layoutWrapLeft'>
                    <div className='addBkgroundImg'>
                        <div className='addImgShow'>
                            <div className='uploadImgUpr'>
                                {uploadedImage ? (
                                    <div className="image-container">
                                        <img src={uploadedImage} alt="Uploaded" />
                                        <div className="edit-icon">
                                            <span onClick={() => handleRemoveImage(uploadedImage)} >{removeImageLoader ? <CircularProgress size={15} style={{ color: 'teal' }} /> : null} Remove</span>
                                        </div>
                                    </div>
                                ) : (
                                    <img src={require('../utils/assets/images/upload2.png')} alt="" />
                                )}
                            </div>
                        </div>
                        <div className='uploadImgIcn'>
                            <label className="btn btn-icon" onClick={() => {
                                setShowModal(true)
                            }}>
                                <i className="fa-solid fa-plus fs-7 uploadImgPlus"></i>
                            </label>
                        </div>
                        <div className='insertMediaDP'>
                            <label>Find and insert media</label>
                        </div>
                    </div>
                    <ImageModal isOpens={showModal} isClose={setShowModal} currentGameId={currentGameId} onSubmit={(e) => {
                        onImageUpload(e)
                    }} />
                </div>

                <div className='quizLayout'>
                    <div className='addQuesBoxStudy'>
                        <div className='playGameMidSrch my-textarea'>
                            <Acent inputRef={textareaRef} setValue={inputValue?.length < 130 ? setInputValue : null} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                            {/* <div style={{ position: 'relative', top: 0, left: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                <div className="dropdown-container">
                                    <button className="dropdown-button accent-select" onClick={() => toggleDropdown(0)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                        Á &#8659;
                                    </button>
                                    {dropdownVisible0 && (
                                        <div className="dropdown-content accent-select">
                                            <ul className="accent-list">
                                                {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                    <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                     {accent}
                                                    </li>
                                                ))}
                                            </ul>

                                            <ul className="accent-list">
                                                {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                    <li key={index} className="accent-list-item" onMouseDown={() => insertAccent(accent)}>
                                                        {accent}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            <div style={{ position: 'relative', marginTop: 5 }} className='padforsmall'>
                                <textarea
                                    ref={textareaRef}
                                    style={{
                                        background: 'transparent',
                                        border: 0,
                                        fontSize: 20,
                                        color: 'white',
                                        resize: 'none',
                                        marginTop: "20",
                                        outline: 'none',
                                    }}
                                    type="text"
                                    value={inputValue}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder={isFocused ? '' : 'Start typing your front question...'}
                                    className="custom-textarea"
                                />
                                <div className='counter-position'
                                    style={{
                                        position: 'absolute',
                                        right: 0,
                                        color: 'white',
                                        fontSize: 12,
                                    }}
                                >
                                    {inputValue.length}/130
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-p mt-n20 position-relative for-pad'>
                        <div className='row g-0'>
                            <div className='col bg-light-primary px-6 pt-0 pb-5 rounded-2 mb-7'>
                                {/* <div style={{ position: 'relative', top: 0, left: 0, float: 'right' }}>
                                    <div className="dropdown-container">
                                        <button className="dropdown-button accent-select" onClick={() => toggleDropdown(1)} onBlur={() => handleBlur1()} style={{ padding: "2px" }}>
                                            Á &#8659;
                                        </button>
                                        {dropdownVisible1 && (
                                            <div className="dropdown-content accent-select">
                                                <ul className="accent-list">
                                                    {SPANISH_ITEM_LOWER.map((accent, index) => (
                                                        <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer1', accent, true, textarea1Ref)}>
                                                            {accent}
                                                        </li>
                                                    ))}
                                                </ul>

                                                <ul className="accent-list">
                                                    {SPANISH_ITEM_UPPER.map((accent, index) => (
                                                        <li key={index} className="accent-list-item" onMouseDown={() => handleChange('answer1', accent, true, textarea1Ref)}>
                                                            {accent}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div> */}
                                <Acent inputRef={textarea1Ref} setValue={(e) => handleChange('answer1', e)} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                                <div className='addAnswerBox'>
                                    <div className='addAnswerBxOne'>
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr075.svg'
                                            className='svg-icon-3x svg-icon-primary d-block quizAnimatedPart'
                                        />
                                    </div>
                                    
                                        <textarea
                                            ref={textarea1Ref}
                                            type="text"
                                            value={answerData?.answer1}
                                            placeholder={isAnsFocused ? '' : 'Add back answer'}

                                            className={`addAnswerMid text-primary flashTextarea`}
                                            onChange={(e) => handleChange('answer1', e.target.value)}
                                        onKeyDown={handleKeyDown1}
                                        style={{minHeight:110}}
                                        />
                                        {/* <div className='flashCardWrdCnt image-container'> */}
                                        <div className='addAnswerBxTwo image-container makeCountSpace'>
                                        <div
                                            className="text-dark"
                                            style={{
                                                position: 'absolute',
                                                bottom: -3,
                                                right: 0,
                                                fontSize: 12,
                                            }}
                                        >
                                            {(answerData?.answer1 || '').length}/80
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="moreAnsBtnSave"><Button disabled={!(inputValue?.trim() !== '' && answerData.answer1?.trim())} onClick={() => {
                            onHandleQuestion();
                            setLoading(true);
                        }}>
                            {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} /> : <>  <AiOutlinePlusSquare />{`${isEditable ? 'Update' : 'save'}`}</>}</Button></div>
                    </div>

                </div>
            </div>
        </>
    )
}

Index.propTypes = {}

export default Index;

